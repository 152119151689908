<fieldset [disabled]="true">
	<div class="row">
		<div class="col-md-6">
			<div class="form-group" *ngIf="rightService.isRevendeur()">
				<label translate>connecteur.import.client</label>
				<div>
					<autocomplete name="tenant" type="tenant" #tenant="ngModel" [(ngModel)]="connecteurExecution.tenant"></autocomplete>
				</div>
			</div>
			<div class="form-group" *ngIf="connecteurExecution.connecteur?.type?.typeConnecteur == 'INTERFACE'">
				<label translate>connecteur.import.format</label>
				<div>
					<autocomplete name="fournisseurInterface" type="fournisseurInterface" #fournisseurInterface="ngModel" [(ngModel)]="connecteurExecution.connecteur.type.fournisseurInterface"></autocomplete>
				</div>
			</div>
			<div class="form-group" *ngIf="connecteurExecution.connecteur?.type?.typeConnecteur == 'API'">
				<label translate>connecteur.import.connecteur</label>
				<div>
					<autocomplete name="type" type="connecteurType" #type="ngModel" [(ngModel)]="connecteurExecution.connecteur.type"></autocomplete>
				</div>
			</div>
			<div class="form-group" *ngIf="connecteurExecution.importTemplate">
				<label translate>connecteur.import.selection.template</label>
				<div>
					<span>{{ connecteurExecution.importTemplate.libelle }}</span>
				</div>
			</div>
			<div class="form-group">
				<label translate>connecteur.import.dateExecution</label>
				<div class="half">
					<input type="text" class="form-control" name="dateExecution" #dateExecution="ngModel" [(ngModel)]="connecteurExecution.dateExecution" datePicker format="datetime"/>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group" *ngIf="connecteurExecution.connecteur?.type?.typeConnecteur == 'INTERFACE' || connecteurExecution.importTemplate">
				<label translate>connecteur.import.pieceJointes</label>
				<div>
					<div [attachment]="connecteurExecution" [typeAttachment]="TypeAttachment.IMPORT" readOnly="true"></div>
				</div>
			</div>
			<div class="form-group">
				<label translate>connecteur.import.logs.item</label>
				<div>
					<a [routerLink]="[]" (click)="showLogs()"><icon class="icon-hc-2x" name="search"></icon><span translate>connecteur.import.logs.info</span></a>
				</div>
			</div>
			<div class="form-group">
				<label translate>connecteur.import.correlationId</label>
				<div>
					<span>{{ connecteurExecution.correlationId }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="progress?.nbTotal">
		<div class="col-md-12">
			<div class="form-group">
				<label translate>connecteur.import.progress.item</label>
				<div>
					<span>{{ progress.nbProcessed }} / {{ progress.nbTotal }}</span>
					<div class="progress m-t-10">
						<div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': 100 * (progress.nbProcessed / progress.nbTotal) + '%' }"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</fieldset>