import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { FactureService } from './facture.service';
import { mapEntites } from './detail/detail.entites';
import { HistoryService } from 'src/app/share/components/history/history.service';

@Component({
	templateUrl: './facture-detail-list-item.component.html'
})
export class FactureDetailListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(public factureService: FactureService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private mailHistoryService: MailHistoryService,private notificationService: NotificationService,private layoutService: LayoutService,private historyService: HistoryService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteDetail = this.deleteDetail.bind(this);
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteDetail(detail: any = this.data,close?: Function) {
		//Mise à jour de la facture (version)
		detail.facture = this.extraOptions.facture;

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.factureService.deleteDetail(detail))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Définition du nombre d'anomalies
					this.extraOptions.resume.nbDetailsAnomalie = result.data.nbDetailsAnomalie;

					//Mise à jour de la facture
					Object.assign(this.extraOptions.facture,result.data.facture);

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Redirection vers l'élément
	 */
	goToObject(objet = this.data.lien.objet) {
		//Vérification des identifiants
		if (objet?.idVehicule) {
			//Navigation vers le véhicule
			this.layoutService.goToByState('listeVehicules-loadVehicule',{
				routeParams: {
					idVehicule: objet.idVehicule
				},
				withGoBack: true
			});
		} else if (objet?.idEquipement) {
			//Navigation vers l'équipement
			this.layoutService.goToByState('materielReferentiel-listeEquipements-loadEquipement',{
				routeParams: {
					idEquipement: objet.idEquipement
				},
				withGoBack: true
			});
		} else if (objet?.idUser) {
			//Redirection vers le collaborateur
			this.layoutService.goToByState('listeUsers-user',{
				routeParams: {
					idUser: objet?.idUser
				},
				withGoBack: true
			});
		}
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.facture.Detail',this.data.idDetail,{
			getOwningEntity: () => this.data.facture,
			typeAttachment: TypeAttachment.FACTURE
		});
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.facture.Detail',this.data.idDetail);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.facture.Detail',this.data.idDetail);
	}
}