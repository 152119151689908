<ng-container *ngIf="selectedFournisseur">
	<list-view [liste]="listeConnecteurs" *ngIf="listeConnecteurs && !selectedConnecteur"></list-view>
	<div class="listview lv-bordered">
		<div class="lv-body">
			<div *ngIf="selectedConnecteur" class="p-t-40">
				<div class="lv-item media clickable">
					<import-specification-connecteur-list-item [data]="selectedConnecteur" canRemove="true" [extraOptions]="listeConnecteurs.extraOptions"></import-specification-connecteur-list-item>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="selectedFormat">
	<list-view [liste]="listeTemplates" *ngIf="listeTemplates && !selectedTemplate"></list-view>
	<div class="listview lv-bordered">
		<div class="lv-body">
			<div *ngIf="selectedTemplate" class="p-t-40">
				<div class="lv-item media clickable">
					<import-specification-template-list-item [data]="selectedTemplate" canRemove="true" [extraOptions]="listeTemplates.extraOptions"></import-specification-template-list-item>
				</div>
			</div>
		</div>
	</div>
	<div class="listview" *ngIf="!selectedTemplate">
		<div class="lv-body">
			<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addImportTemplate();">
				<h5><a [routerLink]="[]" translate>connecteur.import.template.ajouterTemplate</a></h5>
			</div>
		</div>
	</div>
</ng-container>