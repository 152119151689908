import { createPlugin } from '@fullcalendar/core/index.js';
import premiumCommonPlugin from '@fullcalendar/premium-common/index.js';
import timelinePlugin from '@fullcalendar/timeline/index.js';
import resourcePlugin from '@fullcalendar/resource/index.js';
import { ResourceTimelineView } from './internal.js';
import '@fullcalendar/core/internal.js';
import '@fullcalendar/core/preact.js';
import '@fullcalendar/timeline/internal.js';
import '@fullcalendar/resource/internal.js';
import '@fullcalendar/scrollgrid/internal.js';
var index = createPlugin({
  name: '@fullcalendar/resource-timeline',
  premiumReleaseDate: '2024-07-12',
  deps: [premiumCommonPlugin, resourcePlugin, timelinePlugin],
  initialView: 'resourceTimelineDay',
  views: {
    resourceTimeline: {
      type: 'timeline',
      component: ResourceTimelineView,
      needsResourceData: true,
      resourceAreaWidth: '30%',
      resourcesInitiallyExpanded: true,
      eventResizableFromStart: true // TODO: not DRY with this same setting in the main timeline config
    },
    resourceTimelineDay: {
      type: 'resourceTimeline',
      duration: {
        days: 1
      }
    },
    resourceTimelineWeek: {
      type: 'resourceTimeline',
      duration: {
        weeks: 1
      }
    },
    resourceTimelineMonth: {
      type: 'resourceTimeline',
      duration: {
        months: 1
      }
    },
    resourceTimelineYear: {
      type: 'resourceTimeline',
      duration: {
        years: 1
      }
    }
  }
});
export { index as default };