import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AvantageNatureAbattementService } from './avantage-nature-abattement.service';
import { AvantageNatureAbattementListComponent } from './avantage-nature-abattement-list.component';
import { AvantageNatureAbattementListItemComponent } from './avantage-nature-abattement-list-item.component';
import { AvantageNatureAbattementComponent } from './avantage-nature-abattement.component';


@NgModule({
	imports: [ShareModule],
	declarations: [AvantageNatureAbattementListComponent,AvantageNatureAbattementListItemComponent,AvantageNatureAbattementComponent],
	providers: [AvantageNatureAbattementService]
})
export class AvantageNatureAbattementModule { }