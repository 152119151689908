<div class="pull-left lv-avatar">{{ data.type.fournisseurInterface.reference.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="!canRemove && extraOptions.onItemSelected({ connecteur: data })">{{ data.type.fournisseurInterface.fournisseur.libelle + ' (' + data.type.fournisseurInterface.reference + (data.version ? ' - ' + ('connecteur.import.selection.version' | translate:{ version: data.version }) : '') + ')' }}</a>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.type.fournisseurInterface.fournisseur"><span translate>connecteur.import.selection.fournisseur</span>&#160;:&#160;<span>{{ data.type.fournisseurInterface.fournisseur.libelle }}</span></li>
		<li *ngIf="data.type.typeExecution != 'NON_DEFINI'"><span translate>connecteur.typeExecution.item</span>&#160;:&#160;<span [translate]="'connecteur.typeExecution.' + data.type.typeExecution"></span></li>
	</ul>

	<div class="lv-actions actions">
		<a [routerLink]="[]" *ngIf="canRemove" (click)="extraOptions.onItemSelected(null)"><icon name="close" class="icon-hc-1x"></icon></a>
	</div>
</div>