import { Injectable } from '@angular/core';
import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map,} from 'rxjs/operators';

import { UPDATE_USER } from 'src/app/reducers/session';
import { Action } from 'src/app/domain/action';
import { ConnecteurService } from './connecteur.service';

@Injectable()
export class ConnecteurEffects {
	/**
	 * Constructeur
	 */
	constructor(private actions$: Actions,private connecteurService: ConnecteurService) {

	}

	/**
	 * Suppression du cache
	 */
	resetCache$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(UPDATE_USER),
		map(action => {
			//Suppression du cache
			this.connecteurService.evictCache();

			//Retour de l'action
			return action;
		})
	),{ dispatch: false });
}