<div class="modal-header">
	<h4 class="modal-title" translate>affectationEcheance.detail.title</h4>
</div>
<div class="modal-body">
	<div class="alert alert-info">
		<icon name="info"></icon>
		<div>
			<strong translate>affectationEcheance.typeCalculAvantageNature.item</strong>
			<div translate>{{ 'affectationEcheance.typeCalculAvantageNature.'+affectationEcheance.typeCalculAvantageNature | translate}}</div>
		</div>
	</div>
	<div>
		<table class="table">
			<tbody>
				<tr *ngIf="['ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.age.title</span>
								<icon class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.age.detail' | translate"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span [translate]="'affectationEcheance.detail.age' | pluralTranslate:affectationEcheance.ageVehicule" [translateParams]="{ ageVehicule: affectationEcheance.ageVehicule }"></span>
					</td>
				</tr>
				<tr *ngIf="['ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.prixBase.title</span>
								<icon class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.prixBase.detail' | translate"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span>{{ (affectationEcheance.prixBase || 0) | currency:'.2-2':user?.devise  }}</span>
					</td>
				</tr>
				<tr *ngIf="['ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.plafondAchatAge.title</span>
								<icon *ngIf="['ACHAT_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && affectationEcheance.ageVehicule < 5" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAchatAge.detail' | translate:{ pourcent: 12,signe: '<' }"></icon>
								<icon *ngIf="['ACHAT_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && affectationEcheance.ageVehicule >= 5" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAchatAge.detail' | translate:{ pourcent: 9,signe: '>=' }"></icon>
								<icon *ngIf="['ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && affectationEcheance.ageVehicule < 5" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAchatAge.detail' | translate:{ pourcent: 9,signe: '<' }"></icon>
								<icon *ngIf="['ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && affectationEcheance.ageVehicule >= 5" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAchatAge.detail' | translate:{ pourcent: 6,signe: '>=' }"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span *ngIf="['ACHAT_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">{{ affectationEcheance.ageVehicule < 5 ? '12 %' : '9 %' }}</span>
						<span *ngIf="['ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">{{ affectationEcheance.ageVehicule < 5 ? '9 %' : '6 %' }}</span>
					</td>
				</tr>
				<tr *ngIf="['ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.plafondAEN.title</span>
								<icon class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAEN.detailAchat' | translate"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span>{{ (affectationEcheance.plafondPrixBase || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr *ngIf="['ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && affectationEcheance.vehiculeElectrique">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.plafondAENElectrique.title</span>
								<icon class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAENElectrique.detail' | translate:{ plafond: affectationEcheance.plafondAbattementVehiculeElectrique | currency:'.2-2':'EUR' }"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span class="c-red">-&nbsp;{{ (affectationEcheance.plafondVehiculeElectrique || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr *ngIf="['ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.plafondAENMensuel.title</span>
								<icon *ngIf="!affectationEcheance.vehiculeElectrique" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAENMensuel.detail' | translate"></icon>
								<icon *ngIf="affectationEcheance.vehiculeElectrique" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAENMensuel.detailElectrique' | translate"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span *ngIf="!affectationEcheance.vehiculeElectrique">{{ ((affectationEcheance.plafondPrixBase / 12) || 0) | currency:'.2-2':user?.devise }}</span>
						<span *ngIf="affectationEcheance.vehiculeElectrique">{{ (((affectationEcheance.plafondPrixBase-affectationEcheance.plafondVehiculeElectrique) / 12) || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr *ngIf="['LOCATION_AVEC_CARBURANT','LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.loyerTTC</span>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span translate>{{ (affectationEcheance.loyerTTC || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr *ngIf="!affectationEcheance.vehiculeElectrique && ['LOCATION_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.montantEnergieTTC.title</span>
								<icon class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.montantEnergieTTC.detail' | translate"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span translate>{{ (affectationEcheance.montantCarburantTTC || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr *ngIf="['LOCATION_AVEC_CARBURANT','LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.prixBase.title</span>
								<icon *ngIf="!affectationEcheance.vehiculeElectrique && ['LOCATION_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.prixBase.detailCarburant' | translate"></icon>
								<icon *ngIf="affectationEcheance.vehiculeElectrique || ['LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.prixBase.detailNoCarburant' | translate"></icon>
							</label>

						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span *ngIf="!affectationEcheance.vehiculeElectrique && ['LOCATION_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">{{ ((affectationEcheance.loyerTTC + affectationEcheance.montantCarburantTTC) || 0) | currency:'.2-2':user?.devise }}</span>
						<span *ngIf="affectationEcheance.vehiculeElectrique || ['LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">{{ (affectationEcheance.loyerTTC || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr *ngIf="['LOCATION_AVEC_CARBURANT','LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">
					<td style="border-top: none;">
						<div class="form-group">
							<label>

								<span translate>affectationEcheance.detail.plafondAEN.title</span>
								<icon class="m-l-5" name="info_outline" [tooltip]="(!affectationEcheance.vehiculeElectrique && ['LOCATION_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) ? 'affectationEcheance.detail.plafondAEN.detailCarburant' : 'affectationEcheance.detail.plafondAEN.detailNoCarburant') | translate"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span class="c-green bold">{{ (affectationEcheance.plafondPrixBase || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr *ngIf="['LOCATION_AVEC_CARBURANT','LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && affectationEcheance.vehiculeElectrique && affectationEcheance.plafondAbattementVehiculeElectrique">
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.plafondAENElectrique.title</span>
								<icon class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.plafondAENElectrique.detail' | translate:{ plafond: (affectationEcheance.plafondAbattementVehiculeElectrique / 12) | currency:'.2-2':'EUR' }"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span class="c-red bold">-&nbsp;{{(affectationEcheance.plafondVehiculeElectrique || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr>
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.montantParticipation.title</span>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span class="c-red bold">-&nbsp;{{ (affectationEcheance.affectation.montantParticipation || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
				<tr>
					<td style="border-top: none;">
						<div class="form-group">
							<label>
								<span translate>affectationEcheance.detail.avantageNature.title</span>
								<icon *ngIf="['LOCATION_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && affectationEcheance.vehiculeElectrique" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.avantageNature.detailElectriqueCarburant' | translate:{ aen: ((affectationEcheance.montantAvantageNatureAchatAvecCarburant || 0) | currency:'.2-2':user?.devise) }"></icon>
								<icon *ngIf="['LOCATION_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && !affectationEcheance.vehiculeElectrique" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.avantageNature.detailCarburant' | translate:{ aen: ((affectationEcheance.montantAvantageNatureAchatAvecCarburant || 0) | currency:'.2-2':user?.devise) }"></icon>
								<icon *ngIf="['LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && affectationEcheance.vehiculeElectrique" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.avantageNature.detailElectriqueNoCarburant' | translate:{ aen: ((affectationEcheance.montantAvantageNatureAchatSansCarburant || 0) | currency:'.2-2':user?.devise) }"></icon>
								<icon *ngIf="['LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature) && !affectationEcheance.vehiculeElectrique" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.avantageNature.detailNoCarburant' | translate:{ aen: ((affectationEcheance.montantAvantageNatureAchatSansCarburant || 0) | currency:'.2-2':user?.devise) }"></icon>
								<icon *ngIf="['ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)" class="m-l-5" name="info_outline" [tooltip]="'affectationEcheance.detail.avantageNature.detailAchat' | translate"></icon>
							</label>
						</div>
					</td>
					<td class="text-right" style="border-top: none;">
						<span class="c-green bold" *ngIf="['LOCATION_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">{{ (affectationEcheance.montantAvantageNatureLocationAvecCarburant || 0) | currency:'.2-2':user?.devise }}</span>
						<span class="c-green bold" *ngIf="['LOCATION_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">{{ (affectationEcheance.montantAvantageNatureLocationSansCarburant || 0) | currency:'.2-2':user?.devise }}</span>
						<span class="c-green bold" *ngIf="['ACHAT_AVEC_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">{{ (affectationEcheance.montantAvantageNatureAchatAvecCarburant || 0) | currency:'.2-2':user?.devise }}</span>
						<span class="c-green bold" *ngIf="['ACHAT_SANS_CARBURANT'].includes(affectationEcheance.typeCalculAvantageNature)">{{ (affectationEcheance.montantAvantageNatureAchatSansCarburant || 0) | currency:'.2-2':user?.devise }}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>