<form #dataCustomizationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" *ngIf="listeFields && object.dataCustomization">
	<div class="card-body card-padding" [ngClass]="{ 'm-t-20': !isSubItem,'m-b-0 m-t-0 p-0': isSubItem }">
		<div class="row m-b-10">
			<div class="col-xs-12">
				<h4 class="actions">
					<span translate>dataCustomization.entite.title</span>
					<a [routerLink]="[]" class="m-l-10" (click)="edit()"><icon name="edit"></icon></a>
				</h4>
			</div>
		</div>
		<div *ngIf="listeFields?.length">
			<div *ngFor="let subPartOfListeFields of subListeFields">
				<div class="row">
					<div class="col-md-6" *ngFor="let field of subPartOfListeFields">
						<div class="form-group">
							<label>{{ field.libelle }}</label>
							<div [ngClass]="{ half: field.type == 'numeric' || field.type == 'date' }">
								<ng-container *ngIf="field.type == 'alpha' || field.type == 'numeric' || field.type == 'date'">
									<input *ngIf="field.type == 'alpha'" type="text" class="form-control" [name]="field.name" [(ngModel)]="object.dataCustomization[field.name]" [disabled]="disabled"/>
									<input *ngIf="field.type == 'numeric'" type="text" class="form-control" [name]="field.name" [(ngModel)]="object.dataCustomization[field.name]" nNumber="2" [disabled]="disabled"/>
									<input *ngIf="field.type == 'date'" type="text" class="form-control" [name]="field.name" [(ngModel)]="object.dataCustomization[field.name]" datePicker [disabled]="disabled"/>
								</ng-container>
								<autocomplete *ngIf="field.type == 'referentiel'" type="customReferentiel" [filter]="{ isCreation: true,entite,typeReferentiel: field.typeReferentiel }" [name]="field.name" [(ngModel)]="object.dataCustomization[field.name]" [disabled]="disabled"></autocomplete>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!listeFields?.length" class="col-xs-12 text-center">
				<a [routerLink]="[]" (click)="edit()" translate>dataCustomization.entite.actions.modifier</a>
			</div>
		</div>
	</div>
</form>