import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { first } from 'rxjs/operators';

import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { AppState } from 'src/app/domain/appstate';
import { User } from 'src/app/domain/user/user';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EntretienService } from './entretien.service';

@Component({
	selector: 'entretien',
	templateUrl: './entretien.component.html'
})
export class EntretienComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() entretien: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Utilisateur connecté **/
	user: User;

	/** Dernier compteur du véhicule **/
	lastCompteur: any = null;

	/** Nombre d'interventions **/
	nbInterventions: number = 0;

	/** Indicateur de chargement de l'entretien **/
	isEntretienLoaded: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private entretienService: EntretienService,private changeDetectorRef: ChangeDetectorRef,public rightService: RightService,public vehiculeService: VehiculeService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement de l'entretien
		this.entretienService.loadEntretien(this.entretien?.idEntretien || 0).pipe(first()).subscribe({
			next: result => {
				//Vérification de l'entretien
				if (result.data.entretien)
					//Mise à jour de l'entretien
					this.entretien = Object.assign(cloneDeep(this.entretien),result.data.entretien);

				//Vérification de la présence d'un dernier compteur
				if (result.data.lastCompteur)
					//Récupération du dernier relevé de référence pour le véhicule
					this.lastCompteur = result.data.lastCompteur;

				//Vérification de la présence d'interventions
				if (result.data.nbInterventions)
					//Récupération du nombre d'interventions
					this.nbInterventions = result.data.nbInterventions;

				//Mise à jour de l'indicateur de chargement
				this.isEntretienLoaded = true;
			}
		});

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked(): void {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de l'entretien après enregistrement
	 */
	onContentClose(entretien: any) {
		//Mise à jour ou suppression de l'entretien
		this.onObjectChange.emit(entretien || null);
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Interception du changement du véhicule
	 */
	onVehiculeChange() {
		//Vérification du véhicule et de son type
		if (this.entretien.vehicule?.typeVehicule == 'ENGIN')
			//Définition du type d'entretien
			this.entretien.type = 'COURANT'
	}
}