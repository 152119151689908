/**
 * Enumération des droits
 */
export enum TypeDroit {
	/** Enumération **/
	NOT_ASSIGNED = 'NOT_ASSIGNED',
	ADMIN_ENVIRONNEMENT = 'ADMIN_ENVIRONNEMENT',
	ADMIN_TENANT = 'ADMIN_TENANT',
	ADMIN_DATABASE = 'ADMIN_DATABASE',
	ADMIN_PROFIL = 'ADMIN_PROFIL',
	ADMIN_UTILISATEUR = 'ADMIN_UTILISATEUR',
	ADMIN_UTILISATEUR_CATEGORIE = 'ADMIN_UTILISATEUR_CATEGORIE',
	ADMIN_REVENDEUR = 'ADMIN_REVENDEUR',
	ADMIN_UPGRADER = 'ADMIN_UPGRADER',
	ADMIN_TEMPLATE_MAIL = 'ADMIN_TEMPLATE_MAIL',
	ADMIN_ANALYTIQUE_SERVICE = 'ADMIN_ANALYTIQUE_SERVICE',
	ADMIN_ANALYTIQUE_PROJET_1 = 'ADMIN_ANALYTIQUE_PROJET_1',
	ADMIN_ANALYTIQUE_PROJET_2 = 'ADMIN_ANALYTIQUE_PROJET_2',
	ADMIN_ANALYTIQUE_PROJET_3 = 'ADMIN_ANALYTIQUE_PROJET_3',
	ADMIN_GEOGRAPHIE_PAYS = 'ADMIN_GEOGRAPHIE_PAYS',
	ADMIN_VEHICULE = 'ADMIN_VEHICULE',
	ADMIN_LANGUE = 'ADMIN_LANGUE',
	VEHICULE_REFERENTIEL_GENRE = 'VEHICULE_REFERENTIEL_GENRE',
	VEHICULE_REFERENTIEL_MARQUE = 'VEHICULE_REFERENTIEL_MARQUE',
	VEHICULE_REFERENTIEL_MODELE = 'VEHICULE_REFERENTIEL_MODELE',
	VEHICULE_REFERENTIEL_CARBURANT = 'VEHICULE_REFERENTIEL_CARBURANT',
	VEHICULE_REFERENTIEL_TYPE_CARROSSERIE = 'VEHICULE_REFERENTIEL_TYPE_CARROSSERIE',
	VEHICULE_REFERENTIEL_TYPE_INFRACTION = 'VEHICULE_REFERENTIEL_TYPE_INFRACTION',
	VEHICULE_REFERENTIEL_TYPE_AMENAGEMENT = 'VEHICULE_REFERENTIEL_TYPE_AMENAGEMENT',
	VEHICULE_REFERENTIEL_OPTION_CATEGORIE = 'VEHICULE_REFERENTIEL_OPTION_CATEGORIE',
	ADMIN_AIDE_ARTICLE = 'ADMIN_AIDE_ARTICLE',
	ADMIN_AIDE_HINT = 'ADMIN_AIDE_HINT',
	ADMIN_FOURNISSEUR = 'ADMIN_FOURNISSEUR',
	ADMIN_PLAN_COMPTABLE = 'ADMIN_PLAN_COMPTABLE',
	VEHICULE_CONTRAT_FINANCEMENT = 'VEHICULE_CONTRAT_FINANCEMENT',
	ADMIN_CLIENT_CRM = 'ADMIN_CLIENT_CRM',
	ADMIN_CONTRAT_CRM = 'ADMIN_CONTRAT_CRM',
	ADMIN_ARTICLE_CRM = 'ADMIN_ARTICLE_CRM',
	ADMIN_DEVISE = 'ADMIN_DEVISE',
	VEHICULE_GRILLE_ATTRIBUTION = 'VEHICULE_GRILLE_ATTRIBUTION',
	VEHICULE_CONDUCTEUR = 'VEHICULE_CONDUCTEUR',
	SETUP_WIZARD = 'SETUP_WIZARD',
	VEHICULE_SINISTRE = 'VEHICULE_SINISTRE',
	VEHICULE_CONTRAVENTION = 'VEHICULE_CONTRAVENTION',
	VEHICULE_COMPTEUR = 'VEHICULE_COMPTEUR',
	VEHICULE_RELEVE_ENERGIE = 'VEHICULE_RELEVE_ENERGIE',
	ADMIN_EXTRACTION = 'ADMIN_EXTRACTION',
	ADMIN_CHART = 'ADMIN_CHART',
	ADMIN_FOURNISSEUR_NATURE = 'ADMIN_FOURNISSEUR_NATURE',
	ADMIN_FOURNISSEUR_INTERFACE = 'ADMIN_FOURNISSEUR_INTERFACE',
	FACTURE = 'FACTURE',
	ADMIN_OFFRE = 'ADMIN_OFFRE',
	VEHICULE_DEMANDE_IDENTIFICATION = 'VEHICULE_DEMANDE_IDENTIFICATION',
	ADMIN_COMPTE_SERVICE = 'ADMIN_COMPTE_SERVICE',
	ADMIN_PERSONAL_DATA = 'ADMIN_PERSONAL_DATA',
	EQUIPEMENT = 'EQUIPEMENT',
	ENTRETIEN = 'ENTRETIEN',
	CONTROLE_REGLEMENTAIRE = 'CONTROLE_REGLEMENTAIRE',
	VEHICULE_AMENAGEMENT = 'VEHICULE_AMENAGEMENT',
	ADMIN_GROUPE = 'ADMIN_GROUPE',
	ADMIN_TAXE = 'ADMIN_TAXE',
	ADMIN_BAREME = 'ADMIN_BAREME',
	ADMIN_WORKFLOW = 'ADMIN_WORKFLOW',
	DEMANDE_COTATION = 'DEMANDE_COTATION',
	REPONSE_COTATION = 'REPONSE_COTATION',
	ADMIN_CALENDRIER = 'ADMIN_CALENDRIER',
	DEMANDE_VEHICULE = 'DEMANDE_VEHICULE',
	CONNECTEUR = 'CONNECTEUR',
	DATA_CUSTOMIZATION_ENTITE = 'DATA_CUSTOMIZATION_ENTITE',
	FACTURE_DETAIL = 'FACTURE_DETAIL',
	ADMIN_WORKFLOW_MOTIF = 'ADMIN_WORKFLOW_MOTIF',
	ADMIN_TODO_LIST = 'ADMIN_TODO_LIST',
	LICENCE_CONSOMMATION = 'LICENCE_CONSOMMATION',
	VEHICULE_AFFECTATION_ECHEANCE = 'VEHICULE_AFFECTATION_ECHEANCE',
	ADMIN_IMPORT = 'ADMIN_IMPORT',
	ADMIN_SOCIETE = 'ADMIN_SOCIETE',
	ETAT_LIEUX = 'ETAT_LIEUX',
	GRILLE_FLUIDITE = 'GRILLE_FLUIDITE',
	SGI_LOT = 'SGI_LOT',
	VEHICULE_RESERVATION = 'VEHICULE_RESERVATION',
	VEHICULE_POOL = 'VEHICULE_POOL',
	FACTURE_LAD = 'FACTURE_LAD',
	ADMIN_SECRET = 'ADMIN_SECRET',
	ADMIN_TENANT_AUTORISATION = 'ADMIN_TENANT_AUTORISATION',
	ADMIN_AUTHENTIFICATION = 'ADMIN_AUTHENTIFICATION',
	REGLE_AUTOMATIQUE = 'REGLE_AUTOMATIQUE',
	ADMIN_CONVERSATION = 'ADMIN_CONVERSATION',
	ADMIN_AVANTAGE_NATURE_ABATTEMENT = 'ADMIN_AVANTAGE_NATURE_ABATTEMENT'
}

/**
 * Type de tenant
 */
export enum TypeTenant {
	/** Enumération **/
	ROOT = 'ROOT',
	REVENDEUR = 'REVENDEUR',
	CLIENT = 'CLIENT'
}

/**
 * Type de groupe
 */
export enum TypeGroupe {
	/** Enumération **/
	YOURWAY = 'YOURWAY',
	COMPTABILITE = 'COMPTABILITE',
	EXPENSE = 'EXPENSE',
	RESPONSABLE = 'RESPONSABLE',
	ADMIN = 'ADMIN'
}

/**
 * Type de restriction
 */
export enum TypeRestriction {
	/** Enumération **/
	SERVICE = 'SERVICE',
	SOCIETE = 'SOCIETE',
	VEHICULE_POOL = 'VEHICULE_POOL',
	CLIENT = 'CLIENT'
}