import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { filter,first,map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { mapEntites } from './affectation.entites';
import { AffectationListItemComponent } from './affectation-list-item.component';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { AffectationService } from './affectation.service';
import { AppState } from 'src/app/domain/appstate';
import { User } from 'src/app/domain/user/user';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './affectation-list.component.html'
})
export class AffectationListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,AffectationListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private translateService: TranslateService,private rightService: RightService,public dashboardService: DashboardService,public extractionService: ExtractionService,private affectationService: AffectationService,public connecteurService: ConnecteurService) {

	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(filter(user => !!user),first()).subscribe({
			next: user => {
				//Mise à jour de l'utilisateur
				this.user = user;

				//Définition de la liste
				this.liste = new ListView<any,AffectationListItemComponent>({
					uri: `/controller/Vehicule/filtreAffectations`,
					title: this.translateService.instant('vehicule.affectation.liste.title'),
					component: AffectationListItemComponent,
					isTile: true,
					listeFilters: [{
						clef: 'vehicule',
						autocomplete: {
							type: 'vehicule'
						},
						type: TypeFilter.AUTOCOMPLETE,
						isDefault: true
					},{
						clef: 'user',
						autocomplete: {
							type: 'user'
						},
						type: TypeFilter.AUTOCOMPLETE,
						isDefault: true
					},{
						clef: 'dateDebut',
						type: TypeFilter.DATE
					},{
						clef: 'dateFin',
						type: TypeFilter.DATE
					},{
						clef: 'typeAffectation',
						type: TypeFilter.LONG,
						listeValues: this.affectationService.getListeTypesAffectation()
					}],
					noSearch: this.user.type == 'VIRTUAL',
					isLoadingDisabled: this.user.type == 'VIRTUAL',
					defaultOrder: '-dateDebut,vehicule.reference,user.nom',
					hasMainAction: () => this.user?.type != 'VIRTUAL' && (this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') || this.rightService.hasRight(TypeDroit.VEHICULE_CONDUCTEUR,'creation')),
					doMainAction: () => {
						//Ajout d'un nouvel élément
						!this.liste.data?.content?.some(i => !i.idAffectation) && this.liste.addItem({
							isDisplayed: true,
							synchroImputationUser: true
						});
					},
					getKeyFieldName: () => 'idAffectation'
				});

				//Vérification du type d'utilisateur
				if (this.user?.type != 'VIRTUAL') {
					//Vérification de la présence d'un dashboard
					this.dashboardService.hasDashboard().subscribe({
						next: (hasDashboard: boolean) => {
							//Définition de la présence d'un dashboard
							this.hasDashboard = hasDashboard;
						}
					});

					//Vérification de la présence d'imports
					this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
						next: (isImportAvailable: boolean) => {
							//Définition de la présence d'imports
							this.hasImports = isImportAvailable;
						}
					});
				} else if (this.user?.externalAction?.idEntite) {
					//Chargement de l'entretien
					this.affectationService.loadAffectation(this.user.externalAction.idEntite).subscribe({
						next: result => {
							//Définition de la liste
							this.liste.data = {
								content: [Object.assign(result.data.affectation,{
									isDisplayed: true
								})]
							};
						}
					});
				}
			}
		});
	}
}