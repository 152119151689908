<form #importTemplateForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="import-template">
	<div class="modal-header">
		<h4 class="modal-title"><span translate>connecteur.import.template.title</span>&#160;-&#160;<span>{{ format.description }}</span></h4>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
					<label translate>connecteur.import.template.libelle</label>
					<div>
						<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="importTemplate.libelle" required/>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': typeFormatField.invalid }">
					<label translate>connecteur.import.template.typeFormat.item</label>
					<div>
						<selectpicker name="typeFormat" #typeFormatField="ngModel" [(ngModel)]="importTemplate.typeFormat" required>
							<mat-option *ngFor="let typeFormat of listeTypesFormat" [value]="typeFormat.code">{{ typeFormat.libelle }}</mat-option>
						</selectpicker>
					</div>
				</div>
			</div>
		</div>
		<div class="row sortable-container">
			<div class="col-md-6">
				<div class="form-group">
					<label translate>connecteur.import.template.availableField</label>
					<div [ngStyle]="{ 'height.px': (listeFields.length > 10 ? 10 : listeFields.length + 1) * 50,'min-height.px': (importTemplate.listeFields.length > 10 ? 10 : importTemplate.listeFields.length + 1) * 50 }" cdkDropList #availableList="cdkDropList" [cdkDropListData]="listeFields" [cdkDropListConnectedTo]="[usedList]" class="sortable-list" [cdkDropListEnterPredicate]="onListFieldAvailableEnterPredicate" (cdkDropListDropped)="onDroppedField($event)">
						<ng-container *ngFor="let field of listeFields; let i = index;">
							<div class="sortable-item" [cdkDragData]="field" cdkDrag>
								<ng-container [ngTemplateOutlet]="fieldItemTemplate" [ngTemplateOutletContext]="{ field,index: i }"></ng-container>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label translate>connecteur.import.template.usedField</label>
					<div [ngStyle]="{ 'height.px': (importTemplate.listeFields.length > 10 ? 10 : importTemplate.listeFields.length + 1) * 50,'min-height.px': (listeFields.length > 10 ? 10 : listeFields.length + 1) * 50 }" cdkDropList #usedList="cdkDropList" [cdkDropListData]="importTemplate.listeFields" [cdkDropListConnectedTo]="[availableList]" class="sortable-list" [cdkDropListEnterPredicate]="onListFieldUsedEnterPredicate" (cdkDropListDropped)="onDroppedField($event)">
						<ng-container *ngFor="let field of importTemplate.listeFields; let i = index;">
							<div class="sortable-item" [cdkDragData]="field" cdkDrag>
								<ng-container [ngTemplateOutlet]="fieldItemTemplate" [ngTemplateOutletContext]="{ field,index: i }"></ng-container>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="close()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="importTemplateForm.invalid" (click)="saveImportTemplate();">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>
<ng-template #fieldItemTemplate let-field="field" let-index="index">
	<div class="field">
		<mat-icon class="sortable-icon" fontIcon="drag_handle"/>
		<span [ngClass]="{ 'required': field?.required }">{{ field.name }}</span>
		<mat-icon class="m-l-5" [tooltip]="getFieldDescription(field)">info_outline</mat-icon>
	</div>
</ng-template>