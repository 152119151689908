import { Type } from '@angular/core';
import { Subscription } from 'rxjs';

import { Filter } from '.';
import { Action } from './action';
import { ListItem } from './list-item';
import { Page } from 'src/app/domain/common/http/list-result';
import { ExtraData } from './extra-data';
import { Selector } from './selector';

/**
 * Composant décrivant une liste
 */
export class ListView<T extends ListItem,K> {
	/** Données **/
	title?: string;
	uri: string | (() => string);
	defaultOrder?: string;
	defaultSearch?: string;
	data: Page<T>;
	listeFilters?: Array<Filter>;
	hasMainAction?: () => boolean;
	doMainAction?: (data?: any) => void;
	listeActions?: Array<Action>;
	listeSelectors?: Array<Selector>;
	selectedSelector?: any;
	component: Type<K>;
	factory?: new () => any;
	listeStaticFilters: Array<Filter> | (() => Array<Filter>) = [];
	listeSelectedFilters: Array<Filter> = [];
	redirectToList?: () => any;
	extraOptions?: any;
	isLocal?: boolean;
	isPagination?: boolean;
	nbSelectedItems?: number = 0;
	typeActionMasse?: 'SELECTION' | 'FULL' = 'SELECTION';
	nbObjetsParPage?: number;
	isTile?: boolean;
	noZoomEffect?: boolean;
	isFilterShown?: boolean;
	isSearchExtended?: boolean;
	isContentHidden?: boolean;
	onBeforeRefresh?: (liste: ListView<T,K>) => void;
	onRefresh?: (liste: ListView<T,K>,result: any) => void;
	getKeyFieldName?: () => string;
	extraData?: ExtraData;
	listeExtraData?: () => Array<{ _type: string }>;
	generateDisplayedContent?: (liste: ListView<T,K>) => Array<T>;
	placeholder?: string;
	searchAction?: (liste: ListView<T,K>,searchText: string) => void;
	extraBodyClass?: string;
	noSearch?: boolean;
	mapDefaultSearchEncryptedFields?: { [clef: string]: string };
	isLoadingDisabled?: boolean;

	/**
	 * Rafraichissement de la liste
	 */
	refresh?: (isResetFilters?: boolean) => void;

	/**
	 * Ajout d'un élément à la liste
	 */
	addItem?: (item: T) => void;

	/**
	 * Numéro de page
	 */
	numPage: number = 0;

	/**
	 * Souscription au rafraichissement des données
	 */
	refreshDataSubscription: Subscription = null;

	/**
	 * Constructeur
	 */
	constructor({ title,uri,defaultOrder,component,factory,listeFilters = [],listeStaticFilters = [],hasMainAction,doMainAction = null,listeActions = [],listeSelectors = [],selectedSelector,extraOptions = null,isLocal = false,isPagination = true,nbObjetsParPage,isTile,noZoomEffect,isContentHidden,onBeforeRefresh,onRefresh,getKeyFieldName,listeExtraData,generateDisplayedContent,placeholder,searchAction,extraBodyClass,noSearch,mapDefaultSearchEncryptedFields,defaultSearch,isLoadingDisabled }: { title?: string,uri: string | (() => string),defaultOrder?: string,component: Type<K>,factory?: new () => any,listeFilters?: Array<Filter>,listeStaticFilters?: Array<Filter> | (() => Array<Filter>),hasMainAction?: () => boolean,doMainAction?: (creationData?: any) => void,listeActions?: Array<Action>,listeSelectors?: Array<Selector>,selectedSelector?: any,extraOptions?: any,isLocal?: boolean,isPagination?: boolean,nbObjetsParPage?: number,isTile?: boolean,noZoomEffect?: boolean,isContentHidden?: boolean,onBeforeRefresh?: (liste: ListView<T,K>) => void,onRefresh?: (liste: ListView<T,K>,result: any) => void,getKeyFieldName?: () => string,listeExtraData?: () => Array<{ _type: string }>,generateDisplayedContent?: (liste: ListView<T,K>) => Array<T>,placeholder?: string,searchAction?: (liste: ListView<T,K>,searchText: string) => void,extraBodyClass?: string,noSearch?: boolean,mapDefaultSearchEncryptedFields?: { [clef: string]: string },defaultSearch?: string,isLoadingDisabled?: boolean }) {
		//Définition des données
		this.title = title;
		this.uri = uri;
		this.defaultOrder = defaultOrder;
		this.component = component;
		this.factory = factory;
		this.listeFilters = listeFilters;
		this.listeStaticFilters = listeStaticFilters;
		this.hasMainAction = hasMainAction;
		this.doMainAction = doMainAction;
		this.listeActions = listeActions;
		this.listeSelectors = listeSelectors;
		this.selectedSelector = selectedSelector;
		this.extraOptions = extraOptions;
		this.isLocal = isLocal;
		this.isPagination = isPagination;
		this.nbObjetsParPage = isLocal ? (nbObjetsParPage || 10) : nbObjetsParPage;
		this.isTile = isTile;
		this.noZoomEffect = noZoomEffect;
		this.isContentHidden = isContentHidden;
		this.onBeforeRefresh = onBeforeRefresh;
		this.onRefresh = onRefresh;
		this.getKeyFieldName = getKeyFieldName;
		this.listeExtraData = listeExtraData;
		this.generateDisplayedContent = generateDisplayedContent;
		this.placeholder = placeholder
		this.searchAction = searchAction;
		this.extraBodyClass = extraBodyClass;
		this.noSearch = noSearch;
		this.mapDefaultSearchEncryptedFields = mapDefaultSearchEncryptedFields;
		this.defaultSearch = defaultSearch;
		this.isLoadingDisabled = isLoadingDisabled;
	}
}