import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { AvantageNatureAbattementService } from './avantage-nature-abattement.service';

@Component({
	templateUrl: './avantage-nature-abattement-list-item.component.html'
})
export class AvantageNatureAbattementListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private avantageNatureAbattementService: AvantageNatureAbattementService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification d'un abattement
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idAbattement,object?.idAbattement && !this.data?.idAbattement);
	}

	/**
	 * Suppression d'un abattement
	 */
	deleteAbattement() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'abattement
					this.avantageNatureAbattementService.deleteAvantageNatureAbattement(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'abattement
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}
}