import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TenantOffreListItemComponent } from './tenant-offre-list-item.component';

@Component({
	selector: 'tenant-offre-list',
	templateUrl: './tenant-offre-list.component.html'
})
export class TenantOffreListComponent extends PageContentItem implements OnInit {
	/** Tenant **/
	@Input() tenant: any;

	/** Données **/
	liste: ListView<any,TenantOffreListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TenantOffreListItemComponent>({
			uri: () => `/controller/ClientCRM/filtreOffresForClientCRM/${this.tenant.clientCRM.idClient}`,
			title: this.translateService.instant('tenant.elements.offre.liste'),
			component: TenantOffreListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'offre',
				autocomplete: {
					type: 'offre'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}]
		});
	}
}