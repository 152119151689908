<div class="modal-header">
	<h4 class="modal-title" translate>demandeVehicule.livraison.title</h4>
</div>
<div class="modal-body">
	<form #livraisonForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>demandeVehicule.livraison.vehicule.titre</h4>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': !livraison.vehicule?.reference }">
			<label>
				<span translate>demandeVehicule.livraison.vehicule.immatriculation</span>
			</label>
			<div>
				<a [routerLink]="[]" (click)="!livraison.vehicule?.reference ? selectImmatriculation() : showImmatriculation()">
					<span *ngIf="livraison.vehicule?.reference">{{ livraison.vehicule.reference }}<span *ngIf="livraison.vehicule?.immatriculation?.typeSource == 'IMPORT'" class="c-green">&#160;{{ 'vehicule.immatriculation.SIV' | translate }}</span></span>
					<span *ngIf="!livraison.vehicule?.reference" translate>demandeVehicule.livraison.vehicule.aRenseigner</span>
				</a>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': dateEntree.invalid }">
			<label translate>demandeVehicule.livraison.vehicule.dateEntree</label>
			<div class="half">
				<input type="text" name="dateEntree" class="form-control" #dateEntree="ngModel" [(ngModel)]="livraison.dateEntree" (ngModelChange)="livraisonForm.controls['dateAffectation']?.updateValueAndValidity()" datePicker format="datetime" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': societeImputation.invalid }">
			<label translate>demandeVehicule.livraison.societeImputation</label>
			<div>
				<autocomplete name="societeImputation" type="societe" #societeImputation="ngModel" [(ngModel)]="livraison.societeImputation" (ngModelChange)="livraison.serviceImputation = null;" required></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': serviceImputation.invalid }">
			<label translate>demandeVehicule.livraison.serviceImputation</label>
			<div>
				<autocomplete name="serviceImputation" type="service" #serviceImputation="ngModel" [(ngModel)]="livraison.serviceImputation" [filter]="{ idSociete: livraison.societeImputation?.idService }" [disabled]="!livraison.societeImputation" [required]="livraison.societeImputation?.extension?.nbSousServices > 0"></autocomplete>
			</div>
		</div>
		<div class="row p-t-20">
			<div class="col-xs-12 d-flex justify-content-space-between">
				<h4 translate>demandeVehicule.livraison.contrat.titre</h4>
				<mat-button-toggle-group [(ngModel)]="livraison.createContrat" (ngModelChange)="onCreateContratChange()" name="createContrat">
					<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
					<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</div>
		<div [collapse]="!livraison.createContrat">
			<ng-container *ngIf="livraison.createContrat">
				<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
					<label translate>demandeVehicule.livraison.contrat.fournisseur</label>
					<div>
						<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="livraison.financement.fournisseur" [filter]="{ isShowAll: true }" required></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': typeFinancement.invalid }">
					<label translate>demandeVehicule.livraison.contrat.typeFinancement</label>
					<div>
						<autocomplete name="typeFinancement" type="typeFinancement" #typeFinancement="ngModel" [(ngModel)]="livraison.financement.typeFinancement" required></autocomplete>
					</div>
				</div>
				<ng-container *ngIf="livraison.financement.typeFinancement && livraison.financement.typeFinancement.mode != 'ACQUISITION'">
					<div class="form-group" [ngClass]="{ 'has-error': duree.invalid }">
						<label translate>demandeVehicule.livraison.contrat.duree</label>
						<div class="half">
							<input type="text" name="duree" class="form-control" #duree="ngModel" [(ngModel)]="livraison.financement.duree" required nNumber min="1" max="300"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': distance.invalid }">
						<label translate>demandeVehicule.livraison.contrat.distance</label>
						<div class="input-group half">
							<input type="text" name="distance" class="form-control" #distance="ngModel" [(ngModel)]="livraison.financement.distance" required nNumber min="0"/>
							<div class="input-group-addon" *ngIf="user.unite">
								<span>{{ user.unite.libelleCourt }}</span>
							</div>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': typeAffichagePrix.invalid }">
						<label translate>demandeVehicule.livraison.contrat.typeAffichagePrix</label>
						<div>
							<selectpicker name="typeAffichagePrix" #typeAffichagePrix="ngModel" [(ngModel)]="livraison.financement.typeAffichagePrix" [disabled]="!livraison.vehicule" required>
								<mat-option *ngFor="let type of listeTypesAffichagePrix" [value]="type.code">{{ type.libelle }}</mat-option>
							</selectpicker>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': loyerMensuel.invalid }">
						<label translate>demandeVehicule.livraison.contrat.loyerMensuel</label>
						<div>
							<amount name="loyerMensuel" #loyerMensuel="ngModel" [(ngModel)]="livraison.financement.loyerMensuel" [devise]="livraison.financement.devise" (onDeviseChange)="livraison.financement.devise = $event;" required></amount>
						</div>
					</div>
				</ng-container>
				<div class="form-group" *ngIf="livraison.financement.typeFinancement?.mode == 'ACQUISITION' || livraison.financement.typeFinancement?.mode == 'LOCATION_OPTION_ACHAT'">
					<label translate>demandeVehicule.livraison.contrat.concessionnaire</label>
					<div>
						<autocomplete name="concessionnaire" type="fournisseur" [(ngModel)]="livraison.financement.concessionnaire"></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': referenceContrat.invalid }">
					<label translate>demandeVehicule.livraison.contrat.reference</label>
					<div>
						<input type="text" name="referenceContrat" class="form-control" #referenceContrat="ngModel" [(ngModel)]="livraison.financement.reference" required/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }" *ngIf="livraison.financement.typeFinancement?.mode != 'ACQUISITION'">
					<label translate>demandeVehicule.livraison.contrat.dateDebut</label>
					<div class="half">
						<input type="text" name="dateDebut" class="form-control" #dateDebut="ngModel" [(ngModel)]="livraison.financement.dateDebut" datePicker [storeDate]="true" required/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': dateAchat.invalid }" *ngIf="livraison.financement.typeFinancement?.mode == 'ACQUISITION'">
					<label translate>demandeVehicule.livraison.contrat.dateAchat</label>
					<div class="half">
						<input type="text" name="dateAchat" class="form-control" #dateAchat="ngModel" [(ngModel)]="livraison.financement.dateAchat" datePicker [storeDate]="true" required/>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="row p-t-20">
			<div class="col-xs-12 d-flex justify-content-space-between">
				<h4 translate>demandeVehicule.livraison.affectation.titre</h4>
				<mat-button-toggle-group [(ngModel)]="livraison.createAffectation" (ngModelChange)="onCreateAffectationChange()" name="createAffectation">
					<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
					<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</div>
		<div [collapse]="!livraison.createAffectation">
			<ng-container *ngIf="livraison.createAffectation">
				<div class="form-group" [ngClass]="{ 'has-error': typeAffectation.invalid }">
					<label translate>demandeVehicule.livraison.affectation.type</label>
					<div>
						<selectpicker name="typeAffectation" #typeAffectation="ngModel" [(ngModel)]="livraison.typeAffectation" required>
							<mat-option *ngFor="let type of listeTypesAffectation" [value]="type.code">{{ type.libelle }}</mat-option>
						</selectpicker>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': userAffectation.invalid }">
					<label translate>demandeVehicule.livraison.affectation.user</label>
					<div>
						<autocomplete name="user" type="user" #userAffectation="ngModel" [(ngModel)]="livraison.user" required></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': dateAffectation.invalid }">
					<label translate>demandeVehicule.livraison.affectation.date</label>
					<div>
						<input type="text" name="dateAffectation" class="form-control" #dateAffectation="ngModel" [(ngModel)]="livraison.dateAffectation" datePicker format="datetime" [minDate]="livraison.dateEntree" [validator]="isDateAffectationValid()" required/>
					</div>
				</div>
				<div class="form-group" *ngIf="livraison.typeAffectation == 'VEHICULE_FONCTION'">
					<label translate>demandeVehicule.livraison.affectation.typeCalculAvantageNature</label>
					<div>
						<selectpicker name="typeCalculAvantageNature" #typeCalculAvantageNatureField="ngModel" [(ngModel)]="livraison.typeCalculAvantageNature">
							<mat-option *ngFor="let typeCalculAvantageNature of listeTypesCalculAvantageNature" [value]="typeCalculAvantageNature.code">{{ typeCalculAvantageNature.libelle }}</mat-option>
						</selectpicker>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': montantAen.invalid }" *ngIf="livraison.typeAffectation == 'VEHICULE_FONCTION'">
					<label translate>demandeVehicule.livraison.affectation.montantAen</label>
					<div class="input-group half">
						<input type="text" name="montantAen" class="form-control" #montantAen="ngModel" [(ngModel)]="livraison.montantAvantageNature" nNumber="2" min="0"/>
						<div class="input-group-addon no-border">{{ user.devise }}</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': montantParticipation.invalid }" *ngIf="livraison.typeAffectation == 'VEHICULE_FONCTION'">
					<label translate>demandeVehicule.livraison.affectation.montantParticipation</label>
					<div class="input-group half">
						<input type="text" name="montantParticipation" class="form-control" #montantParticipation="ngModel" [(ngModel)]="livraison.montantParticipation" nNumber="2" min="0"/>
						<div class="input-group-addon no-border">{{ user.devise }}</div>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="row p-t-20" *ngIf="demandeVehicule.vehiculeSource && !demandeVehicule.vehiculeSource.dateSortie">
			<div class="col-xs-12 d-flex justify-content-space-between">
				<h4 translate>demandeVehicule.livraison.sortieVehicule</h4>
				<mat-button-toggle-group [(ngModel)]="livraison.takeOutVehicule" name="takeOutVehicule">
					<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
					<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</div>
		<ng-container *ngIf="canDuplicateAttachments">
			<div class="row p-t-20">
				<div class="col-xs-12 d-flex justify-content-space-between">
					<h4 translate>demandeVehicule.livraison.duplicationAttachments.titre</h4>
					<mat-button-toggle-group [(ngModel)]="livraison.duplicationAttachments" name="duplicationAttachments">
						<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
						<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
			<ng-container *ngIf="livraison.duplicationAttachments">
				<div class="form-group flex-direction-row align-items-center justify-content-space-between m-t-20">
					<label translate>{{ 'demandeVehicule.livraison.duplicationAttachments.nbVersVehicule' | pluralTranslate:nbAttachments }}</label>
					<div>
						<mat-chip-listbox>
							<mat-chip><span>{{ nbAttachments }}</span></mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
				<div class="form-group flex-direction-row align-items-center justify-content-space-between m-t-20" *ngIf="livraison.createContrat">
					<label translate>{{ 'demandeVehicule.livraison.duplicationAttachments.nbVersContrat' | pluralTranslate:(demandeVehicule.financement?.listeLinks?.length || 0) }}</label>
					<div>
						<mat-chip-listbox>
							<mat-chip><span>{{ demandeVehicule.financement?.listeLinks?.length || 0 }}</span></mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="livraisonForm.invalid || !livraison.vehicule?.reference" (click)="doContinue();">
		<span translate>actions.continuer</span>
	</button>
</div>