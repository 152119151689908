import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ServiceService } from './service.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';

@Component({
	selector: 'service-edit',
	templateUrl: './service-edit.component.html'
})
export class ServiceEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() service: any;

	/** Suppression de l'objet **/
	@Input() deleteService: () => void;

	/** Indicateur de saisie du parent **/
	@Input() isForceParent: boolean;

	/** Liste des types de service **/
	listeTypes: Array<{ code: string,libelle: string }>;

	/** Liste des types de règles de calcul de l'avantage en nature **/
	listeTypesCalculAvantageNature: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private serviceService: ServiceService,private vehiculeService: VehiculeService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des types de service
		this.listeTypes = this.serviceService.getListeTypes();

		//Définition de la liste des types de règle de calcul de l'avantage en nature
		this.listeTypesCalculAvantageNature = this.vehiculeService.getListeTypesCalculAvantageNature();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveService() {
		//Enregistrement de l'objet
		this.serviceService.saveService(this.service).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.service = Object.assign(this.service,result.data?.service);

					//Fermeture du formulaire
					this.close(this.service);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Ajout du reference
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Vérification de l'utilisation dans un plan comptable
						if (result?.data?.isUsedInPlanComptable)
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('service.error.utilisationPlanComptable'));
						else
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}