import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,filter,first,map } from 'rxjs';

import { TypeChart } from 'src/app/domain/chart/chart';
import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { ChartPreviewComponent } from './chart-preview.component';

@Injectable()
export class ChartService {
	/** Liste des types de diffusion **/
	private readonly listeTypesDiffusion: Array<string> = ['TOUS','REVENDEUR','CLIENT','ROOT'];

	/** Liste des types de graphique **/
	private readonly listeTypesGraphique: Array<{ type: TypeChart,icon: string,iconType?: string,listeOptions?: Array<string>,listeSubTypes?: Array<string> }> = [{
		type: TypeChart.BAR,
		icon: 'bar_chart',
		listeOptions: ['DATA','CONDITION','SEUIL','INTERACTION'],
		listeSubTypes: ['NON_DEFINI','GROUPE','EMPILE']
	},{
		type: TypeChart.LINE,
		icon: 'show_chart',
		listeOptions: ['DATA','CONDITION','SEUIL','INTERACTION'],
		listeSubTypes: ['NON_DEFINI','MULTIPLE']
	},{
		type: TypeChart.AREA,
		icon: 'area_chart',
		listeOptions: ['DATA','CONDITION','SEUIL','INTERACTION'],
		listeSubTypes: ['NON_DEFINI','EMPILE']
	},{
		type: TypeChart.PIE,
		icon: 'pie_chart',
		listeOptions: ['DATA','CONDITION','INTERACTION']
	},{
		type: TypeChart.DONUT,
		icon: 'donut_large',
		listeOptions: ['DATA','CONDITION','INTERACTION']
	},{
		type: TypeChart.KPI,
		icon: 'pin',
		listeOptions: ['DATA','CONDITION','NOTIFICATION']
	}];

	/** Liste des types de tri **/
	private readonly listeTypesSort: Array<string> = ['LABEL','VALUE'];

	/** Liste des types d'ordre **/
	private readonly listeTypesOrder: Array<string> = ['ASCENDING','DESCENDING'];

	/** Liste des icônes pour les graphiques de type 'KPI' **/
	private readonly listeIcones: Array<{ reference: string,code: string }> = [{
		reference: 'archive',
		code: 'e149'
	},{
		reference: 'directions_bus_filled',
		code: 'eff6'
	},{
		reference: 'directions_car_filled',
		code: 'eff7'
	},{
		reference: 'local_car_wash',
		code: 'e542'
	},{
		reference: 'credit_card',
		code: 'e870'
	},{
		reference: 'add_shopping_cart',
		code: 'e854'
	},{
		reference: 'flash_on',
		code: 'e3e7'
	},{
		reference: 'local_gas_station',
		code: 'e546'
	},{
		reference: 'functions',
		code: 'e24a'
	},{
		reference: 'whatshot',
		code: 'e80e'
	},{
		reference: 'home',
		code: 'e88a'
	},{
		reference: 'local_hospital',
		code: 'e548'
	},{
		reference: 'tungsten',
		code: 'f07d'
	},{
		reference: 'lock',
		code: 'e897'
	},{
		reference: 'email',
		code: 'e0be'
	},{
		reference: 'map',
		code: 'e55b'
	},{
		reference: 'attach_money',
		code: 'e227'
	},{
		reference: 'euro',
		code: 'ea15'
	},{
		reference: 'navigation',
		code: 'e55d'
	},{
		reference: 'place',
		code: 'e55f'
	},{
		reference: 'local_parking',
		code: 'e54f'
	},{
		reference: 'print',
		code: 'e8ad'
	},{
		reference: 'receipt',
		code: 'e8b0'
	},{
		reference: 'format_quote',
		code: 'e244'
	},{
		reference: 'description',
		code: 'e873'
	},{
		reference: 'directions_railway_filled',
		code: 'eff8'
	},{
		reference: 'traffic',
		code: 'e565'
	},{
		reference: 'local_shipping',
		code: 'e558'
	},{
		reference: 'directions',
		code: 'e52e'
	},{
		reference: 'build',
		code: 'e869'
	},{
		reference: 'info',
		code: 'e88e'
	},{
		reference: 'error',
		code: 'e000'
	},{
		reference: 'warning',
		code: 'e002'
	},{
		reference: 'help',
		code: 'e887'
	},{
		reference: 'notifications',
		code: 'e7f4'
	},{
		reference: 'check_box',
		code: 'e834'
	},{
		reference: 'person',
		code: 'e7fd'
	},{
		reference: 'people',
		code: 'e7fb'
	},{
		reference: 'star',
		code: 'e838'
	},{
		reference: 'swap_horiz',
		code: 'e8d4'
	},{
		reference: 'swap_vert',
		code: 'e8d5'
	},{
		reference: 'timer',
		code: 'e425'
	},{
		reference: 'event',
		code: 'e878'
	},{
		reference: 'alarm',
		code: 'e855'
	},{
		reference: 'trending_down',
		code: 'e8e3'
	},{
		reference: 'trending_up',
		code: 'e8e5'
	},{
		reference: 'gavel',
		code: 'e90e'
	}];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private bsModalService: BsModalService) { }

	/**
	 * Suppression du graphique
	 */
	public deleteChart(chart: any): Observable<Result> {
		//Suppression du graphique
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Chart/deleteChart/${chart.idChart}`);
	}

	/**
	 * Chargement d'un graphique
	 */
	public loadChart(idChart: number) {
		//Chargement du graphique
		return this.http.post<Result>(`${environment.baseUrl}/controller/Chart/loadChart/${idChart}`,null);
	}

	/**
	 * Sauvegarde d'un graphique
	 */
	public saveChart(chart: any) {
		//Sauvegarde du graphique
		return this.http.put<Result>(`${environment.baseUrl}/controller/Chart/saveChart`,chart);
	}

	/**
	 * Chargement d'un graphique pour exécution
	 */
	public loadChartForExecution(idChart: number) {
		//Chargement d'un graphique
		return this.http.post<Result>(`${environment.baseUrl}/controller/Chart/loadChartForExecution/${idChart}`,null);
	}

	/**
	 * Récupération de la liste des types de diffusion traduits
	 */
	public getListeTypesDiffusion(): Array<{ code: string,libelle: string }> {
		//Création de la correspondance entre un code et son libellé
		return this.listeTypesDiffusion.map(code => ({
			code,
			libelle: this.translateService.instant('diffusion.'+code)
		}));
	}

	/**
	 * Duplication d'un graphique
	 */
	public duplicateChart(chart: any): Observable<Result> {
		//Duplication d'un graphique
		return this.http.post<Result>(`${environment.baseUrl}/controller/Chart/duplicateChart/${chart.idChart}`,null);
	}

	/**
	 * Récupération de la liste des types de graphique
	 */
	public getListeTypesGraphique(): Array<{ type: TypeChart,icon: string,iconType?: string }> {
		//Retour de la liste
		return this.listeTypesGraphique;
	}

	/**
	 * Récupération de la liste des types de tri
	 */
	public getListeTypesSort(): Array<{ code: string,libelle: string }> {
		//Liste des types de tri
		return this.listeTypesSort.map(code => ({
			code,
			libelle: this.translateService.instant('chart.typeSort.' + code)
		}));
	}

	/**
	 * Récupération de la liste des types d'ordre
	 */
	public getListeTypesOrder(): Array<{ code: string,libelle: string }> {
		//Liste des types d'ordre
		return this.listeTypesOrder.map(code => ({
			code,
			libelle: this.translateService.instant('chart.typeOrder.' + code)
		}));
	}

	/**
	 * Récupération de la liste des icônes
	 */
	public getListeIcones(): Array<{ reference: string,code: string }> {
		//Liste des icônes
		return [...this.listeIcones];
	}

	/**
	 * Affichage de la prévisualisation d'un graphique
	 */
	public previewChart(chart: any,options?: { canAddChart?: boolean,source?: 'GRAPHIQUE' | 'CHATBOT' }): Observable<boolean> {
		let bsModalRef: BsModalRef<ChartPreviewComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(ChartPreviewComponent,{
			initialState: {
				dashboardChart: {
					chart
				},
				canAddChart: options?.canAddChart,
				source: options?.source
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.isChartAdded),
			filter(isChartAdded => !!isChartAdded)
		);
	}
}