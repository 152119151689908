<div class="attachment" [fileUploader]="fileUploader" (onFilesDrop)="onFilesDrop($event)">
	<div class="modal-header" *ngIf="!options.inline">
		<h4 class="modal-title" [translate]="'attachment.title.' + (!options.readOnly ? 'edition' : 'consultation')"></h4>
	</div>
	<div [ngClass]="{ 'modal-body': !options.inline }">
		<div *ngIf="!options.inline">
			<mat-button-toggle-group class="pull-right m-t-10 m-b-10" [(ngModel)]="modeAffichage" (change)="selectModeAffichage($event.value);">
				<mat-button-toggle value="grid"><icon name="apps"></icon></mat-button-toggle>
				<mat-button-toggle value="liste"><icon name="menu"></icon></mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div class="clearfix"></div>

		<div class="row" *ngIf="!options.readOnly && ((options.listeTypesDroit || null | right:'creation') || options.inline) && (!options.imageSwiper || options.imageSwiper && getListeLinksForType().length == 0)">
			<div class="col-xs-12">
				<div #fileSelection [ngClass]="{ 'attachment-inline': options.inline }">
					<div class="description alert clickable" matRipple *ngIf="!options.queueLimit || ((fileUploader?.queue?.length || 0) + (!options.inline && liste?.data?.content?.length || 0) + (options.inline && options.owningEntity?.listeLinks?.length || 0)) < options.queueLimit">
						<div class="d-flex">
							<icon name="download" class="m-r-10"></icon>
							<div style="flex: 1;">
								<strong translate>attachment.dropZone.message</strong>
								<div>
									<div translate>attachment.dropZone.messageDescription</div>
									<div *ngIf="options.filters"><span translate>attachment.dropZone.extensionsDescription</span>&#160;:&#160;<span *ngFor="let filter of options.filters.split('|'); let first = first;"><span>{{ (!first ? ', ' : '') + filter }}</span></span></div>
									<div *ngIf="options.characterRestrictions"><span translate>attachment.dropZone.characterRestrictionsDescription</span>&#160;:&#160;<span *ngFor="let character of options.characterRestrictions.split('|'); let first = first;"><span>{{ (!first ? ' ' : '') + character }}</span></span></div>
									<div class="italic" translate>attachment.dropZone.tailleMax</div>
								</div>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
				</div>

				<div class="alert alert-warning" *ngIf="options.queueLimit && ((fileUploader?.queue?.length || 0) + (!options.inline && liste?.data?.content?.length || 0) + (options.inline && options.owningEntity?.listeLinks?.length || 0)) == options.queueLimit">
					<icon name="info"></icon>
					<div>
						<strong translate>attachment.dropZone.fichierExistant</strong>
						<div translate>attachment.dropZone.fichierExistantDescription</div>
					</div>
				</div>
			</div>
		</div>

		<list-view [liste]="liste" *ngIf="liste && !options.inline && options.idObjet" [hidden]="!!options.queueLimit"></list-view>

		<div class="text-center p-10" [ngClass]="{ 'tile': liste.isTile }" *ngIf="!options.inline && !((fileUploader?.queue?.length || 0) + (liste?.data?.content?.length || 0))">
			<h5><span translate>liste.empty</span></h5>
		</div>

		<div class="row" *ngIf="(modeAffichage == 'grid' || options.inline) && !options.imageSwiper" [ngClass]="{ 'p-5 p-t-30': options.inline }">
			<ng-container *ngIf="!!options.idObjet">
				<div class="col-sm-4" *ngFor="let attachment of liste?.data?.content; let idxAttachment = index;">
					<div class="thumbnail">
						<div class="top-actions" *ngIf="TypeAttachmentContext[options.typeAttachment]?.isMobile && attachment.typeOrigineSaisie == 'WEB' && (options.listeTypesDroit || null | right:'creation')" (click)="updateVisibility(attachment,idxAttachment)">
							<mat-icon color="primary" *ngIf="attachment.visibleMobile" [tooltip]="'attachment.mobile.visible.oui' | translate">visibility</mat-icon>
							<mat-icon *ngIf="!attachment.visibleMobile" [tooltip]="'attachment.mobile.visible.oui' | translate">visibility_off</mat-icon>
						</div>
						<div class="selector">
							<div class="btn-group">
								<button class="btn" *ngIf="!options.readOnly && ((options.listeTypesDroit || null | right:'creation') || attachment.type == TypeAttachment.NON_DEFINI) && options.typeAttachment == attachment.type" (click)="deleteAttachment(attachment,idxAttachment);" [tooltip]="'actions.supprimer' | translate"><icon name="delete" class="c-red"></icon></button>
								<button class="btn" *ngIf="isFileImage(attachment.name)" (click)="editImage(attachment)" [tooltip]="'actions.afficher' | translate"><icon class="c-green" name="search"></icon></button>
								<button class="btn" (click)="downloadAttachment(attachment);" [tooltip]="'actions.telecharger' | translate"><icon name="download" class="c-green"></icon></button>
							</div>
						</div>
						<div class="content">
							<thumb [file]="attachment" [height]="100"></thumb>
						</div>
						<div class="caption text-center"></div>
						<div class="title text-center">
							<a href="controller/Blob/readBlob/{{ attachment.idBlob }}" target="_new" [tooltip]="attachment.name">{{ attachment.name }}</a>
						</div>
						<div class="checkbox text-center" *ngIf="options.isSelection">
							<label class="p-l-15"><input type="checkbox" [(ngModel)]="attachment.isSelected"/><i class="input-helper"></i></label>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="!options.idObjet">
				<div class="col-sm-4" *ngFor="let link of options.owningEntity?.listeLinks; let idxLink = index;">
					<div class="thumbnail">
						<div class="selector">
							<div class="btn-group">
								<button class="btn" *ngIf="options.context == 'NOTIFICATION' || (!options.readOnly && ((options.listeTypesDroit || null | right:'creation') || options.typeAttachment == TypeAttachment.NON_DEFINI)) && options.typeAttachment == link.attachment.type" (click)="removeLink(link,idxLink)" [tooltip]="'actions.supprimer' | translate"><icon name="delete" class="c-red"></icon></button>
								<button class="btn" *ngIf="options.context != 'NOTIFICATION' && isFileImage(link.attachment.name)" (click)="editImage(link.attachment)" [tooltip]="'actions.afficher' | translate"><icon class="c-green" name="search"></icon></button>
								<button class="btn" *ngIf="options.context != 'NOTIFICATION'" (click)="downloadAttachment(link.attachment);" [tooltip]="'actions.telecharger' | translate"><icon name="download" class="c-green"></icon></button>
							</div>
						</div>
						<div class="content">
							<thumb [file]="link.attachment" [height]="100"></thumb>
						</div>
						<div class="caption text-center"></div>
						<div class="title text-center">
							<a href="controller/Blob/readBlob/{{ link.attachment.idBlob }}" target="_new" [tooltip]="link.attachment.name">{{ link.attachment.name }}</a>
						</div>
					</div>
				</div>
			</ng-container>

			<div class="col-sm-4" *ngFor="let item of fileUploader.queue; let idxItem = index">
				<div class="thumbnail uploading" [ngClass]="{ 'infected': item.isUnsafe,'unsafe': item.isUnsafe === null }">
					<div class="selector">
						<div class="btn-group">
							<button class="btn" *ngIf="(options.listeTypesDroit || null | right:'creation') || options.typeAttachment == TypeAttachment.NON_DEFINI" (click)="fileUploader.removeItem(idxItem)" [tooltip]="'actions.supprimer' | translate"><icon name="delete" class="c-red"></icon></button>
							<button class="btn" *ngIf="(options.listeTypesDroit || null | right:'creation') && isFileImage(item.name)" (click)="editImage(item,idxItem)" [tooltip]="'actions.modifier' | translate"><icon name="edit"></icon></button>
							<button class="btn" *ngIf="!options.disableUploadUnitaire" (click)="uploadItem(idxItem);" [tooltip]="'actions.enregistrer' | translate"><icon name="check" class="c-green"></icon></button>
						</div>
					</div>
					<div class="content">
						<thumb [file]="item" [height]="100"></thumb>
					</div>
					<div class="caption text-center">
						<div *ngIf="!item.isFinished" class="progress" style="margin-bottom: 0;">
							<div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': (item.progress$ ? (item.progress$ | async) : 0) + '%' }"></div>
						</div>
					</div>
					<div class="title text-center">{{ item.name }}</div>
					<icon name="warning" class="alert" *ngIf="item.isUnsafe || item.isUnsafe === null" [title]="getBlobAlertTitle(item.isUnsafe,item.name)"></icon>
				</div>
			</div>
		</div>

		<div *ngIf="modeAffichage == 'liste' && !options.inline" class="listview lv-bordered lv-sm liste">
			<div class="lv-body">
				<ng-container *ngIf="!!options.idObjet">
					<div class="lv-item" *ngFor="let attachment of liste?.data?.content; let idxAttachment = index;">
						<div class="pull-left col-md-2 text-center">
							<thumb [file]="attachment" [height]="60"></thumb>
						</div>
						<div class="media-body">
							<div class="lv-title">
								<a href="controller/Blob/readBlob/{{ attachment.idBlob }}" target="_new" [tooltip]="attachment.name">{{ attachment.name }}</a>
							</div>
							<ul class="lv-attrs">
								<li><span translate>attachment.formulaire.date</span>&#160;:&#160;<span>{{ attachment.dateUpdate | date:'short' }}</span></li>
								<li><span translate>attachment.formulaire.taille</span>&#160;:&#160;<span>{{ attachment.size | bytes }}</span></li>
								<li *ngIf="TypeAttachmentContext[options.typeAttachment]?.isMobile && attachment.typeOrigineSaisie == 'WEB' && (options.listeTypesDroit || null | right:'creation')"><span translate>attachment.mobile.visible.title</span>&#160;:&#160;<span [translate]="'common.'+(attachment.visibleMobile ? 'visible' : 'nonVisible')"></span></li>
							</ul>

							<div class="lv-actions actions dropdown" *ngIf="!options.isSelection">
								<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
								<ul class="dropdown-menu dropdown-menu-right">
									<li *ngIf="!options.readOnly && ((options.listeTypesDroit || null | right:'creation') || options.typeAttachment == TypeAttachment.NON_DEFINI) && options.typeAttachment == attachment.type"><a [routerLink]="[]" (click)="deleteAttachment(attachment,idxAttachment);" translate>actions.supprimer</a></li>
									<li *ngIf="(options.listeTypesDroit || null | right:'creation') && isFileImage(attachment.name)"><a [routerLink]="[]" (click)="editImage(attachment,null)" translate>actions.afficher</a></li>
									<li><a [routerLink]="[]" (click)="downloadAttachment(attachment);" translate>actions.telecharger</a></li>
									<li *ngIf="TypeAttachmentContext[options.typeAttachment]?.isMobile && attachment.typeOrigineSaisie == 'WEB' && (options.listeTypesDroit || null | right:'creation')">
										<a [routerLink]="[]" (click)="updateVisibility(attachment,idxAttachment)">
											<span *ngIf="!attachment.visibleMobile" translate>attachment.mobile.actions.afficher</span>
											<span *ngIf="attachment.visibleMobile" translate>attachment.mobile.actions.masquer</span>
										</a>
									</li>
								</ul>
							</div>
							<div class="lv-actions actions" *ngIf="options.isSelection">
								<div class="checkbox">
									<label><input type="checkbox" [(ngModel)]="attachment.isSelected"/><i class="input-helper"></i></label>
								</div>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
				</ng-container>

				<ng-container *ngIf="!options.idObjet">
					<div class="lv-item" *ngFor="let link of options.owningEntity?.listeLinks; let idxLink = index;">
						<div class="pull-left col-md-2 text-center">
							<thumb [file]="link.attachment" [height]="60"></thumb>
						</div>
						<div class="media-body">
							<div class="lv-title">
								<a href="controller/Blob/readBlob/{{ link.attachment.idBlob }}" target="_new" [tooltip]="link.attachment.name">{{ link.attachment.name }}</a>
							</div>
							<ul class="lv-attrs">
								<li><span translate>attachment.formulaire.date</span>&#160;:&#160;<span>{{ link.attachment.dateUpdate | date:'short' }}</span></li>
								<li><span translate>attachment.formulaire.taille</span>&#160;:&#160;<span>{{ link.attachment.size | bytes }}</span></li>
							</ul>

							<div class="lv-actions actions dropdown">
								<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
								<ul class="dropdown-menu dropdown-menu-right">
									<li *ngIf="!options.readOnly && ((options.listeTypesDroit || null | right:'creation') || options.typeAttachment == TypeAttachment.NON_DEFINI) && options.typeAttachment == link.attachment.type"><a [routerLink]="[]" (click)="removeLink(link,idxLink)" translate>actions.supprimer</a></li>
									<li *ngIf="(options.listeTypesDroit || null | right:'creation') && isFileImage(link.attachment.name)"><a [routerLink]="[]" (click)="editImage(link.attachment,null)" translate>actions.afficher</a></li>
									<li><a [routerLink]="[]" (click)="downloadAttachment(link.attachment);" translate>actions.telecharger</a></li>
								</ul>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
				</ng-container>

				<div class="lv-item uploading" *ngFor="let item of fileUploader.queue; let idxItem = index">
					<div class="pull-left col-md-2 text-center">
						<thumb [file]="item" [height]="60"></thumb>
					</div>
					<div class="media-body">
						<div class="lv-title" [ngClass]="{ 'infected': item.isUnsafe,'unsafe': item.isUnsafe === null }">
							<span>{{ item.name }}</span>
							<icon name="warning" class="p-l-5" *ngIf="item.isUnsafe || item.isUnsafe === null" [title]="getBlobAlertTitle(item.isUnsafe,item.name)"></icon>
						</div>
						<ul class="lv-attrs">
							<li>
								<div *ngIf="!item.isFinished" class="progress">
									<div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': (item.progress$ ? (item.progress$ | async) : 0) + '%' }"></div>
								</div>
							</li>
						</ul>

						<div class="lv-actions actions dropdown">
							<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
							<ul class="dropdown-menu dropdown-menu-right">
								<li *ngIf="(options.listeTypesDroit || null | right:'creation') || options.typeAttachment == TypeAttachment.NON_DEFINI"><a [routerLink]="[]" (click)="fileUploader.removeItem(idxItem)" translate>actions.supprimer</a></li>
								<li *ngIf="(options.listeTypesDroit || null | right:'creation') && isFileImage(item.name)"><a [routerLink]="[]" translate>actions.modifier</a></li>
								<li><a [routerLink]="[]" (click)="uploadItem(idxItem);" translate>actions.enregistrer</a></li>
							</ul>
						</div>
					</div>
					<div class="clearfix"></div>
				</div>
			</div>
		</div>
		<div *ngIf="options.imageSwiper">
			<ng-container>
				<div class="attachment-inline">
					<div class="switcher">
						<div class="navigation left" *ngIf="options.navigation.currentIndex > 0" (click)="options.navigation.currentIndex = options.navigation.currentIndex - 1">
							<icon name="chevron_left" class="icon-hc-5x"></icon>
						</div>
						<div class="navigation right" *ngIf="options.navigation.currentIndex < getListeLinksForType().length - 1" (click)="options.navigation.currentIndex = options.navigation.currentIndex + 1">
							<icon name="chevron_right" class="icon-hc-5x"></icon>
						</div>
						<div class="switcher-inner">
							<div class="attachment" [ngClass]="{ 'active': idxImg == options.navigation.currentIndex }" *ngFor="let item of getListeLinksForType(); let idxImg = index">
								<div class="content" *ngIf="item.attachment && idxImg == options.navigation.currentIndex" [style.background-image]="'url(controller/Blob/readBlob/' + item.attachment.idBlob + ')'"></div>
								<div class="content" *ngIf="!item.attachment && idxImg == options.navigation.currentIndex">
									<thumb [file]="item" [height]="300"></thumb>
								</div>
								<div *ngIf="idxImg == options.navigation.currentIndex && item._file && !item.isFinished" class="progress">
									<div class="progress-bar" [ngStyle]="{ 'width': (item.progress ? item.progress : 0) + '%' }"></div>
								</div>
							</div>
						</div>
						<div class ="btn-container">
							<div class="btn-group">
								<button class="btn" *ngIf="!options.readOnly && ((options.listeTypesDroit || null | right:'creation') || options.typeAttachment == TypeAttachment.NON_DEFINI)" (click)="deleteAttachment(getListeLinksForType()[options.navigation.currentIndex].attachment,options.navigation.currentIndex);" [tooltip]="'actions.supprimer' | translate"><icon name="delete" class="c-red icon-hc-2x"></icon></button>
								<button class="btn" *ngIf="!options.readOnly && (options.listeTypesDroit || null | right:'creation')" #fileSelection [tooltip]="'actions.ajouter' | translate"><icon class="icon-hc-2x" name="add"></icon></button>
								<button class="btn" (click)="editImage(getListeLinksForType()[options.navigation.currentIndex].attachment,null);" [tooltip]="'actions.afficher' | translate"><icon class="c-green icon-hc-2x" name="search"></icon></button>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer" *ngIf="!options.inline">
		<button mat-button (click)="close()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="!options.readOnly && (options.listeTypesDroit || null | right:'creation')" [disabled]="!fileUploader.queue?.length" (click)="uploadAll()">
			<span translate>actions.enregistrer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="options.isSelection" (click)="selectAttachments();" [disabled]="!hasSelectedAttachments()">
			<span translate>actions.selectionner</span>
		</button>
	</div>
</div>