import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ArticleListItemComponent } from './article-list-item.component';
import { ArticleService } from './article.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ChartService } from '../chart/chart.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './article-list.component.html'
})
export class ArticleListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ArticleListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private articleService: ArticleService,private rightService: RightService,private layoutService: LayoutService
			,public dashboardService: DashboardService,private chartService: ChartService,public connecteurService: ConnecteurService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ArticleListItemComponent>({
			uri: `/controller/Aide/filtreArticles`,
			title: this.translateService.instant('article.liste.title'),
			component: ArticleListItemComponent,
			listeFilters: [{
				clef: 'titre',
				title: this.translateService.instant('article.titre'),
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: '*listeContents.titre',
				title: this.translateService.instant('article.rubrique.item'),
				isDefault: true
			},{
				clef: '*listeContents.content',
				title: this.translateService.instant('article.contenu')
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN,
				title: this.translateService.instant('article.actif.item')
			},{
				clef: 'typeArticle',
				type: TypeFilter.LONG,
				title: this.translateService.instant('article.type.item'),
				listeValues: this.articleService.getListeTypes()
			},{
				clef: 'typePerimetre',
				type: TypeFilter.LONG,
				title: this.translateService.instant('article.perimetre.item'),
				listeValues: this.articleService.getListePerimetres()
			},this.rightService.isRoot() && {
				clef: 'typeDiffusion',
				type: TypeFilter.STRING,
				listeValues: this.chartService.getListeTypesDiffusion()
			}].filter(i => !!i),
			defaultOrder: 'titre,reference',
			getKeyFieldName: () => 'idArticle',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Aide/loadArticle/0'))
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}