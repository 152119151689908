import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject,filter,first,map } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';
import { ImportRelanceComponent } from './import-relance.component';

@Injectable()
export class ImportService {
	/** Liste des types d'import **/
	private readonly listeTypesImport: Array<string> = ['FULL','DELTA'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private confirmService: ConfirmService,private messagingService: MessagingService,private attachmentService: AttachmentService,private bsModalService: BsModalService) {}

	/**
	 * Suppression de l'import
	 */
	public deleteImport(connecteurExecution: any): Observable<Result> {
		//Suppression de l'import
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Connecteur/deleteImport/${connecteurExecution?.idExecution}`);
	}

	/**
	 * Dénombrement des imports pour un état
	 */
	public countImports(isSuccess: boolean,searchSpec: any): Observable<Result> {
		//Dénombrement des imports pour un état
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/countImports/${isSuccess}`,searchSpec);
	}

	/**
	 * Relance d'un import
	 */
	public retryImport(typeImport: 'DELTA' | 'FULL' = 'FULL',connecteurExecution: any): Observable<Result> {
		//Relance de l'import
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/retryImport/${typeImport}/${connecteurExecution?.idExecution}`,null);
	}

	/**
	 * Relance d'un import pour une sélection
	 */
	public retryImportForSelection(nbImportsOK: number,nbImportsKO: number,messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Vérification de la présence d'imports en succès
		if (nbImportsOK) {
			//Affichage d'un message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.relance.confirmation',{ nbOK: nbImportsOK,nbKO: nbImportsKO }),{ actionColor: 'primary' }).subscribe({
				next: isConfirmed => {
					//Vérification de la confirmation
					if (isConfirmed)
						//Lancement en masse
						this.doRetryImportForSelection(messagingOptions,subject);
				}
			});
		} else
			//Lancement en masse
			this.doRetryImportForSelection(messagingOptions,subject);

		return subject;
	}

	/**
	 * Déclenchement d'un import en masse
	 */
	private doRetryImportForSelection(messagingOptions: MessagingOptions,subject: Subject<any>) {
		let messaging$: MessagingObservables;

		//Démarrage de l'import par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});
	}

	/**
	 * Génération des journaux d'exécution
	 */
	public generateLogs(idExecution: number): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init({
			method: 'POST',
			entryPoint: `controller/Connecteur/generateLogs/${idExecution}`,
			progressConfig: {
				libelle: this.translateService.instant('connecteur.import.logs.title'),
				icone: 'download'
			}
		}).onFinish({
			next: ({ idSession }) => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();

				//Téléchargement des journaux
				this.downloadLogs(idExecution,idSession).pipe(first()).subscribe({
					next: response => {
						//Téléchargement du contenu
						this.attachmentService.downloadAttachment(response,null,'application/zip','logs.zip','_blank');
					}
				});
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		});

		return subject;
	}

	/**
	 * Téléchargement des journaux d'exécution
	 */
	private downloadLogs(idExecution: number,idSession: string) {
		//Téléchargement des journaux d'exécution
		return this.http.post(`${environment.baseUrl}/controller/Connecteur/downloadLogs/${idExecution}/${idSession}`,null,{
			responseType: 'arraybuffer'
		});
	}

	/**
	 * Ouverture de la popup du choix du mode de relance d'un import
	 */
	public showTypeImportForRelance(): Observable<'FULL' | 'DELTA'> {
		let bsModalRef: BsModalRef<ImportRelanceComponent>;

		//Affichage de la popup
		return (bsModalRef = this.bsModalService.show(ImportRelanceComponent,{
			initialState: {
				typeImport: 'FULL'
			}
		})).onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.typeImport),
			filter(typeImport => !!typeImport)
		);
	}

	/**
	 * Liste des types d'import
	 */
	public getListeTypesImport(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'import
		return this.listeTypesImport.map(code => ({
			code,
			libelle: this.translateService.instant(`connecteur.import.relance.typeImport.${code}`)
		}));
	}

	/**
	 * Suivi de l'exécution d'un import
	 */
	public retrieveProgress(connecteurExecution: any): { messaging$: MessagingObservables,subject: Observable<{ nbProcessed: number,nbTotal: number }> } {
		let messaging$: MessagingObservables;
		let subject: Subject<any> = new Subject<any>();

		//Suivi de l'exécution par websocket
		messaging$ = this.messagingService.init({
			method: 'POST',
			entryPoint: `controller/Connecteur/retrieveProgression/${connecteurExecution.idExecution}${connecteurExecution?.apiSession?.sessionId ? '/' + connecteurExecution.apiSession?.sessionId : ''}`,
		}).onResult({
			next: (result) => {
				//Transmission de la progression
				subject.next({
					nbProcessed: result.data.dataResult?.sessionIncrementOut || 0,
					nbTotal: result.data.dataResult?.sessionIncrementIn || 0,
					connecteurExecution: result.data.connecteurExecution
				});
			}
		}).onMessage({
			next: (message) => {
				//Transmission de la progression
				subject.next({
					nbProcessed: message.nbProcessed || 0,
					nbTotal: message.nbTotal || 0
				});
			}
		}).onFinish({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		});

		return { messaging$,subject };
	}
}