import moment,{ Duration } from 'moment';

/**
 * Enumération des types de vues possibles pour le planning
 */
export enum TypeView {
	/** Enumération **/
	ONE_DAY = 'ONE_DAY',
	ONE_WEEK = 'ONE_WEEK',
	TWO_WEEKS = 'TWO_WEEKS',
	ONE_MONTH = 'ONE_MONTH',
	THREE_MONTHS = 'THREE_MONTHS'
}

/**
 * Définition du paramétrage des différentes vues du planning
 */
export const typeViewSettings: { [typeView: string]: { name: string,duration: Duration,spanAsHours: number }} = {
	[TypeView.ONE_DAY]: {
		name: 'resourceTimelineOneDay',
		duration: moment.duration(1,'days'),
		spanAsHours: 0.5
	},
	[TypeView.ONE_WEEK]: {
		name: 'resourceTimelineOneWeek',
		duration: moment.duration(1,'weeks'),
		spanAsHours: 12
	},
	[TypeView.TWO_WEEKS]: {
		name: 'resourceTimelineTwoWeeks',
		duration: moment.duration(2,'weeks'),
		spanAsHours: 12
	},
	[TypeView.ONE_MONTH]: {
		name: 'resourceTimelineMonth',
		duration: moment.duration(1,'months'),
		spanAsHours: 24
	},
	[TypeView.THREE_MONTHS]: {
		name: 'resourceTimelineThreeMonths',
		duration: moment.duration(3,'months'),
		spanAsHours: 24
	}
}