import { Component,Input,OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map } from 'rxjs/operators';

import { DataCustomizationFieldListComponent } from './data-customization-field-list.component';
import { DataCustomizationService } from './data-customization.service';

@Component({
	selector: 'data-customization',
	templateUrl: './data-customization.component.html'
})
export class DataCustomizationComponent implements OnInit {
	/** Classe de l'entité **/
	@Input() entite: string;

	/** Objet **/
	@Input() object: any;

	/** Etat du composant **/
	@Input() disabled: boolean = false;

	/** Indicateur d'affichage dans un sous-élément **/
	@Input() isSubItem: boolean = false;

	/** Liste des champs disponibles **/
	listeFields: Array<any>;

	/** Liste regroupée (2 par 2) des champs disponibles **/
	subListeFields: Array<any>;

	/** Personnalisation des champs **/
	dataCustomization: any;

	/**
	 * Constructeur
	 */
	constructor(private dataCustomizationService: DataCustomizationService,private bsModalService: BsModalService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification des champs personnalisés de l'entité
		if (!this.object.dataCustomization)
			//Initialisation des champs personnalisés
			this.object.dataCustomization = {};

		//Chargement du paramétrage des champs personnalisés
		this.dataCustomizationService.loadDataCustomization(this.entite).pipe(
			first(),
			filter(dataCustomization => !!dataCustomization)
		).subscribe({
			next: dataCustomization => {
				//Conservation de la personnalisation
				this.dataCustomization = dataCustomization;

				//Définition de la liste des champs
				this.listeFields = this.dataCustomizationService.getListeFields(dataCustomization,false);

				//Mise à jour des traductions
				this.dataCustomizationService.updateListeFields(this.entite,this.listeFields);

				//Définition de la liste regroupée (2 par 2) des champs disponibles
				this.subListeFields = this.getSubListeFields(this.listeFields);
			}
		});
	}

	/**
	 * Récupération et regroupement des champs disponibles
	 */
	getSubListeFields(listeFields: Array<any>) {
		//Regroupement des champs
		this.subListeFields = listeFields.reduce((current,value) => {
			//Vérification de la taille de la sous-liste précédente
			if (current.length == 0 || current[current.length - 1]?.length == 2)
				//Définition de la sous-liste
				current.push([value]);
			else
				//Ajout de la valeur courate à la sous-liste
				current[current.length - 1].push(value)

			return current;
		},[]);

		//Retour de la liste des champs regroupés
		return this.subListeFields;
	}

	/**
	 * Edition des champs
	 */
	edit() {
		let bsModalRef: BsModalRef<DataCustomizationFieldListComponent>;

		//Affichage de la popup d'édition des champs
		bsModalRef = this.bsModalService.show(DataCustomizationFieldListComponent,{
			initialState: {
				dataCustomization: cloneDeep(this.dataCustomization),
				listeFields: cloneDeep(this.listeFields)
			},
			class: 'modal-max'
		});

		//Interception de la fermeture de la popup
		bsModalRef.onHidden.pipe(map(() => bsModalRef.content.savedDataCustomization)).subscribe({
			next: dataCustomization => {
				//Vérification de la présence d'une personnalisation mise à jour
				if (dataCustomization) {
					//Mise à jour de la personnalisation
					this.dataCustomization = dataCustomization;

					//Définition de la liste des champs
					this.listeFields = this.dataCustomizationService.getListeFields(dataCustomization,false);

					//Mise à jour des traductions
					this.dataCustomizationService.updateListeFields(this.entite,this.listeFields);

					//Définition de la liste regroupée (2 par 2) des champs disponibles
					this.subListeFields = this.getSubListeFields(this.listeFields);
				}
			}
		})
	}
}