<form #articleCRMForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!articleCRM.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>articleCRM.libelle</label>
							<div>
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="articleCRM.libelle" required maxlength="300"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>articleCRM.reference</label>
							<div>
								<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="articleCRM.reference" required maxlength="100"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeObjetField.invalid }">
							<label translate>articleCRM.typeObjet</label>
							<div>
								<selectpicker name="typeObjet" #typeObjetField="ngModel" [(ngModel)]="articleCRM.typeObjet" required>
									<mat-option *ngFor="let typeObjet of listeTypesObjet" [value]="typeObjet.code">{{ typeObjet.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group">
							<label translate>articleCRM.typeLicence</label>
							<div>
								<selectpicker name="typeLicence" #typeLicenceField="ngModel" [(ngModel)]="articleCRM.typeLicence" required>
									<mat-option *ngFor="let typeLicence of listeTypesLicence" [value]="typeLicence.code">{{ typeLicence.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': code.invalid }">
							<label translate>articleCRM.code</label>
							<div>
								<input type="text" class="form-control" name="code" #code="ngModel" [(ngModel)]="articleCRM.code" maxlength="100" required/>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label translate>articleCRM.actif.item</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="articleCRM.actif"><span translate>articleCRM.actif.description</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
				<basket [model]="articleCRM" propertyName="listeRevendeurs" colWidthLabel="2" colWidthAutocomplete="6" type="revendeur" [labelAutocomplete]="'articleCRM.revendeur.ajout' | translate" [labelListe]="'articleCRM.revendeur.liste' | translate" [hasError]="hasErrorListeRevendeurs"></basket>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: articleCRMForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteArticleCRM();" *ngIf="articleCRM.idArticle && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveArticleCRM();" *ngIf="null | right:'creation'" [disabled]="articleCRMForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>