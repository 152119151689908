import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter,switchMap,take } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FormatInterfaceService } from '../fournisseur/format-interface/format-interface.service';
import { ConnecteurListItemComponent } from './connecteur-list-item.component';

@Component({
	templateUrl: './connecteur-list.component.html'
})
export class ConnecteurListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ConnecteurListItemComponent>;

	/** Utilisateur d'origine **/
	userOrigine$: Observable<User>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public rightService: RightService,private layoutService: LayoutService,private store: Store<AppState>,private confirmService: ConfirmService,private connecteurService: ConnecteurService,private toastrService: ToastrService,private formatInterfaceService: FormatInterfaceService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ConnecteurListItemComponent>({
			uri: '/controller/Connecteur/filtreConnecteurs',
			title: this.translateService.instant('connecteur.liste.title'),
			component: ConnecteurListItemComponent,
			listeFilters: [{
				clef: 'type',
				title: this.translateService.instant('search.connecteur'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'connecteurType'
				},
				isDefault: true
			},{
				clef: 'type.fournisseurInterface.typeFlux',
				type: TypeFilter.LONG,
				title: this.translateService.instant('fournisseur.formatInterface.typeFlux.item'),
				listeValues: this.formatInterfaceService.getListeTypesFlux()
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			defaultOrder: 'type.libelle',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Connecteur/loadConnecteur/0'))
		});

		//Sélection de l'utilisateur connecté
		this.userOrigine$ = this.store.select<User>(state => state.session.userOrigine).pipe(take(1));
	}

	/**
	 * Synchronisation des connecteurs
	 */
	syncConnecteurs() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.synchronisation.confirmation'),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.connecteurService.syncConnecteurs())
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.synchronisation.success'));
			else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.synchronisation.error'));
		});
	}
}