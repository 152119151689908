import { NgModule } from '@angular/core';

import { AttachmentModule } from 'src/app/share/components/attachment/attachment.module';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';
import { StepperModule } from 'src/app/share/components/stepper/stepper.module';
import { ThumbModule } from 'src/app/share/components/thumb/thumb.module';
import { DatePickerModule } from 'src/app/share/directive/date-picker/date-picker.module';
import { ImportAttachmentsComponent } from './import-attachments.component';
import { ImportContentComponent } from './import-content.component';
import { ImportFormatFournisseurListItemComponent } from './import-format-fournisseur-list-item.component';
import { ImportFormatOperationListItemComponent } from './import-format-operation-list-item.component';
import { ImportFormatComponent } from './import-format.component';
import { ImportModuleListItemComponent } from './import-module-list-item.component';
import { ImportModuleListComponent } from './import-module-list.component';
import { ImportResumeExecutionComponent } from './import-resume-execution.component';
import { ImportResumeComponent } from './import-resume.component';
import { ImportSelectionComponent } from './import-selection.component';
import { ImportSpecificationConnecteurListItemComponent } from './import-specification-connecteur-list-item.component';
import { ImportSpecificationTemplateListItemComponent } from './import-specification-template-list-item.component';
import { ImportSpecificationComponent } from './import-specification.component';

@NgModule({
	imports: [CoreComponentsModule,ListViewModule,StepperModule,ThumbModule,AttachmentModule,IconModule,PleaseWaitModule,DatePickerModule],
	declarations: [ImportSelectionComponent,ImportFormatComponent,ImportFormatFournisseurListItemComponent,ImportFormatOperationListItemComponent,ImportSpecificationComponent,ImportSpecificationConnecteurListItemComponent,ImportAttachmentsComponent,ImportResumeComponent,ImportModuleListComponent,ImportModuleListItemComponent,ImportSpecificationTemplateListItemComponent,ImportResumeExecutionComponent,ImportContentComponent],
	exports: [ImportContentComponent]
})
export class ImportModule {}