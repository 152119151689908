<div class="thumbnail clickable d-relative" (click)="onItemSelected.emit({ fournisseur: data });">
	<div class="selector" *ngIf="selectedFournisseur">
		<div class="btn-group">
			<button class="btn" (click)="onItemSelected.emit(null)" [tooltip]="'actions.supprimer' | translate"><icon name="close" class="c-red"></icon></button>
		</div>
	</div>
	<div class="content text-center">
		<thumb [file]="data.attachment" [height]="100" [fallback]="data.reference.substring(0,3)"></thumb>
	</div>
	<div class="caption text-center"></div>
	<div class="title text-center">
		<a [routerLink]="[]">{{ data.libelle }}</a>
	</div>
</div>