<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idConnecteurType">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data?.libelle + (data?.reference ? ' (' + data?.reference + ')' : '') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idConnecteurType">
		<li><span translate>connecteur.connecteurType.typeConnecteur</span>&#160;:&#160;<span [translate]="'connecteur.type.'+data.typeConnecteur"></span></li>
		<li><span translate>connecteur.connecteurType.typeExecution</span>&#160;:&#160;<span [translate]="'connecteur.typeExecution.'+data.typeExecution"></span></li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	</ul>
</div>
<div class="tile-detail-container" *ngIf="data?.isDisplayed">
	<connecteur-type [connecteurType]="data" (onObjectChange)="onObjectChange($event)"></connecteur-type>
</div>