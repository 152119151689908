import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';
import { PlanComptableListItemComponent } from './plan-comptable-list-item.component';

@Component({
	templateUrl: './plan-comptable-list.component.html'
})
export class PlanComptableListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,PlanComptableListItemComponent>;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,public connecteurService: ConnecteurService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PlanComptableListItemComponent>({
			uri: `/controller/Compte/filtrePlanComptables`,
			title: this.translateService.instant('comptabilite.planComptable.liste.title'),
			component: PlanComptableListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'pays',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'pays',
					fieldForDefault: 'libelle,code2'
				},
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'listeSocietes',
				title: this.translateService.instant('search.societe'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'societe'
				}
			}],
			defaultOrder: 'libelle,reference',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Comptabilite/loadPlanComptable/0'))
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}