import { Component,OnInit,ElementRef,Renderer2,AfterViewInit,HostListener,LOCALE_ID,Inject,HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import moment from 'moment';
import * as momentTZ from 'moment-timezone';

import { AppState } from './domain/appstate';
import { Session } from './domain/security/session';
import { LayoutService } from './share/layout/layout.service';
import { MenuService } from './share/layout/menu/menu.service';
import { ExtendedRouteData } from './domain/common/route/extended-route';
import { ChatbotService } from './share/layout/chatbot/chatbot.service';
import { LoginService } from './share/login/login.service';

@Component({
	selector: 'body',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit,AfterViewInit {
	/** Données **/
	session: Session = null;

	/** Indicateur d'ouverture du menu **/
	isMenuOpened: boolean = false;

	/** Ajout de la classe 'login-content' si l'utilisateur n'est pas connecté **/
	@HostBinding('class.login-content') get isLoginContentClass() { return !this.session?.isLogged; }

	/** Ajout de la classe 'menu-opened' si le menu est ouvert **/
	@HostBinding('class.menu-opened') get isMenuOpenedClass() { return this.isMenuOpened; }

	/** Observateur de la présence d'un titre **/
	private isTitled$: Observable<[string,{ state: string,data: ExtendedRouteData }]>;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private translateService: TranslateService,private layoutService: LayoutService,private elementRef: ElementRef,private renderer: Renderer2,public menuService: MenuService,public chatbotService: ChatbotService,@Inject(LOCALE_ID) private locale: string,private loginService: LoginService) {
		//Définition de la langue par défaut
		translateService.setDefaultLang('fr');

		//Définition de la langue
		translateService.use(locale.split('-')[0]);

		//Définition du mode de compatibilité pour Bootstrap v3
		setTheme('bs3');

		//Définition de la locale
		moment.locale(locale);

		//Définition de la locale de la TimeZone
		momentTZ.defineLocale(locale,(moment.localeData() as any)._config);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => this.session = session);

		//Initialisation du layout
		this.layoutService.init();

		//Définition de l'observateur de changements du titre ou de la route
		this.isTitled$ = combineLatest([
			this.store.select(s => s.layout?.title),
			this.layoutService.routeChangeSubject.pipe(startWith({ state: null } as { state: string,data: ExtendedRouteData }))
		]);

		//Interception de l'ouverture/fermeture du menu
		this.menuService.isOpened$.subscribe(isOpened => {
			//Mise à jour de l'indicateur local
			this.isMenuOpened = isOpened;
		});
	}

	/**
	 * Détection de l'affichage de la vue
	 */
	ngAfterViewInit() {
		//Écoute des changements du titre
		this.isTitled$.subscribe(([title,data]) => {
			//Vérification du state de la route activée
			if (data.state === null || data.state && data.state != 'ng-blank') {
				//Retrait de la classe 'titled' éventuellement présente
				this.renderer.removeClass(this.elementRef.nativeElement,'titled');

				//Vérification de la présence d'un titre
				if (!!title || data.data?.title)
					//Ajout de la classe 'titled'
					this.renderer.addClass(this.elementRef.nativeElement,'titled');
			}
		});
	}

	/**
	 * Gestion du dragover
	 */
	@HostListener('dragover',['$event'])
	onBodyDragOver(event: DragEvent) {
		//Annulation de l'événement
		event.preventDefault();

		//Arrêt de la propagation
		event.stopPropagation();

		//Vérification des données
		if (event.dataTransfer) {
			//Mise à jour de l'évènement
			event.dataTransfer.effectAllowed = 'none';
			event.dataTransfer.dropEffect = 'none';
		}
	}

	/**
	 * Gestion du drop
	 */
	@HostListener('drop',['$event'])
	onBodyDrop(event: DragEvent) {
		//Annulation de l'événement
		event.preventDefault();

		//Mise à jour de l'évènement
		event.dataTransfer.effectAllowed = 'none';
		event.dataTransfer.dropEffect = 'none';
	}

	/**
	 * Gestion de la déconnexion inter-onglets
	 */
	@HostListener('window:storage',['$event'])
	onStorage(event: StorageEvent) {
		//Vérification qu'une déconnexion a été demandée depuis un autre onglet
		if (event.key === 'logout' && event.newValue === 'true')
			//Déconnexion de l'utilisateur dans l'onglet courant
			this.loginService.logout({ isFromCrossTab: true });
	}

	/**
	 * Détection du changement de route
	 */
	onActivate(event) {
		//Définition du composant instancié dans la route
		this.layoutService.setRouteComponentInstance(event);
	}
}
