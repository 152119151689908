import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import moment from 'moment';
import { finalize,map,of } from 'rxjs';

import { TypeDroit } from 'src/app/domain/security/right';
import { TypeAnomalie } from 'src/app/domain/facture/detail';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { NatureService } from 'src/app/components/fournisseur/nature/nature.service';
import { EntretienService } from 'src/app/components/vehicule/entretien/entretien.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { FactureService } from '../facture.service';
import { TaxeService } from '../taxe/taxe.service';
import { ControleReglementaireService } from 'src/app/components/vehicule/controle-reglementaire/controle-reglementaire.service';

@Component({
	selector: 'facture-detail-content',
	templateUrl: './detail-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class DetailContentComponent implements OnInit {
	/** Ligne de facture **/
	@Input() detail: any;

	/** Affichage en lecture seule **/
	@Input() isConsultation: boolean = false;

	/** Source **/
	@Input() source: 'VEHICULE' | 'EQUIPEMENT' | null = null;

	/** Indicateur de duplication **/
	@Input() isDuplication: boolean = false;

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Facture courante **/
	public facture: any;

	/** Indicateur d'invalidité de la taxe **/
	public isTaxeInvalid: boolean = false;

	/** Liste des types de réconciliation **/
	public listeTypesReconciliation: Array<{ code: string,libelle: string }>;

	/** Liste des type d'entretien **/
	public listeTypesEntretien: Array<{ code: string,libelle: string }>;

	/** Liste des sous-types de contrôles réglementaires **/
	public listeSousTypes: Array<{ code: string,libelle: string }>;

	/** Liste des types de lien **/
	public listeTypesLien: Array<{ code: string,libelle: string,type: string }>;

	/** Champs personnalisés **/
	public extraFields: { [key: string]: boolean } = {};

	/** Ancienne réconciliation **/
	public oldTypeReconciliation: any;

	/** Réconciliation **/
	public reconciliation: { isVisible: boolean,selectedTypeReconciliation: 'VEHICULE' | 'COLLABORATEUR' | 'EQUIPEMENT' | 'SOCIETE' } = {
		isVisible: true,
		selectedTypeReconciliation: null
	};

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Date courante **/
	public dateCourante: Date;

	/** Référentiel de taxe du pays à la date de la ligne de facture **/
	public taxeForPays: any = null;

	/**
	 * Constructeur
	 */
	constructor(private factureService: FactureService,private entretienService: EntretienService,private natureService: NatureService,private rightService: RightService,private layoutService: LayoutService,private vehiculeService: VehiculeService,private taxeService: TaxeService,private controleReglementaireService: ControleReglementaireService) {
		//Binding des méthodes
		this.saveDetail = this.saveDetail.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Définition de la liste des types de réconciliation
		this.listeTypesReconciliation = this.factureService.getListeTypesReconciliation();

		//Définition de la liste des type de lien
		this.listeTypesLien = this.natureService.getListeTypesLien();

		//Définition de la liste des types d'entretien
		this.listeTypesEntretien = this.entretienService.getListeTypesEntretien();

		//Définition de la liste des sous-types de contrôles réglementaires
		this.listeSousTypes = this.controleReglementaireService.getListeTypes();

		//Récupération des types de réconciliation
		this.natureService.getListeTypesLien();

		//Définition de la facture
		this.facture = this.detail.facture;

		//Date courante
		this.dateCourante = moment().endOf('day').toDate();

		//Vérification de la présence d'un entretien ou d'un compteur
		if (this.detail?.lien?.entretien) {
			//Récupération du type d'entretien
			this.detail.typeEntretien = this.detail.lien.entretien.type;
			this.detail.sousType = this.detail.lien.entretien.sousType;
			this.detail.typeEntretienItem = this.detail.lien.entretien.typeEntretienItem;

			//Récupération du relevé de compteur depuis l'entretien
			this.detail.releveCompteur = this.detail.lien.entretien.compteur;
			this.detail.releveHoraire = this.detail.lien.entretien.compteurHoraire;
		}

		//Vérification du compteur
		if (this.detail?.lien?.vehiculeCompteur) {
			//Récupération du relevé de compteur
			this.detail.releveCompteur = this.detail.lien.vehiculeCompteur.releveCompteur;
			this.detail.releveHoraire = this.detail.lien.vehiculeCompteur.releveHoraire;
		}

		//Vérification de la présence de taxes
		if (this.detail?.listeTaxes)
			//Vérification de la taxe
			this.isTaxeInvalid = !this.taxeService.verifyListeTaxes(this.detail.montant,this.detail.listeTaxes);

		//Définition du type de réconciliation
		this.retrieveTypeReconciliation();

		//Vérification du pays
		if (this.detail?.pays)
			//Définition du référentiel de taxe du pays à la date de la ligne de facture
			this.taxeService.findTaxeForPays(this.detail.pays.idPays,this.detail.date).subscribe(taxe => this.taxeForPays = taxe);
	}

	/**
	 * Définition du type de réconciliation
	 */
	private retrieveTypeReconciliation() {
		let anomalieReconciliation: any;

		//Vérification du mode de saisie de la ligne de facture
		if (this.detail.importe) {
			//Récupération d'une potentielle anomalie de réconciliation
			anomalieReconciliation = this.getAnomalieReconciliation();

			//Vérification de la réconciliation sur le véhicule
			if (this.detail.lien?.objet?.idVehicule
					|| anomalieReconciliation?.typeAnomalie == 'VEHICULE'
					|| this.detail.editable && this.detail.lien?._type == 'com.notilus.data.facture.link.LinkDetailVehicule') {
				//Définition de la réconciliation
				this.reconciliation = {
					selectedTypeReconciliation: 'VEHICULE',
					isVisible: this.detail.editable
				};
			} else if (this.detail.lien?.objet?.idUser
					|| anomalieReconciliation?.typeAnomalie == 'COLLABORATEUR'
					|| this.detail.editable && this.detail.lien?._type == 'com.notilus.data.facture.link.LinkDetailUser') {
				//Définition de la réconciliation
				this.reconciliation = {
					selectedTypeReconciliation: 'COLLABORATEUR',
					isVisible: this.detail.editable
				};
			} else if (this.detail.lien?.objet?.idEquipement
					|| anomalieReconciliation?.typeAnomalie == 'EQUIPEMENT' || anomalieReconciliation?.typeAnomalie == 'EQUIPEMENT_NUMERO'
					|| this.detail.editable && this.detail.lien?._type == 'com.notilus.data.facture.link.LinkDetailEquipement') {
				//Définition de la réconciliation
				this.reconciliation = {
					selectedTypeReconciliation: 'EQUIPEMENT',
					isVisible: this.detail.editable
				};
			} else if (this.detail.lien?.objet?.idService
					|| anomalieReconciliation?.typeAnomalie == 'SOCIETE'
					|| this.detail.editable && this.detail.lien?._type == 'com.notilus.data.facture.link.LinkDetailService'
					|| this.facture.societe
					|| !this.detail.lien && !this.detail.anomalie && this.detail.idDetail) {
				//Définition de la réconciliation
				this.reconciliation = {
					selectedTypeReconciliation: 'SOCIETE',
					isVisible: this.detail.editable
				};
			}

			//Vérification du lien
			if (this.detail.lien?.objet == null) {
				//Création du lien
				this.detail.lien = {
					_type: this.listeTypesLien?.find(typeLien => typeLien.code == this.reconciliation?.selectedTypeReconciliation)?.type,
					typeDetail: this.listeTypesLien?.find(typeLien => typeLien.type == this.detail.lien?._type)?.code
				};
			}
		} else {
			//Définition du type de réconciliation en fonction de la nature
			this.reconciliation = {
				selectedTypeReconciliation: this.detail.lien?.objet && (this.detail.lien.objet.idVehicule && 'VEHICULE' || this.detail.lien.objet.idUser && 'COLLABORATEUR' || this.detail.lien.objet.idEquipement && 'EQUIPEMENT' || this.detail.lien.objet.idService && 'SOCIETE') || this.detail.nature?.typeReconciliation || null,
				isVisible: true
			};
		}

		//Mise à jour des champs supplémentaires
		this.detail.nature?.listeFields.forEach(field => Object.assign(this.extraFields,{ [field.typeField]: field.actif }));
	}

	/**
	 * Récupération d'une anomalie de réconciliation
	 */
	public getAnomalieReconciliation(detail: { [key: string]: any,listeAnomalies: Array<any> } = this.detail): { typeAnomalie: 'EQUIPEMENT_NUMERO' | 'EQUIPEMENT' | 'VEHICULE' | 'COLLABORATEUR' | 'SOCIETE' | null } {
		//Récupération d'une anomalie de réconciliation
		return this.factureService.getAnomalieReconciliation(detail);
	}

	/**
	 * Vérification de la présence d'un type d'anomalie
	 */
	public hasTypeAnomalie(type: TypeAnomalie | Array<TypeAnomalie>,detail: { [key: string]: any,listeAnomalies: Array<any> } = this.detail,isReversed: boolean = false): boolean {
		let listeTypes: Array<TypeAnomalie>;

		//Définition de la liste des types d'anomalie à tester
		listeTypes = Array.isArray(type) ? type : [type];

		//Vérification de la présence d'un type d'anomalie parmi / à l'exclusion de ceux fournis
		return detail?.listeAnomalies?.some(anomalie => isReversed != listeTypes.includes(anomalie.typeAnomalie)) || false;
	}

	/**
	 * Récupération d'une anomalie d'un type donné
	 */
	public getAnomalieForType(typeAnomalie: TypeAnomalie): { typeAnomalie: TypeAnomalie,reference: string } {
		//Récupération de l'anomalie du type demandé
		return this.detail.listeAnomalies?.find(anomalie => anomalie.typeAnomalie == typeAnomalie) || null;
	}

	/**
	 * Interception d'un changement de montant ou de quantité
	 */
	public onMontantQuantiteChange(detail: any = this.detail) {
		//Récupération du montant hors taxe
		detail.montantHorsTaxe = detail.montant - (detail.montantTaxe || 0);

		//Recalcul du montant unitaire
		detail.montantUnitaire = detail.montant && detail.quantite ? detail.montant / detail.quantite : null;

		//Vérification de la présence de taxes
		if (this.detail.listeTaxes)
			//Vérification de la taxe
			this.isTaxeInvalid = !this.taxeService.verifyListeTaxes(this.detail.montant,this.detail.listeTaxes);
	}

	/**
	 * Interception d'un changement de pays ou de date
	 */
	public onPaysDateChange(detail: any = this.detail) {
		let isResetTaxe: boolean = true;

		//Recherche du nouveau référentiel de taxe applicable
		(detail.pays ? this.taxeService.findTaxeForPays(this.detail.pays.idPays,this.detail.date) : of(null)).pipe(map(taxeForPays => {
			//Mise à jour du référentiel de taxe applicable
			this.taxeForPays = taxeForPays;

			//Vérification de l'incompatibilité de la taxe saisie avec le nouveau référentiel
			isResetTaxe = !this.detail.importe && (!taxeForPays?.listeTaux || this.detail.listeTaxes?.some(detailTaxe => !taxeForPays.listeTaux.map(t => t.taux).includes(detailTaxe.taux)));
		}),finalize(() => {
			//Vérification que la taxe saisie est incompatible avec le nouveau référentiel
			if (!detail.importe && isResetTaxe) {
				//Suppression des taxes
				detail.listeTaxes = null;

				//Mise à jour du montant hors taxe
				detail.montantHorsTaxe = detail.montant;

				//Suppression des montants de taxe
				detail.montantTaxe = null;
				detail.montantTaxeCalculee = null;
			}
		})).subscribe();
	}

	/**
	 * Interception d'un changement de type de réconciliation
	 */
	public onTypeReconciliationChange(detail = this.detail) {
		//Vérification d'un changement de réconciliation
		if (this.oldTypeReconciliation != this.reconciliation.selectedTypeReconciliation) {
			//Création du (lien si nécessaire)
			detail.lien = detail.lien || {};

			//Mise à jour du lien
			detail.lien._type = this.listeTypesLien?.find(typeLien => typeLien.code == this.reconciliation?.selectedTypeReconciliation)?.type;
			detail.lien.typeDetail = this.listeTypesLien?.find(typeLien => typeLien.type == detail.lien?._type)?.code;

			//Vérification du type de réconciliation
			if (this.reconciliation.selectedTypeReconciliation == 'VEHICULE') {
				//Suppression de l'utilisateur
				detail.user = null;
			} else if (this.reconciliation.selectedTypeReconciliation == 'COLLABORATEUR') {
				//Suppression du véhicule
				detail.vehicule = null;
			} else if (this.reconciliation.selectedTypeReconciliation == 'SOCIETE') {
				//Définition de la société
				detail.lien.objet = this.facture.societe;
				detail.lien.typeDetail = 'SERVICE';
			}

			//Vérification du type de réconciliation du véhicule
			if (this.reconciliation.selectedTypeReconciliation != 'VEHICULE') {
				//Remise à la valeur par défaut
				this.detail.typeEntretien = undefined;
				this.detail.typeEntretienItem = undefined;
			}

			//Définition de la réconciliation
			this.oldTypeReconciliation = this.reconciliation.selectedTypeReconciliation;
		}
	}

	/**
	 * Interception d'un changement de véhicule
	 */
	public onVehiculeChange(detail: any = this.detail,vehicule: any = this.detail?.lien?.objet) {
		//Vérification du type de véhicule
		if (vehicule?.typeVehicule == 'ENGIN') {
			//Suppression d'un type d'entretien sélectionné
			detail.typeEntretien = undefined;

			//Définition de la liste des types d'entretien
			this.listeTypesEntretien = this.entretienService.getListeTypesEntretien().filter(type => type.code == 'COURANT');
		} else
			//Définition de la liste des types d'entretien
			this.listeTypesEntretien = this.entretienService.getListeTypesEntretien();
	}

	/**
	 * Interception d'un changement de nature
	 */
	public onNatureChange(detail: any = this.detail) {
		//Vérification de l'existance d'un lien
		if (detail.lien == null)
			//Création du lien
			detail.lien = {};

		//Mise à jour des champs supplémentaires
		this.detail.nature?.listeFields.forEach(field => Object.assign(this.extraFields,{ [field.typeField]: field.actif }));

		//Vérification de la présence d'un relevé d'énergie
		if (this.extraFields.RELEVE_ENERGIE) {
			//Suppression de la quantité
			detail.quantite = null;

			//Définition de l'unité
			detail.unite = this.detail.nature?.listeFields.find(field => field.typeField == 'RELEVE_ENERGIE')?.unite;
		} else
			//Suppression de l'unité
			detail.unite = null;

		//Vérification de la présence d'une réalisation d'entretien
		if (this.extraFields.REALISATION_CONTROLE_TECHNIQUE) {
			//Pré-selection du type d'entretien "Contrôle technique"
			detail.typeEntretien = 'CONTROLE_REGLEMENTAIRE';
			detail.sousType = 'TECHNIQUE';
		} else if (this.extraFields.REALISATION_CONTROLE_ANTIPOLLUTION) {
			//Pré-selection du type d'entretien "Contrôle anti-pollution"
			detail.typeEntretien = 'CONTROLE_REGLEMENTAIRE';
			detail.sousType = 'ANTIPOLLUTION';
		} else if (this.extraFields.ENTRETIEN) {
			//Retrait du type d'entretien par défaut
			detail.typeEntretien = undefined;
			detail.typeEntretienItem = undefined;
		}

		//Vérification du type de réconciliation
		if (detail?.nature?.typeReconciliation)
			//Définition du type de réconciliation
			this.reconciliation.selectedTypeReconciliation = detail?.nature?.typeReconciliation;

		//Mise à jour des réconciliations
		this.onTypeReconciliationChange();
	}

	/**
	 * Redirection vers l'objet
	 */
	public goToObject(lien: any = this.detail.lien) {
		//Vérification de la redirection
		switch (lien?.typeDetail) {
		case 'VEHICULE':
			//Vérification des droits
			if (this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && !(this.source == 'VEHICULE' && !this.isConsultation)) {
				//Redirection vers le véhicule
				this.layoutService.goToByState('listeVehicules-loadVehicule',{
					routeParams: {
						idVehicule: lien?.objet?.idVehicule
					},
					withGoBack: true
				});
			}
			break;
		case 'COLLABORATEUR':
			//Vérification des droits
			if (this.rightService.hasRight(TypeDroit.ADMIN_UTILISATEUR,'consultation') && !this.isConsultation) {
				//Redirection vers le collaborateur
				this.layoutService.goToByState('listeUsers-user',{
					routeParams: {
						idUser: lien?.objet?.idUser
					},
					withGoBack: true
				});
			}
			break;
		case 'EQUIPEMENT':
			//Vérification des droits
			if (this.rightService.hasRight(TypeDroit.EQUIPEMENT,'consultation') && !(this.source == 'EQUIPEMENT' && !this.isConsultation)) {
				//Redirection vers l'équipement
				this.layoutService.goToByState('materielReferentiel-listeEquipements-loadEquipement',{
					routeParams: {
						idEquipement: lien?.objet?.idEquipement
					},
					withGoBack: true
				});
			}
			break;
		default:
			//Ne rien faire
			break;
		}
	}

	/**
	 * Affichage de l'historique de modification de l'immatriculation
	 */
	public showListeImmatriculations(vehicule = this.detail.lien.objet): void {
		//Affichage de l'historique de modification de l'immatriculation
		this.vehiculeService.showListeImmatriculations(vehicule.idVehicule);
	}

	/**
	 * Affichage de la pop-up de taxe
	 */
	public showTaxe(detail: any = this.detail) {
		//Affichage de la pop-up de taxe
		this.taxeService.showTaxes({ ...detail },{
			idPays: detail.pays.idPays,
			date: detail.date,
			disabled: !this.rightService.hasRight(null,'creation') || ['COMPTABILISEE','VALIDEE'].includes(this.facture?.statut) || this.isConsultation
		}).subscribe({
			next: (listeTaxes: Array<any>) => {
				//Récupération de la liste des taxes
				detail.listeTaxes = listeTaxes;

				//Récupération du montant de taxe
				detail.montantTaxe = this.taxeService.computeTotalTaxe(listeTaxes);

				//Définition du montant de taxe calculée
				detail.montantTaxeCalculee = detail.montantTaxe;

				//Récupération du montant hors taxe
				detail.montantHorsTaxe = detail.montant - detail.montantTaxe;

				//Vérification de la présence de taxes
				if (detail.listeTaxes)
					//Vérification de l'invalidité de la taxe
					this.isTaxeInvalid = !this.taxeService.verifyListeTaxes(detail.montant,detail.listeTaxes);
			}
		});
	}

	/**
	 * Affichage de la liste des compteurs du véhicule
	 */
	public showListeCompteurs() {
		//Affichage de la liste des compteurs du véhicule
		this.vehiculeService.showListeCompteurs(this.detail.lien.objet)
	}

	/**
	 * Sauvegarde de la ligne de détail
	 */
	public saveDetail(detail = this.detail) {
		let data: any;

		//Vérification du type de réconciliation - Suppression de l'unité si nécessaire
		if (!detail.importe && !this.extraFields.RELEVE_ENERGIE)
			//Suppression de l'unité
			detail.unite = null;

		//Enregistrement de la ligne de facture
		this.factureService.doSaveDetail(detail).subscribe({
			next: (result: { detail: any,facture: any,isCorrectionAnomalie: boolean,nbDetailsAnomalie: number }) => {
				//Vérification de la présence d'une ligne de détail
				if (result.detail)
					//Mise à jour de la ligne de détail
					Object.assign(detail,result.detail);

				//Vérification de la présence d'une facture
				if (result.facture)
					//Mise à jour de la facture
					Object.assign(this.facture,result.facture);

				//Définition des valeurs
				data = { ...result,detail,facture: this.facture };

				//Mise en cycle
				setTimeout(() => {
					//Récupération du type de réconciliation
					this.retrieveTypeReconciliation()
				});
			},
			complete: () => {
				//Fermeture du formulaire
				this.close?.emit?.(data);
			}
		});
	}

	/**
	 * Sélection de l'imputation
	 */
	public showSelectionImputation(detail: any = this.detail) {
		//Sélection de l'imputation
		this.vehiculeService.showVehiculeImputationSelection(detail.lien?.objet).subscribe({
			next: (vehicule) => {
				//Récupération du véhicule
				detail.vehicule = vehicule;

				//Définition du mode forcé
				detail.userEdited = true;
			}
		});
	}

	/**
	 * Interception d'un changement sur le type d'entretien
	 */
	onTypeEntretienChange() {
		//Remise à zéro du type d'entretien courant
		this.detail.typeEntretienItem = null;

		//Mise à jour du sous-type
		this.detail.sousType = this.detail.typeEntretien == 'CONTROLE_REGLEMENTAIRE' ? 'TECHNIQUE' : null;
	}

	/**
	 * Navigation vers la facture
	 */
	goToFacture(facture: any) {
		//Navigation vers la facture
		this.rightService.hasRight(TypeDroit.FACTURE,'consultation') && this.layoutService.goToByState('listeFactures-load',{
			routeParams: {
				idFacture: facture.idFacture
			},
			withGoBack: true
		});
	}

	/**
	 * Vérification de la validité du pays en cas d'import
	 */
	isPaysValidForImport(): boolean {
		let listeTaux: Array<number>;

		//Vérification de la présence d'une anomalie sur le taux de taxe
		if (this.detail.importe && this.hasTypeAnomalie('TAUX_TAXE')) {
			//Récupération de la liste des taux de taxe de la ligne
			listeTaux = this.detail.listeTaxes?.length ? this.detail.listeTaxes.map(t => t.taux) : [this.detail.tauxTaxe];

			//Vérification que les taux de taxe sont présents dans le référentiel du pays
			return this.taxeForPays?.listeTaux?.length && listeTaux.every(taux => this.taxeForPays.listeTaux.map(t => t.taux).includes(taux));
		}

		//Aucune vérification
		return true;
	}
}
