import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ImportTemplateService } from 'src/app/share/components/import-template/import-template.service';

@Component({
	selector: 'import-specification-template-list-item',
	templateUrl: './import-specification-template-list-item.component.html'
})
export class ImportSpecificationTemplateListItemComponent extends ListViewItem<any> {
	/** Indicateur de suppression possible **/
	@Input() canRemove: boolean;

	/**
	 * Constructeur
	 */
	constructor(private importTemplateService: ImportTemplateService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Récupération de la liste des champs
	 */
	public getListeFields(): string {
		//Retour de la liste des champs
		return this.data.listeFields.map(f => f.name).join(', ');
	}

	/**
	 * Affichage du template d'import
	 */
	public showImportTemplate() {
		//Affichage de la popup de création d'un template d'import
		this.importTemplateService.showImportTemplate(this.extraOptions.typeModule,this.extraOptions.selectedFormat,this.data).subscribe({
			next: importTemplate => {
				//Mise à jour du template
				Object.assign(this.data,importTemplate);
			}
		});
	}

	/**
	 * Suppression du template d'import
	 */
	public deleteImportTemplate() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.importTemplateService.deleteImportTemplate(this.data))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}
}