import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AffectationService } from './affectation.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';

@Component({
	selector: 'affectation',
	templateUrl: './affectation.component.html'
})
export class AffectationComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() affectation: any;

	/** Gestion d'une modification de l'affectation **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Demande de rafraichissement de la liste **/
	@Output() refreshListe = new EventEmitter<any>();

	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/** Indicateur de chargement de l'affectation **/
	isAffectationLoaded: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private affectationService: AffectationService,private changeDetectorRef: ChangeDetectorRef,private layoutService: LayoutService,public rightService: RightService,public vehiculeService: VehiculeService,private store: Store<AppState>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde de l'affectation
		this.affectation = Object.assign({},this.affectation);

		//Vérification de l'identifiant de l'affectation
		if (this.affectation.idAffectation) {
			//Chargement complet de l'affectation
			this.affectationService.loadAffectation(this.affectation.idAffectation).subscribe(result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Mise à jour de l'affectation
					Object.assign(this.affectation,result.data.affectation);
				}

				//Mise à jour de l'indicateur de chargement
				this.isAffectationLoaded = true;
			});
		}

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Redirection vers le véhicule
	 */
	showVehicule() {
		//Redirection vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.affectation.vehicule?.idVehicule || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Fermeture de l'affectation après enregistrement
	 */
	onContentClose(data: any) {
		//Vérification de la présence de données
		if (data) {
			//Chargement complet de l'affectation
			this.affectationService.loadAffectation(data.affectation.idAffectation).subscribe(result => {
				//Mise à jour de l'affectation
				Object.assign(this.affectation,result.data.affectation);

				//Vérification de la nécessité de recharger la liste
				if (data.idAffectationClosed)
					//Rechargement de la liste
					this.refreshListe.emit();
				else
					//Mise à jour de l'affectation
					this.onObjectChange.emit(result.data.affectation);
			});
		} else {
			//Rechargement de la liste
			this.refreshListe.emit();
		}
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}