import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,map,first } from 'rxjs';

import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { WorkflowService } from 'src/app/components/workflow/workflow.service';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DemandeVehiculeAddComponent } from './demande-vehicule-add.component';
import { DemandeVehiculeListItemComponent } from './demande-vehicule-list-item.component';
import { mapEntites } from './demande-vehicule.entite';
import { DemandeVehiculeService } from './demande-vehicule.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './demande-vehicule-list.component.html'
})
export class DemandeVehiculeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,DemandeVehiculeListItemComponent>;

	/** Entité **/
	private readonly entite: string = 'com.notilus.data.vehicule.cotation.DemandeVehicule';

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,public dashboardService: DashboardService,public extractionService: ExtractionService,private demandeVehiculeService: DemandeVehiculeService,private bsModalService: BsModalService,private workflowService: WorkflowService,public connecteurService: ConnecteurService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Recherche d'un workflow
		this.workflowService.isWorkflowAvailableForEntity(this.entite).subscribe({
			next: () => {
				//Définition de la liste
				this.liste = new ListView<any,DemandeVehiculeListItemComponent>({
					uri: () => `/controller/VehiculeCommande/filtreDemandesVehicule`,
					title: this.translateService.instant('demandeVehicule.liste.title'),
					component: DemandeVehiculeListItemComponent,
					listeFilters: [{
						clef: 'user',
						isDefault: true,
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'user',
							fieldForDefault: '*nom,*prenom'
						}
					},{
						clef: 'vehicule',
						isDefault: true,
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'vehicule',
							fieldForDefault: '*reference'
						},
						title: this.translateService.instant('demandeVehicule.nouveauVehicule')
					},{
						clef: 'vehiculeSource',
						isDefault: true,
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'vehicule',
							fieldForDefault: '*reference'
						},
						title: this.translateService.instant('demandeVehicule.vehiculeARenouveler')
					},{
						clef: 'referenceCommande',
						isDefault: true,
						title: this.translateService.instant('demandeVehicule.referenceCommande.long')
					},{
						clef: 'societe',
						isDefault: true,
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'societe',
							fieldForDefault: '*reference,*libelle'
						}
					},{
						clef: 'service',
						isDefault: true,
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'service',
							fieldForDefault: '*reference,*libelle'
						}
					},{
						clef: 'statut',
						listeValues: this.demandeVehiculeService.getListeStatuts().filter(statut => statut.icone),
						type: TypeFilter.LONG
					}],
					defaultOrder: '-idDemandeVehicule',
					getKeyFieldName: () => 'idDemandeVehicule',
					hasMainAction: () => this.rightService.hasRight(null,'creation'),
					doMainAction: () => this.showAddDemandeVehicule()
				});
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}

	/**
	 * Affichage de la popup d'ajout d'une demande de véhicule
	 */
	private showAddDemandeVehicule() {
		let bsModalRef: BsModalRef<DemandeVehiculeAddComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(DemandeVehiculeAddComponent,{
			class: 'modal-md',
			backdrop: 'static'
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.demandeVehiculeAdded),
			filter(demandeVehiculeAdded => !!demandeVehiculeAdded)
		).subscribe({
			next: (demandeVehicule: any) => {
				//Navigation vers la demande de véhicule
				this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
					routeParams: {
						idDemandeVehicule: 0
					},
					routeData: {
						demandeVehicule
					},
					withGoBack: true
				});
			}
		});
	}
}