import { AfterViewInit,Component,ElementRef,OnInit,QueryList,ViewChild,ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first,map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

import { ChatbotService } from './chatbot.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { Exchange,TypeScenario } from 'src/app/domain/chatbot/chatbot';
import { ArticleService as SharedArticleService } from 'src/app/share/components/article/article.service';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AppState } from 'src/app/domain/appstate';
import { UPDATE_OPEN_CREATION } from 'src/app/reducers/navigation';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { PREVIEW_CHART } from 'src/app/reducers/dashboard';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	selector: 'chatbot',
	templateUrl: './chatbot.component.html'
})
export class ChatbotComponent implements OnInit,AfterViewInit {
	/** Scénario sélectionné **/
	public selectedScenario: any = null;

	/** Liste des échanges ***/
	public listeExchanges: Array<Exchange> = [];

	/** Attente de la réponse du bot **/
	public isLoading: boolean = false;

	/** Message de l'utilisateur **/
	public newMessage: string;

	/** Indicateur de verrouillage de la zone de saisie utilisateur **/
	public isInputDisabled: boolean = true;

	/** Mode d'affichage de la pop-up **/
	public isMinimized: boolean = false;

	/** Exemple de question à poser **/
	public exampleQuestion: string;

	/** Liste des scénarios possibles **/
	public listeScenarios: Array<any> = [];

	/** Identifiant de corrélation de la session **/
	private correlationId: string;

	/** Élément défilable **/
	private scroller: ElementRef<HTMLElement>;

	/** URL de la politique de confidentialité **/
	public confidentialiteUrl: string;

	/** Type de scénario **/
	public currentTypeScenario: TypeScenario = null;

	/** Indicateur d'affichage du type de scénario **/
	public isTypeScenarioShown: boolean = false;

	/** Indicateur de sélection de scénario **/
	public isSelectionScenario: boolean = false;

	/** Liste des messages affichés **/
	@ViewChildren('message') listeMessages: QueryList<any>;

	/**
	 * Constructeur
	 */
	constructor(public chatbotService: ChatbotService,private translateService: TranslateService,private toastrService: ToastrService,private pluralPipe: PluralTranslatePipe,private sharedArticleService: SharedArticleService
			,private messagingService: MessagingService,public rightService: RightService,private store: Store<AppState>,private layoutService: LayoutService,private extractionService: ExtractionService,private connecteurService: ConnecteurService) {

	}

	/**
	 * Interception de la mise à jour de l'élément défilable
	 */
	@ViewChild('scroller') set setScroller(scroller: ElementRef<HTMLElement>) {
		//Vérification de la référence
		if (scroller && !this.scroller)
			//Mise à jour de la référence
			this.scroller = scroller;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Début d'attente
		this.isLoading = true;

		//Remise à zéro de l'identifiant de corrélation
		this.correlationId = null;

		//Récupération de la liste des scénarios actifs et disponibles pour l'utilisateur
		combineLatest([this.chatbotService.getListeScenarios(),this.chatbotService.findAllScenariosAvailable().pipe(map(result => result.data.listeScenarios))]).subscribe({
			next: ([listeScenarios,listeScenariosAvailable]) => {
				//Fin d'attente du bot
				this.isLoading = false;

				//Mémorisation de la liste des scénarios actifs et disponibles
				this.listeScenarios = listeScenarios.filter(s => !s.disabled && listeScenariosAvailable.some(scenarioAvailable => scenarioAvailable == s.code));

				//Envoi de la question dans le chat
				this.listeExchanges.push({
					type: 'BOT',
					text: this.listeScenarios[0].text
				});

				//Déverrouillage de la zone de saisie
				this.isInputDisabled = false;
			}
		});

		//Récupération de l'URL de confidentialité
		this.store.select<string>(state => state.session.confidentialiteUrl).pipe(first()).subscribe({
			next: confidentialiteUrl => {
				//Mémorisation de l'URL de confidentialité
				this.confidentialiteUrl = confidentialiteUrl;
			}
		});

		//Définition de la question donnée comme exemple de saisie
		this.exampleQuestion = this.translateService.instant(`chatbot.placeholder.${Math.floor(Math.random() * 5)}`);
	}

	/**
	 * Chargement de la vue
	 */
	ngAfterViewInit(): void {
		//Détection de l'affichage de nouveaux messages
		this.listeMessages.changes.subscribe(() => {
			//Défilement automatique au bas de la fenêtre
			this.scrollToBottom();
		});
	}

	/**
	 * Envoi d'un message
	 */
	public sendMessage(message: string) {
		let messaging$: MessagingObservables;

		//Vérification du message
		if (message?.length) {
			//Ajout du message saisi par l'utilisateur à la liste
			this.listeExchanges.push({
				type: 'USER',
				text: message
			});

			//Remise à zéro du message de l'utilisateur
			this.newMessage = '';

			//Début d'attente (le bot écrit une réponse)
			this.isLoading = true;

			//Démarrage de l'action par websocket
			messaging$ = this.messagingService.init({
				method: 'POST',
				entryPoint: `controller/Chatbot/sendMessage`,
				params: {
					correlationId: this.correlationId,
					text: message,
					typeScenario: this.currentTypeScenario
				}
			}).onResult({
				next: result => {
					//Vérification de l'identifiant de corrélation
					if (!this.correlationId)
						//Mémorisation de l'identifiant de corrélation
						this.correlationId = result?.data?.correlationId;
				}
			}).onMessage({
				next: message => {
					let exchange: Exchange = null;

					//Vérification de l'identifiant de corrélation
					if (!this.correlationId && message?.correlationId)
						//Mémorisation de l'identifiant de corrélation
						this.correlationId = message.correlationId;

					//Vérification de la référence de partie
					if (message.reference?.length) {
						//Recherche de l'échange existant
						exchange = this.listeExchanges.find(exchange => exchange.partId === message.reference);

						//Vérification de l'existence de l'échange
						if (exchange) {
							//Complétion de l'échange
							exchange.markdown += message.message;

							//Défilement automatique au bas de la fenêtre
							this.scrollToBottom();
						}
					}

					//Vérification de l'absence d'échange existant
					if (!exchange) {
						//Ajout de la réponse du bot
						this.listeExchanges.push({
							type: 'BOT',
							partId: message.reference,
							markdown: message.message
						});
					}
				}
			}).onFinish({
				next: message => {
					let action: any;
					let exchange: Exchange;
					let markdown: string;

					//Fin d'attente du bot
					this.isLoading = false;

					//Récupération de l'action
					action = message?.data?.action;

					//Vérification de l'existence d'un type de scénario
					this.currentTypeScenario = action?.typeScenario;

					//Ajout du type de scénario
					this.addTypeScenario();

					//Vérification de la fin de l'action
					if (action?.valid) {
						//Vérification du type de scénario
						switch (action?.typeScenario) {
						case TypeScenario.SEARCH_FAQ:
							//Vérification de la liste des articles trouvés
							if (action?.listeArticles?.length) {
								//Vérification du fallback
								if (action.fallback) {
									//Ajout d'un échange
									this.listeExchanges.push(exchange = {
										type: 'BOT'
									});
								} else
									//Récupération du dernier échange
									exchange = this.listeExchanges[this.listeExchanges.length - 1];

								//Indicateur de fallback
								exchange.fallback = action.fallback;

								//Ajout du message
								exchange.markdown += `\n\n${this.translateService.instant(this.pluralPipe.transform('chatbot.response.SEARCH_FAQ.result',action.listeArticles.length))}`;

								//Ajout des articles
								Object.assign(exchange,{
									limitLinks: 5,
									listeLinks: action.listeArticles.map(article => ({
										text: article.titre,
										onClick: () => {
											//Ouverture de l'article
											this.sharedArticleService.consultArticle(article);
										}
									}))
								});
							}

							//Affichage du type de scénario si nécessaire
							this.addTypeScenario();

							//Vérification de la compréhension de l'action
							if (!action.fallback)
								//Ajout d'un message pour l'évaluation
								this.addRatingRequest();

							//Suppression du type de scénario
							this.currentTypeScenario = null;
							break;
						case TypeScenario.CREATE_VEHICULE:
							//Ajout de l'échange de réponse
							this.listeExchanges.push({
								type: 'BOT',
								markdown: action.botOutput,
								listeActions: [{
									title: this.translateService.instant(this.translateService.instant('chatbot.response.CREATE_VEHICULE.action',{ immatriculation: action.immatriculation })),
									onClick: () => {
										//Action de création du véhicule
										this.doActionForState('listeVehicules',{ immatriculation: action.immatriculation });
									}
								}]
							});

							//Affichage du type de scénario si nécessaire
							this.addTypeScenario();

							//Ajout d'un message pour l'évaluation
							this.addRatingRequest();

							//Suppression du type de scénario
							this.currentTypeScenario = null;
							break;
						case TypeScenario.CREATE_SINISTRE:
							//Ajout de l'échange de réponse
							this.listeExchanges.push({
								type: 'BOT',
								markdown: action.botOutput,
								listeActions: [{
									title: this.translateService.instant(this.translateService.instant('chatbot.response.CREATE_SINISTRE.action')),
									onClick: () => {
										//Action de création du sinistre
										this.doActionForState('listeVehiculeSinistres',{ reference: action.reference,vehicule: action.vehicule,dateSinistre: action.dateSinistre });
									}
								}]
							});

							//Affichage du type de scénario si nécessaire
							this.addTypeScenario();

							//Ajout d'un message pour l'évaluation
							this.addRatingRequest();

							//Suppression du type de scénario
							this.currentTypeScenario = null;
							break;
						case TypeScenario.EXECUTE_CHART:
							//Vérification de la liste des graphiques trouvés
							if (action?.listeCharts?.length) {
								//Construction du markdown
								markdown = action.done ? `${action.botOutput}\n\n` : '';

								//Ajout du nombre de graphiques trouvés
								markdown = action.listeCharts?.length ? `${markdown}${this.translateService.instant(this.pluralPipe.transform('chatbot.response.EXECUTE_CHART.result',action.listeCharts.length))}` : this.translateService.instant('chatbot.response.EXECUTE_CHART.absenceResultat');

								//Ajout de l'échange de réponse
								this.listeExchanges.push({
									type: 'BOT',
									markdown,
									limitLinks: 5,
									listeLinks: action.listeCharts.map(chart => ({
										text: chart.libelle,
										onClick: () => {
											//Lancement de l'exécution du graphique
											this.store.dispatch({
												type: PREVIEW_CHART,
												payload: {
													chart,
													source: 'CHATBOT'
												}
											});
										}
									}))
								});
							}

							//Affichage du type de scénario si nécessaire
							this.addTypeScenario();

							//Ajout d'un message pour l'évaluation
							this.addRatingRequest();

							//Suppression du type de scénario
							this.currentTypeScenario = null;
							break;
						case TypeScenario.EXECUTE_EXTRACTION:
							//Vérification de la liste des extractions trouvées
							if (action?.listeExtractions?.length) {
								//Ajout de l'échange de réponse
								this.listeExchanges.push({
									type: 'BOT',
									markdown: `${action.botOutput}\n\n${this.translateService.instant(this.pluralPipe.transform('chatbot.response.EXECUTE_EXTRACTION.result',action.listeExtractions.length))}`,
									limitLinks: 5,
									listeLinks: action.listeExtractions.map(extraction => ({
										text: extraction.libelle,
										onClick: () => {
											//Lancement de l'exécution de l'extraction
											this.extractionService.executeExtraction(extraction);
										}
									}))
								});
							}

							//Affichage du type de scénario si nécessaire
							this.addTypeScenario();

							//Ajout d'un message pour l'évaluation
							this.addRatingRequest();

							//Suppression du type de scénario
							this.currentTypeScenario = null;
							break;
						case TypeScenario.IMPORT_DATA:
							//Ajout de l'échange de réponse
							this.listeExchanges.push({
								type: 'BOT',
								markdown: action.botOutput,
								listeActions: [{
									title: this.translateService.instant(this.translateService.instant('chatbot.response.IMPORT_DATA.action')),
									onClick: () => {
										//Lancement de l'import de données
										this.connecteurService.showImport(null,[]);
									}
								}]
							});

							//Affichage du type de scénario si nécessaire
							this.addTypeScenario();

							//Ajout d'un message pour l'évaluation
							this.addRatingRequest();

							//Suppression du type de scénario
							this.currentTypeScenario = null;
							break;
						default:
							//Ne rien faire
							break;
						}
					}

					//Fermeture des souscriptions
					messaging$.unsubscribe();
				}
			}).onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();
				}
			});
		}
	}

	/**
	 * Ajout du type de scénario
	 */
	addTypeScenario() {
		let indexLastBotExchange: number;

		//Récupération de l'échange précédent
		indexLastBotExchange = this.listeExchanges.map(e => e.type == 'BOT' && (e.markdown || e.partId || e.listeActions || e.listeLinks) && !e.listeStars).lastIndexOf(true);

		//Vérification de l'absence de type de scénario affiché et de la présence d'un type de scénario courant
		if (!this.isTypeScenarioShown && this.currentTypeScenario && indexLastBotExchange >= 0) {
			//Affichage du type de scénario
			this.isTypeScenarioShown = true;

			//Mémorisation du type de scénario
			this.listeExchanges[indexLastBotExchange].typeScenario = this.currentTypeScenario;
		}
	}

	/**
	 * Ajout de l'évaluation des échanges
	 */
	public addRatingRequest() {
		//Ajout d'un message pour l'évaluation
		this.listeExchanges.push({
			type: 'BOT',
			correlationId: this.correlationId,
			listeStars: [1,2,3,4,5].map(() => {
				//Aucune évaluation initiale
				return { filled: false };
			}),
			finished: true
		});

		//Réinitialisation de l'identifiant de corrélation
		this.correlationId = null;

		//Réinitialisation du type de scénario
		this.currentTypeScenario = null;

		//Réinitialisation de l'affichage du type de scénario
		this.isTypeScenarioShown = false;
	}

	/**
	 * Mise à jour de l'évaluation de la conversation en cours
	 */
	public updateRating(item: Exchange,index: number) {
		//Vérification de l'index
		if (index >= 0) {
			//Parcours de la liste des étoiles
			(item.listeStars as any[]).forEach((star,i) => {
				//Mise à jour de l'évaluation
				star.filled = i <= index;
			});

			//Définition du score
			item.score = index + 1;
		}

		//Mise à jour du score
		this.chatbotService.evaluateJob(item,{
			score: item.score,
			comment: item.comment
		}).subscribe(result => {
			//Vérification du code d'erreur
			if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Elément évalué
				item.rated = true;

				//Identifiant de l'échange
				item.idChatbotExchange = result.data?.idChatbotExchange;

				//Vérification du commentaire
				if (item.comment) {
					//Ajout du message de remerciement
					this.listeExchanges.push({
						type: 'BOT',
						text: this.translateService.instant('chatbot.exchange.merci')
					});
				} else {
					//Déclenchement différé
					setTimeout(() => {
						//Scroll en bas de la liste des messages
						this.scrollToBottom();
					});
				}

				//Elément commenté
				item.commented = !!item.comment;
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Envoi du commentaire pour l'évaluation du chabot
	 */
	public sendComment(event: KeyboardEvent,item: Exchange) {
		//Appui sur la touche 'Entrée'
		if (event.code == 'Enter' && !event.shiftKey) {
			//Annulation de l'évènement par défaut
			event.preventDefault();

			//Mise à jour de l'évaluation
			this.updateRating(item,-1);
		}
	}

	/**
	 * Réduction/Maximisation de la fenêtre
	 */
	public toggleWindow() {
		//Réduction/Maximisation de la fenêtre
		this.isMinimized = !this.isMinimized;
	}

	/**
	 * Scroll en bas de la liste des messages
	 */
	private scrollToBottom() {
		//Scroll en bas de la liste des messages
		this.scroller.nativeElement.scrollTop = this.scroller.nativeElement.scrollHeight;
	}

	/**
	 * Ouverture des conditions générales d'utilisation
	 */
	public openCGU() {
		//Chargement de l'article CGU
		this.sharedArticleService.loadTypeArticle('CGU').subscribe({
			next: result => {
				//Véficication du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR && result.data?.article)
					//Consultation de l'article
					this.sharedArticleService.consultArticle(result.data.article);
				else
					//Erreur de chargement
					this.toastrService.error(this.translateService.instant('chatbot.cgu.errorLoad'));
			}
		});
	}

	/**
	 * Ouverture de la politique de confidentialité
	 */
	public openConfidentialiteUrl() {
		//Ouverture de l'article en consultation
		window.open(this.confidentialiteUrl,'_new');
	}

	/**
	 * Réalisation de l'action liée au retour final du chatbot
	 */
	public doActionForState(state: any,data?: any) {
		//Définition de l'indicateur de création
		this.store.dispatch({
			type: UPDATE_OPEN_CREATION,
			payload: {
				state: state,
				creationData: data
			}
		});

		//Navigation vers la route
		this.layoutService.goToByState(state,{ reloadOnSameUrl: true });
	}

	/**
	 * Récupération d'un type de scénario par son code
	 */
	getTypeScenarioForCode(code: string) {
		//Retour du type de graphique
		return this.listeScenarios.find(e => e.code == code);
	}

	/**
	 * Sélection d'un scénario
	 */
	public selectTypeScenario(typeScenario: any) {
		let lastUserExchange: Exchange;

		//Fermeture du mode de sélection de scénario
		this.isSelectionScenario = false;

		//Suppression de l'identifiant de corrélation
		this.correlationId = null;

		//Réinitialisation du type de scénario
		this.isTypeScenarioShown = false;

		//Récupération du dernier échange de l'utilisateur
		lastUserExchange = this.listeExchanges.reverse().find(e => e.type == 'USER' && !e.rated && !e.commented);

		//Réinitialisation de la liste des échanges
		this.listeExchanges = [];

		//Mise à jour du type de scénario
		this.currentTypeScenario = typeScenario.code;

		//Envoi du dernier message de l'utilisateur
		lastUserExchange?.text?.length && this.sendMessage(lastUserExchange.text);
	}

	/**
	 * Réinitialisation de la conversation
	 */
	public resetExchanges() {
		//Initialisation du scénario sélectionné
		this.currentTypeScenario = null;

		//Initialisation de l'affichage du type de scénario
		this.isTypeScenarioShown = false;

		//Initialisation de l'identifiant de corrélation
		this.correlationId = null;

		//Initialisation de la liste des échanges
		this.listeExchanges = [{
			type: 'BOT',
			text: this.listeScenarios[0].text
		}];
	}

	/**
	 * Affichage des scénarios depuis la réponse de fallback
	 */
	onFallbackClick($event: any) {
		//Vérification du clic sur le lien
		if ($event.target.classList.contains('clickable'))
			//Affichage des scénarios
			this.isSelectionScenario = true;
	}
}