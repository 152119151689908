<form #importResumeExecutionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>connecteur.import.resume.title</h4>
	</div>
	<div class="modal-body">
		<import-content [connecteurExecution]="connecteurExecution" source="MODAL"></import-content>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-stroked-button color="primary" (click)="goToImport()">
			<span translate>connecteur.import.resume.goToImport</span>
		</button>
	</div>
</form>