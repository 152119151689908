<div ngModelGroup="affectationContent">
	<div class="card m-b-0" [ngClass]="{ 'detail': source != 'AFFECTATION' }">
		<div class="card-header" *ngIf="source != 'PLANNING' && source != 'AFFECTATION'">
			<h2 class="card-title" translate>vehicule.affectation.titre</h2>
		</div>
		<div class="card-body card-padding p-b-0">
			<div class="alert alert-danger" *ngIf="!periodeValidity.isValid || periodeVehiculeError">
				<icon name="warning"></icon>
				<div *ngIf="!periodeValidity.isValid">
					<strong translate>vehicule.affectation.alertes.chevauchementPeriode.title</strong>
					<div>
						<span *ngIf="periodeValidity.affectation">
							<span translate>vehicule.affectation.alertes.chevauchementPeriode.description</span>
							<span>{{ '(' + (periodeValidity.affectation.dateDebut | date:'short') + (periodeValidity.affectation.dateFin ? ' - ' + (periodeValidity.affectation.dateFin | date:'short') : '') + ')' }}</span>
						</span>
					</div>
				</div>
				<div *ngIf="periodeVehiculeError">
					<strong translate>vehicule.affectation.alertes.periodeVehicule.title</strong>
					<div>
						<span translate>vehicule.affectation.alertes.periodeVehicule.description</span>
						<span>{{ '(' + (periodeVehiculeError.dateEntree | date:'short') + (periodeVehiculeError.dateSortie ? ' - ' + (periodeVehiculeError.dateSortie | date:'short') : '') + ')' }}</span>
					</div>
				</div>
			</div>
			<div *ngIf="user.type == 'VIRTUAL'" class="alert alert-info">
				<icon name="info"></icon>
				<strong translate>vehicule.affectation.alertes.userVirtualConfirmation</strong>
			</div>

			<fieldset [disabled]="!(TypeDroit.ADMIN_VEHICULE | right:'creation') && !(TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': type.invalid }" *ngIf="listeTypesAffectation">
							<label translate>vehicule.affectation.type.itemShort</label>
							<div>
								<selectpicker name="type" #type="ngModel" [(ngModel)]="affectation.typeAffectation" [disabled]="!(TypeDroit.ADMIN_VEHICULE | right:'creation') && !(TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || user.type == 'VIRTUAL' || affectation.reservation" (ngModelChange)="affectation.synchroImputationUser = affectation.typeAffectation != 'VEHICULE_POOL';" required>
									<mat-option *ngFor="let type of listeTypesAffectation" [value]="type.code">{{ type.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': !affectation.user }" *ngIf="!isCollabDisabled && affectation.typeAffectation != 'VEHICULE_IMMOBILISE'">
							<label translate>vehicule.affectation.conducteur.item</label>
							<div>
								<div class="input-group">
									<ng-container *ngIf="user.type != 'VIRTUAL'">
										<autocomplete name="collaborateur" type="user" [(ngModel)]="affectation.user" [disabled]="!(TypeDroit.ADMIN_VEHICULE | right:'creation') && !(TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || affectation.reservation" required></autocomplete>
									</ng-container>
									<span *ngIf="user.type == 'VIRTUAL'">{{ affectation.user | display:'user' }}</span>
									<div class="input-group-addon" *ngIf="user.type != 'VIRTUAL'" [ngClass]="{ 'clickable': affectation.vehicule,'disabled': !affectation.vehicule }" (click)="affectation.vehicule && showListeAffectations(affectation.vehicule);" [tooltip]="'vehicule.affectation.historiqueAffectations' | translate">
										<icon name="replay_30" class="icon-hc-2x"></icon>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': !affectation.vehicule }" *ngIf="isCollabDisabled || source == 'PLANNING' || source == 'AFFECTATION'">
							<label translate>vehicule.affectation.vehicule</label>
							<div>
								<ng-container *ngIf="!affectation.vehicule">
									<autocomplete name="vehicule" type="vehicule" filter="VEHICULE_FONCTION_SERVICE" [(ngModel)]="affectation.vehicule" required></autocomplete>
								</ng-container>
								<div *ngIf="affectation.vehicule">
									<mat-chip-listbox>
										<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') && user.type != 'VIRTUAL' }" (removed)="removeVehicule()" (click)="user.type != 'VIRTUAL' && goToVehicule()">
											{{ affectation.vehicule | display:'vehicule' }}
											<button matChipRemove *ngIf="(null | right:'creation') && canRemoveVehicule && source != 'PLANNING' && user.type != 'VIRTUAL'">
												<mat-icon>cancel</mat-icon>
											</button>
										</mat-chip>
										<span>
											<icon name="replay_30" class="clickable pull-right icon-hc-2x" *ngIf="isCollabDisabled" (click)="showListeAffectations(affectation.vehicule);" [tooltip]="'vehicule.affectation.historiqueAffectations' | translate"></icon>
											<icon name="replay_30" class="clickable pull-right icon-hc-2x" *ngIf="affectation.vehicule.idVehicule && affectation.vehicule.listeOldReferences?.length > 0" (click)="vehiculeService.showListeImmatriculations(affectation.vehicule.idVehicule)" [tooltip]="'vehicule.immatriculation.historique.title' | translate"></icon>
										</span>
									</mat-chip-listbox>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
							<label translate>vehicule.affectation.dateDebut.itemShort</label>
							<div>
								<div class="input-group">
									<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="affectation.dateDebut" datePicker format="datetime" [disabled]="!(TypeDroit.ADMIN_VEHICULE | right:'creation') && !(TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')" required/>
									<div class="input-group-addon no-border">
										<icon name="info_outline" [tooltip]="'vehicule.affectation.dateDebut.description' | translate"></icon>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid || affectation.dateFin && (!affectation.dateDebut || affectation.dateFin < affectation.dateDebut) }">
							<label translate>vehicule.affectation.dateFin.itemShort</label>
							<div>
								<div class="input-group">
									<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="affectation.dateFin" datePicker format="datetime" [disabled]="!(TypeDroit.ADMIN_VEHICULE | right:'creation') && !(TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')"/>
									<div class="input-group-addon no-border">
										<icon name="info_outline" [tooltip]="'vehicule.affectation.dateFin.description' | translate"></icon>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="affectation.typeAffectation == 'VEHICULE_FONCTION'">
							<label translate>vehicule.affectation.typeCalculAvantageNature</label>
							<div>
								<selectpicker name="typeCalculAvantageNature" #typeCalculAvantageNatureField="ngModel" [(ngModel)]="affectation.typeCalculAvantageNature">
									<mat-option *ngFor="let typeCalculAvantageNature of listeTypesCalculAvantageNature" [value]="typeCalculAvantageNature.code">{{ typeCalculAvantageNature.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': montantAen.invalid }" *ngIf="affectation.typeAffectation == 'VEHICULE_FONCTION' && user.type != 'VIRTUAL'">
							<label translate>vehicule.affectation.montantAen</label>
							<div class="half">
								<div class="input-group">
									<input type="text" class="form-control" name="montantAen" #montantAen="ngModel" [(ngModel)]="affectation.montantAvantageNature" nNumber="2" min="0"/>
									<span class="input-group-addon no-border">{{ user.devise }}</span>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': montantParticipation.invalid }" *ngIf="affectation.typeAffectation == 'VEHICULE_FONCTION' && user.type != 'VIRTUAL'">
							<label translate>vehicule.affectation.montantParticipation</label>
							<div class="half">
								<div class="input-group">
									<input type="text" class="form-control" name="montantParticipation" #montantParticipation="ngModel" [(ngModel)]="affectation.montantParticipation" nNumber="2" min="0"/>
									<span class="input-group-addon no-border">{{ user.devise }}</span>
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="user.type != 'VIRTUAL'">
							<label translate>vehicule.affectation.piecesJointes</label>
							<div [attachment]="affectation" [typeAttachment]="TypeAttachment.VEHICULE_AFFECTATION" [listeTypesDroit]="[TypeDroit.ADMIN_VEHICULE,TypeDroit.VEHICULE_CONDUCTEUR]"></div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" *ngIf="affectation.user?.userDescription && affectation.typeAffectation != 'VEHICULE_IMMOBILISE' && user.type != 'VIRTUAL'">
							<label personalData translate>vehicule.affectation.adressePersonnelle</label>
							<div>
								<adresse name="adresse" #adresse="adresse" [(ngModel)]="affectation.user.userDescription.adressePerso" [disabled]="!(TypeDroit.ADMIN_VEHICULE | right:'creation') && !(TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')"></adresse>
							</div>
						</div>
						<div class="form-group" *ngIf="affectation.user && affectation.conducteur && affectation.typeAffectation != 'VEHICULE_IMMOBILISE' && user.type != 'VIRTUAL'">
							<label personalData translate>vehicule.affectation.permisConduire</label>
							<div>
								<a [routerLink]="[]" (click)="showPermisConduire();">
									<span *ngIf="affectation.conducteur.permisConduire?.reference">{{ affectation.conducteur.permisConduire.reference }}</span>
									<span *ngIf="!affectation.conducteur.permisConduire?.reference" translate>vehicule.affectation.conducteur.aRenseigner</span>
								</a>
							</div>
						</div>
						<div class="form-group" *ngIf="affectation.reservation">
							<label translate>vehicule.affectation.reservation.statut</label>
							<div>
								<span [translate]="'reservation.statut.'+affectation.reservation.statut"></span>
							</div>
						</div>
						<div class="form-group" *ngIf="affectation.reservation">
							<label translate>vehicule.affectation.reservation.dateReservation</label>
							<div>
								<span>{{ affectation.reservation.dateCreation | date:'short' }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.affectation.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" [(ngModel)]="affectation.remarque" rows="3" [disabled]="user.type == 'VIRTUAL'"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
	</div>

	<div class="card" [ngClass]="{ 'detail': source != 'AFFECTATION' }">
		<data-customization entite="com.notilus.data.vehicule.Affectation" [object]="affectation" [disabled]="!(null | right:'creation')" [isSubItem]="source != 'AFFECTATION' && source != 'PLANNING'"></data-customization>
	</div>

	<div class="card" [ngClass]="{ 'detail': source != 'AFFECTATION' }" *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'creation') && affectation.typeAffectation != 'VEHICULE_IMMOBILISE' && user.type != 'VIRTUAL'">
		<div class="card-header">
			<h2 translate>vehicule.affectation.creationImputationQuestion</h2>
			<div class="row">
				<div class="lv-actions actions m-t-0">
					<div class="boolean-button">
						<mat-button-toggle-group [value]="affectation.synchroImputationUser" (change)="affectation.synchroImputationUser = $event.value">
							<mat-button-toggle [value]="true">{{ 'common.oui' | translate }}</mat-button-toggle>
							<mat-button-toggle [value]="false">{{ 'common.non' | translate }}</mat-button-toggle>
						</mat-button-toggle-group>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card" [ngClass]="{ 'detail': source != 'AFFECTATION' }" *ngIf="(TypeDroit.VEHICULE_RESERVATION | right:'creation') && affectation.typeAffectation != 'VEHICULE_IMMOBILISE' && user.type != 'VIRTUAL' && affectation.reservation?.statut == 'EN_ATTENTE'">
		<div class="card-header">
			<h2 translate>vehicule.affectation.validationReservationQuestion</h2>
			<div class="row">
				<div class="lv-actions actions m-t-0">
					<div class="boolean-button">
						<mat-button-toggle-group [value]="affectation.validateReservation" (change)="affectation.validateReservation = $event.value">
							<mat-button-toggle [value]="true">{{ 'common.oui' | translate }}</mat-button-toggle>
							<mat-button-toggle [value]="false">{{ 'common.non' | translate }}</mat-button-toggle>
						</mat-button-toggle-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>