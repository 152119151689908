import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject,of } from 'rxjs';
import { filter,first,map,switchMap,tap } from 'rxjs/operators';

import { Page } from 'src/app/domain/common/http/list-result';
import { Result } from 'src/app/domain/common/http/result';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';
import { ImmatriculationService } from './immatriculation/immatriculation.service';
import { VehiculeReleveCompteurListComponent } from './releve-compteur/vehicule-releve-compteur-list.component';
import { VehiculeImmatriculationListComponent } from './vehicule-immatriculation-list.component';
import { VehiculeEntretienInterventionListComponent } from './entretien/vehicule-entretien-intervention-list.component';
import { VehiculeImmatriculationSelectionComponent } from './vehicule-immatriculation-selection.component';
import { VehiculeImputationSelectionComponent } from './vehicule-imputation-selection.component';
import { VehiculeSortieComponent } from './vehicule-sortie.component';
import { TypePlanning } from 'src/app/domain/planning/planning';

@Injectable()
export class VehiculeService {
	/** Liste des types de véhicule **/
	private readonly listeTypesVehicule: Array<string> = [TypeVehicule.VEHICULE_FONCTION_SERVICE,TypeVehicule.VEHICULE_PERSO,TypeVehicule.ENGIN];

	/** Liste des types de crit'air **/
	private readonly listeTypesCritAir: Array<string> = ['AUCUN','CRITAIR_VERTE','CRITAIR_1','CRITAIR_2','CRITAIR_3','CRITAIR_4','CRITAIR_5','CRITAIR_NON_CLASSE'];

	/** Liste des unités d'usages **/
	private readonly listeTypesUniteUsage: Array<string> = ['DISTANCE','TEMPS'];

	/** Liste des configurations de véhicule **/
	private listeConfigurations: Array<any> = null;

	/** Liste des types de règle de calcul de l'avantage en nature **/
	private readonly listeTypesCalculAvantageNature: Array<string> = [null,'ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT','LOCATION_AVEC_CARBURANT','LOCATION_SANS_CARBURANT'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private layoutService: LayoutService,private translateService: TranslateService,private bsModalService: BsModalService
		,private messagingService: MessagingService,private immatriculationService: ImmatriculationService,private rightService: RightService,private pluralTranslatePipe: PluralTranslatePipe) {

	}

	/**
	 * Récupération de la liste des types de véhicule
	 */
	public getListeTypesVehicule(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de véhicule
		return this.listeTypesVehicule.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.typeVehicule.${code}`)
		}));
	}

	/**
	 * Chargement d'un véhicule
	 */
	public loadVehicule(idVehicule: number,codePays?: string): Observable<Result> {
		//Chargement du véhicule
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadVehicule/${idVehicule}${codePays?.length ? '?codePays='+codePays : ''}`,null);
	}

	/**
	 * Chargement du résumé du véhicule
	 */
	public loadResume(idVehicule: number): Observable<Result> {
		//Chargement du résumé du véhicule
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadResume/${idVehicule}`,null);
	}

	/**
	 * Enregistrement d'un véhicule
	 */
	public saveVehicule(vehicule: any,isWebSocket: boolean = false,isCreationImputation: boolean = false): Observable<Result> {
		let subject: Subject<any> = new Subject<any>();
		let messagingObservables$: MessagingObservables;
		let idVehicule: number;
		let canCloseSubscriptionOnResult: boolean = false;

		//Vérification du mode WebSocket
		if (isWebSocket) {
			//Appel du traitement en WebSocket
			messagingObservables$ = this.messagingService.init({
				entryPoint: `controller/Vehicule/saveVehicule${!vehicule.idVehicule ? '?isUpdateDetailReconciliation=true&isCreationImputation=true' : ''}`,
				params: vehicule,
				method: 'PUT'
			}).onResult({
				next: (result: Result) => {
					//Retour des données
					subject.next(result);

					//Définition de l'identifiant du véhicule
					idVehicule = result?.data?.vehicule?.idVehicule;

					//Vérification de l'indicateur de fermeture de la souscription
					if (canCloseSubscriptionOnResult) {
						//Annulation de la souscription
						messagingObservables$.unsubscribe();

						//Fin du traitement
						subject.complete();
					}
				}
			}).onFinish({
				next: () => {
					//Vérification de l'identifiant du véhicule
					if (idVehicule) {
						//Rechargement du véhicule
						this.loadVehicule(idVehicule).pipe(
							map((result: Result) => {
								//Vérification de la présence d'une donnée
								if (result?.data)
									//Modification du résultat
									result.data.refresh = true

								//Retour du résulat
								return result;
							}),
							first()
						).subscribe({
							next: (result: Result) => {
								//Retour des données
								subject.next(result);
							},
							complete: () => {
								//Annulation de la souscription
								messagingObservables$.unsubscribe();

								//Fin du traitement
								subject.complete();
							}
						})
					} else
						//Définition de l'indicateur
						canCloseSubscriptionOnResult = true;
				}
			}).onError({
				next: () => {
					//Annulation de la souscription
					messagingObservables$.unsubscribe();

					//Rejet du sujet
					subject.error(null);

					//Fin du traitement
					subject.complete();
				}
			});

			return subject;
		} else
			//Enregistrement du véhicule (sans WebSocket)
			return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveVehicule${isCreationImputation ? '?isCreationImputation=true' : ''}`,vehicule);
	}

	/**
	 * Suppression d'un véhicule
	 */
	public deleteVehicule(vehicule: any): Observable<Result> {
		//Suppression du véhicule
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Vehicule/deleteVehicule/${vehicule.idVehicule}`);
	}

	/**
	 * Accès au planning
	 */
	public goToPlanning(vehicule?: any,searchSpec: { listeSelectedFilters?: Array<Filter> } = {},listeIdsVehicule?: Array<number>,routeData?: { initialDate?: any },typePlanning?: TypePlanning) {
		//Vérification de la présence de filtres
		if (!searchSpec?.listeSelectedFilters)
			//Initialisation de la liste de filtres
			searchSpec.listeSelectedFilters = [];

		//Vérification de la recherche
		if (!searchSpec.listeSelectedFilters?.some(e => e.clef == 'pool.idPool') && (listeIdsVehicule?.length || vehicule)) {
			//Ajout du filtre sur les identifiants
			searchSpec.listeSelectedFilters.push({
				clef: 'idVehicule',
				listeObjects: listeIdsVehicule || [vehicule.idVehicule],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.LONG,
				isKeptForSelector: TypePlanning.VEHICULE,
				displayedValeur: vehicule ? vehicule.reference + (vehicule.numeroInterne ? ' - ' + vehicule.numeroInterne : '') + ' (' + (vehicule.modele ? vehicule.modele.marque.libelle + ' ' + vehicule.modele.libelle : this.translateService.instant('vehicule.modele.nonDefini')) + ')' : this.translateService.instant(this.pluralTranslatePipe.transform('vehicule.planning.selection',listeIdsVehicule?.length),{ nbVehicules: listeIdsVehicule?.length })
			});
		}

		//Itération sur les filtres sans libellé
		searchSpec?.listeSelectedFilters?.filter(f => !f.displayedValeur).forEach(f => {
			//Définition de la valeur affichée
			f.displayedValeur = this.translateService.instant(this.pluralTranslatePipe.transform(`vehicule.planning.${f.typeComparaison == TypeComparaison.NOT_IN ? 'selection' : 'deselection'}`,f.listeObjects?.length),{ nbVehicules: f.listeObjects?.length });
		});

		//Navigation
		this.layoutService.goToByState('planning',{
			savedSearch: searchSpec,
			routeParams: {
				typePlanning: typePlanning || TypePlanning.VEHICULE
			},
			routeData,
			withGoBack: true
		});
	}

	/**
	 * Affichage de l'historique des immatriculations d'un véhicule
	 */
	public showListeImmatriculations(idVehicule: number) {
		//Affichage de la popup d'historique des immatriculations
		this.bsModalService.show(VehiculeImmatriculationListComponent,{
			initialState: {
				idVehicule
			},
			class: 'modal-lg'
		});
	}

	/**
	 * Affichage de l'historique des relevés de compteur d'un véhicule
	 */
	public showListeCompteurs(vehicule: any) {
		//Affichage de la popup d'historique des relevés de compteur d'un véhicule
		this.bsModalService.show(VehiculeReleveCompteurListComponent,{
			initialState: {
				vehicule
			},
			class: 'modal-lg'
		});
	}

	/**
	 * Affichage de l'historique des interventions pour un entretien constructeur d'un véhicule
	 */
	public showListeInterventions(vehicule: any,planEntretienDetail: any) {
		//Affichage de la popup d'historique des interventions d'un type donné pour un véhicule
		this.bsModalService.show(VehiculeEntretienInterventionListComponent,{
			initialState: {
				vehicule,
				planEntretienDetail
			},
			class: 'modal-lg'
		});
	}

	/**
	 * Réalisation du renouvellement du véhicule
	 */
	public doVehiculeRenouvellement(vehicule: any) {
		//Rechargement du véhicule
		this.loadVehicule(vehicule.idVehicule).pipe(first()).subscribe({
			next: result => {
				//Vérification du chargement
				if (result?.data?.vehicule) {
					//Redirection vers la demande de véhicule
					this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
						routeParams: {
							idDemandeVehicule: 0
						},
						routeData: {
							demandeVehicule: {
								source: 'RENOUVELLEMENT',
								vehiculeSource: result.data.vehicule,
								statut: 'EN_COURS'
							}
						},
						withGoBack: true
					});
				}
			}
		});
	}

	/**
	 * Récupération de la liste des configurations de véhicule
	 */
	public getListeConfigurations(): Observable<Array<any>> {
		//Vérification de la liste des configurations
		if (!this.listeConfigurations?.length) {
			//Récupération de la liste des configurations de véhicule
			return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/retrieveListeConfigurations`,null).pipe(
				first(),
				map(result => result?.data?.listeConfigurations),
				tap(listeConfigurations => this.listeConfigurations = listeConfigurations)
			);
		} else
			//Retour de la liste des configurations
			return of(this.listeConfigurations);
	}

	/**
	 * Réalisation de la sortie du véhicule
	 */
	public takeOutVehicule(vehicule: any,configurationPays?: any): Observable<any> {
		//Rechargement du véhicule
		return this.loadVehicule(vehicule.idVehicule).pipe(
			map(result => result?.data?.vehicule),
			switchMap(vehicule => {
				let bsModalRef: BsModalRef<VehiculeSortieComponent>;

				//Affichage de la popup de sortie du véhicule
				bsModalRef = this.bsModalService.show(VehiculeSortieComponent,{
					initialState: {
						vehicule: cloneDeep(vehicule),
						configurationPays
					}
				});

				//Retour du véhicule enregistré
				return bsModalRef.onHidden.pipe(
					map(() => bsModalRef.content.savedVehicule)
				);
			})
		);
	}

	/**
	 * Sélection de l'immatriculation d'un véhicule
	 */
	public showImmatriculationSelection(onImmatriculationSelected: ({ immatriculation,params,vehicule,demandeVehicule }: { immatriculation?: any,vehicule?: any,demandeVehicule?: any,params: { modele?: any,dateEntree?: any,societe?: any,service?: any } }) => void,options?: { isDateEntreeVisible: boolean,isWithEngin: boolean,isWithDemandeVehicule?: boolean,typeVehicule?: TypeVehicule,modeleInitial?: any,useExistingImmatriculation?: any,vehicule?: any,isSynchro?: boolean,isImputationVisible?: boolean,reference?: string }): Observable<any> {
		let bsModalRef: BsModalRef<VehiculeImmatriculationSelectionComponent>;
		let params: { modele?: any,dateEntree?: any,societe?: any,service?: any } = {};

		//Affichage de la popup de sélection de l'immatriculation
		bsModalRef = this.bsModalService.show(VehiculeImmatriculationSelectionComponent,{
			initialState: {
				options
			}
		});

		//Interception de la fermeture
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.savedData),
			tap(savedData => {
				//Définition des paramètres
				params.dateEntree = savedData?.dateEntree;
				params.societe = savedData?.societe;
				params.service = savedData?.service;
			}),
			switchMap(savedData => {
				//Affichage de l'immatriculation du véhicule
				return savedData?.immatriculation ? this.immatriculationService.showImmatriculation({
					immatriculation: savedData.immatriculation,
					pays: savedData.pays,
					vehicule: options?.vehicule,
					options: {
						isSynchro: options?.isSynchro
					}
				}) : of({ demandeVehicule: savedData?.demandeVehicule,vehicule: savedData });
			}),
			tap(({ immatriculation,vehicule,demandeVehicule }) => {
				let listeModelesConstructeur: Array<any>;
				let isModeleInitialSelectable: boolean;

				//Vérification de l'immatriculation
				if (immatriculation) {
					//Récupération des modèles constructeur
					listeModelesConstructeur = immatriculation.listeImmatriculationModeles?.filter(m => m.modeleCatalogue?.actif) || [];

					//Suppression de la liste des modèles de l'immatriculation
					delete immatriculation.listeImmatriculationModeles;

					//Récupération de la possibilité d'utiliser directement le modèle pré-renseigné
					isModeleInitialSelectable = options?.modeleInitial && (listeModelesConstructeur.length == 0 || listeModelesConstructeur.some(m => m.modeleCatalogue.reference == options.modeleInitial.reference));

					//Vérification de la nécessité de demander la sélection d'un modèle constructeur
					if (options?.typeVehicule != TypeVehicule.VEHICULE_PERSO && !options.modeleInitial && listeModelesConstructeur.length > 1) {
						//Affichage de la sélection du modèle catalogue
						this.immatriculationService.showListeModelesForImmatriculation(immatriculation).subscribe({
							next: modele => {
								//Définition du modèle
								params.modele = modele;

								//Poursuite du traitement
								onImmatriculationSelected({ immatriculation,params,vehicule });
							}
						});
					} else if (options?.typeVehicule != TypeVehicule.VEHICULE_PERSO && options?.modeleInitial && !isModeleInitialSelectable) {
						//Affichage de la sélection du modèle catalogue
						this.immatriculationService.showModeleCatalogueSelection(immatriculation,options?.modeleInitial).pipe(first(),filter(modele => !!modele)).subscribe(modele => {
							//Définition du modèle
							params.modele = modele;

							//Poursuite du traitement
							onImmatriculationSelected({ immatriculation,params,vehicule });
						});
					} else {
						//Vérification qu'un modèle peut être directement utilisé
						if (isModeleInitialSelectable)
							//Définition du modèle pré-renseigné
							params.modele = options?.modeleInitial;
						else if (listeModelesConstructeur.length == 1)
							//Définition du modèle
							params.modele = listeModelesConstructeur[0].modeleCatalogue;
						else
							//Aucun modèle
							params.modele = null;

						//Poursuite du traitement
						onImmatriculationSelected({ immatriculation,params,vehicule });
					}
				} else if (demandeVehicule) {
					//Poursuite du traitement
					onImmatriculationSelected({
						demandeVehicule,
						params
					});
				} else if (vehicule) {
					//Poursuite du traitement
					onImmatriculationSelected({
						vehicule,
						params
					});
				} else {
					//Aucune sélection effectuée
					onImmatriculationSelected(null);
				}
			})
		);
	}

	/**
	 * Recherche d'un véhicule
	 */
	public findVehicule({ typeVehicule,reference,idMarque,idPays }: { typeVehicule: TypeVehicule,reference: string,idMarque?: number,idPays?: number }): Observable<any> {
		let listeFilters: Array<Filter>;

		//Création de la liste des filtres
		listeFilters = [{
			clef: 'typeVehicule',
			valeur: typeVehicule,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		},{
			clef: 'reference',
			valeur: reference,
			typeComparaison: TypeComparaison.LIKE,
			type: TypeFilter.STRING
		},idMarque && {
			clef: 'marque.idMarque',
			valeur: idMarque,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		},idPays && {
			clef: 'pays.idPays',
			valeur: idPays,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		}].filter(f => !!f);

		//Recherche du véhicule existant
		return this.http.post<Page<any>>(`${environment.baseUrl}/controller/Vehicule/filtreVehicules`,{
			defaultSearch: 'reference',
			listeFilter: listeFilters
		}).pipe(
			first(),
			map(result => result?.content?.[0])
		);
	}

	/**
	 * Recherche d'une immatriculation
	 */
	public findImmatriculation({ immatriculation,typeVehicule,codePays,isSynchro }: { immatriculation: string,typeVehicule: TypeVehicule,codePays: string,isSynchro?: boolean }): Subject<Result> {
		let subject: Subject<any> = new Subject<any>();
		let messagingObservables$: MessagingObservables;
		let queryParams: URLSearchParams = new URLSearchParams();

		//Définition des paramètres
		queryParams.append('immatriculation',immatriculation);
		queryParams.append('typeVehicule',typeVehicule || TypeVehicule.VEHICULE_FONCTION_SERVICE);
		queryParams.append('codePays',codePays);
		queryParams.append('isSynchro',isSynchro ? 'true' : 'false');

		//Appel du traitement en WebSocket
		messagingObservables$ = this.messagingService.init({
			entryPoint: 'controller/Vehicule/findImmatriculation',
			queryParams
		}).onFinish({
			next: message => {
				//Annulation de la souscription
				messagingObservables$.unsubscribe();

				//Retour des données
				subject.next(message.data);

				//Fin du traitement
				subject.complete();
			}
		}).onError({
			next: () => {
				//Annulation de la souscription
				messagingObservables$.unsubscribe();

				//Rejet de la recherche
				subject.error(null);

				//Fin du traitement
				subject.complete();
			}
		});

		return subject;
	}

	/**
	 * Affichage du véhicule
	 */
	goToVehicule(vehicule: any) {
		//Vérification de l'autorisation
		if (this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation')) {
			//Redirection vers le véhicule
			this.layoutService.goToByState('listeVehicules-loadVehicule',{
				routeParams: {
					idVehicule: vehicule?.idVehicule || 0
				},
				withGoBack: true
			});
		}
	}

	/**
	 * Vérification de la période du véhicule avec la liste de ses affectations
	 */
	public isPeriodeValidForVehicule(vehicule?: any,currentAffectation?: any): Observable<{ isDateEntreeValid: boolean,isDateSortieValid: boolean }> {
		//Vérification des dates
		const checkDates = ({ minDate,maxDate }) => {
			let isDateEntreeValid: boolean = true;
			let isDateSortieValid: boolean = true;

			//Validation des dates
			isDateEntreeValid = minDate ? (vehicule.dateEntree <= minDate) : true;
			isDateSortieValid = (minDate || maxDate) ? (!vehicule?.dateSortie || maxDate && vehicule?.dateSortie >= maxDate || currentAffectation?.dateFin === maxDate && (!currentAffectation?.dateFin || vehicule?.dateSortie <= currentAffectation?.dateFin)) : true;

			//Retour
			return { isDateEntreeValid,isDateSortieValid };
		}

		//Récupération des dates min/max des affectations du véhicule
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/findAffectationMinMaxDate/${vehicule.idVehicule}`,null).pipe(
			map((result: Result) => {
				let minDate: Date;
				let maxDate: Date;

				//Lecture des dates
				minDate = result?.data?.periode?.dateMin || null;
				maxDate = result?.data?.periode?.dateMax || null;

				//Vérification des dates
				return checkDates({ minDate,maxDate });
			})
		);
	}

	/**
	 * Récupération de la liste des types de Crit'Air
	 */
	public getListeTypesCritAir(): Array<{ code: string,libelle: string }> {
		//Liste des types de Crit'Air
		return this.listeTypesCritAir.map(code => ({
			code,
			libelle: this.translateService.instant('vehicule.critAir.' + code)
		}));
	}

	/**
	 * Récupération de la liste des types d'unité d'usage
	 */
	public getListeTypesUniteUsage(): Array<{ code: string,libelle: string }> {
		//Liste des types d'unité d'usage
		return this.listeTypesUniteUsage.map(code => ({
			code,
			libelle: this.translateService.instant('vehicule.uniteUsage.' + code)
		}));
	}

	/**
	 * Enregistrement d'une imputation
	 */
	public saveImputation(imputation: any): Observable<Result> {
		//Enregistrement de l'imputation
		return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveImputation`,imputation);
	}

	/**
	 * Chargement de la première imputation
	 */
	public getFirstImputation(vehicule: any): Observable<Result> {
		//Chargement de l'imputation
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/getFirstImputation/${vehicule.idVehicule}`,null);
	}

	/**
	 * Suppression de l'imputation
	 */
	public deleteImputation(imputation: any): Observable<Result> {
		//Suppression de l'imputation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Vehicule/deleteImputation/${imputation.idImputation}`);
	}

	/**
	 * Ouverture de la popup de sélection d'une imputation
	 */
	public showVehiculeImputationSelection(user: User): Observable<any> {
		let bsModalRef: BsModalRef<VehiculeImputationSelectionComponent>;

		//Affichage de la sélection de l'imputation
		bsModalRef = this.bsModalService.show(VehiculeImputationSelectionComponent,{
			initialState: {
				user
			}
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content?.result?.vehicule),
			filter(vehicule => !!vehicule)
		);
	}

	/**
	 * Récupération de la liste des types de règle de calcul de l'avantage en nature
	 */
	public getListeTypesCalculAvantageNature(): Array<{ code: string,libelle: string }> {
		//Liste des types de règle de calcul de l'avantage en nature
		return this.listeTypesCalculAvantageNature.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.typeCalculAvantageNature.${code || 'AUCUNE'}`)
		}));
	}
}