<form #entretienForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<entretien-content #entretienContent [entretien]="entretien" [vehicule]="entretien.vehicule" source="VEHICULE" [lastCompteur]="lastCompteur" [nbInterventions]="nbInterventions" [updateVehicule]="updateVehicule" (close)="close({ entretien: $event })"></entretien-content>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteEntretien(entretien,close);" *ngIf="deleteEntretien && entretien?.idEntretien && (TypeDroit.ENTRETIEN | right:'suppression') && (entretien.type == 'COURANT' || !entretien.previsionnel)">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="entretienContent.saveEntretien();" *ngIf="TypeDroit.ENTRETIEN | right:'creation'" [disabled]="entretienForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: entretienForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>