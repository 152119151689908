import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { FactureListItemComponent } from './facture-list-item.component';
import { FactureService } from './facture.service';
import { mapEntites } from './facture.entites';
import { TypeDroit } from 'src/app/domain/security/right';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './facture-list.component.html'
})
export class FactureListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,FactureListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,public dashboardService: DashboardService,public factureService: FactureService,public extractionService: ExtractionService,private layoutService: LayoutService,public connecteurService: ConnecteurService) {}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,FactureListItemComponent>({
			uri: `/controller/Facture/filtreFactures`,
			title: this.translateService.instant('facture.liste.title'),
			component: FactureListItemComponent,
			listeFilters: [{
				clef: '-date',
				type: TypeFilter.DATE
			},{
				clef: 'fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'type',
				type: TypeFilter.LONG,
				listeValues: this.factureService.getListeTypesFacture(),
				isListeValuesWithButton: true
			},{
				clef: 'montant',
				type: TypeFilter.DECIMAL
			},{
				clef: 'statut',
				type: TypeFilter.LONG,
				listeValues: this.factureService.getListeStatuts()
			},{
				clef: 'etat',
				type: TypeFilter.LONG,
				listeValues: this.factureService.getListeEtats()
			},{
				clef: 'isAnomalie',
				type: TypeFilter.BOOLEAN
			}],
			defaultOrder: '-date,-analysis.idFactureAnalysis,fournisseur.libelle,reference',
			getKeyFieldName: () => 'idFacture',
			listeActions: [this.rightService.hasRight(TypeDroit.FACTURE,'creation') && {
				icon: 'check_circle',
				messagingOptions: {
					entryPoint: 'controller/Facture/updateListeFactures/VALIDER'
				},
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.updateListeFactures(messagingOptions)
			},this.rightService.hasRight(TypeDroit.FACTURE,'creation') && {
				icon: 'cancel',
				messagingOptions: {
					entryPoint: 'controller/Facture/updateListeFactures/REJETER'
				},
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.updateListeFactures(messagingOptions)
			}].filter(i => !!i),
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Facture/loadFacture/0'))
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}

	/**
	 * Changement de statut d'une liste de factures
	 */
	private updateListeFactures(messagingOptions: MessagingOptions) {
		//Réalisation de l'action
		this.factureService.updateListeFactures(messagingOptions).subscribe({
			complete: () => this.liste.refresh()
		});
	}
}