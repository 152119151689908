<mat-radio-group [(ngModel)]="typeChoix" name="choix">
	<ng-container *ngIf="listeConnecteurs">
		<mat-radio-button value="FOURNISSEUR" *ngIf="typeChoixAvailable == 'BOTH'"><span translate>connecteur.import.selection.selectFormat.fournisseur</span></mat-radio-button>
		<div *ngIf="typeChoix == 'FOURNISSEUR'">
			<list-view [liste]="listeConnecteurs" *ngIf="listeConnecteurs && !selectedFournisseur"></list-view>

			<div class="row attachment-inline" *ngIf="listeConnecteurs && (selectedFournisseur || listeConnecteurs?.data?.content?.length)">
				<div class="col-md-4 attachment" [ngClass]="{ 'col-md-offset-4': selectedFournisseur }" *ngFor="let fournisseur of (selectedFournisseur ? [selectedFournisseur] : listeConnecteurs.data.content)">
					<import-format-fournisseur-list-item [data]="fournisseur" [selectedFournisseur]="selectedFournisseur" (onItemSelected)="onItemSelected($event)"></import-format-fournisseur-list-item>
				</div>
			</div>
			<div class="text-center p-10" *ngIf="!selectedFournisseur && !listeConnecteurs?.data?.content">
				<please-wait><h5 translate>liste.loading</h5></please-wait>
			</div>
			<div class="text-center p-10" *ngIf="!selectedFournisseur && listeConnecteurs?.data?.content?.length == 0">
				<h5><span translate>liste.empty</span></h5>
			</div>
		</div>
	</ng-container>
	<div *ngIf="selectedModule && typeChoixAvailable == 'BOTH'" class="separator">
		<span translate>common.ou</span>
	</div>
	<ng-container *ngIf="selectedModule && listeFormats">
		<mat-radio-button value="FORMAT" *ngIf="typeChoixAvailable == 'BOTH'"><span translate>connecteur.import.selection.selectFormat.importTemplate</span></mat-radio-button>
		<div *ngIf="typeChoix == 'FORMAT'">
			<list-view [liste]="listeFormats" *ngIf="listeFormats" class="p-t-0"></list-view>
		</div>
	</ng-container>
</mat-radio-group>