<form #tenantOffreEditForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>tenant.offre.item</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="true">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label translate>tenant.offre.contratCRM</label>
							<div>
								<autocomplete name="contrat" type="contratCRM" [(ngModel)]="lien.contratCRM"></autocomplete>
							</div>
						</div>
						<div class="form-group">
							<label translate>tenant.offre.item</label>
							<div>
								<autocomplete name="offre" type="offre" #offreField="ngModel" [(ngModel)]="lien.offre"></autocomplete>
							</div>
						</div>
						<div class="form-group" *ngIf="lien.idLien">
							<label translate>tenant.offre.dateResiliation</label>
							<div class="half">
								<input type="text" class="form-control" name="dateResiliation" #dateResiliation="ngModel" minDate="today" [(ngModel)]="lien.dateResiliation" datePicker [storeDate]="true" format="date"/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<button mat-button  [formDetectChanges]="{ doAction: close }">
		<span translate>actions.fermer</span>
	</button>
</form>