import { Component,Input,OnInit } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { OffreService } from './offre.service';
import { LicenceConsommationService } from 'src/app/components/licence/consommation/licence-consommation.service';

@Component({
	selector: 'offre-edit',
	templateUrl: './offre-edit.component.html'
})
export class OffreEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() offre: any;

	/** Suppression de l'objet **/
	@Input() deleteOffre: () => void;

	/** Enregistrement de l'objet **/
	@Input() saveOffre: (offre: any,close: Function) => void;

	/** Liste des types de licence **/
	public listeTypesLicence: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,public offreService: OffreService,private licenceConsommationService: LicenceConsommationService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de licence
		this.listeTypesLicence = this.licenceConsommationService.getListeTypesLicence();
	}
}