<form #entretienForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!entretien.isDisplayed">
		<div *ngIf="!entretien.idEntretien || isEntretienLoaded">
			<div class="tile-sub">
				<entretien-content #entretienContent [entretien]="entretien" source="ENTRETIEN" [lastCompteur]="lastCompteur" (close)="onContentClose($event)"></entretien-content>
			</div>
			<div class="tile-footer">
				<button mat-button *ngIf="user?.type != 'VIRTUAL'" [formDetectChanges]="{ doAction: close,isDirty: entretienForm.dirty }">
					<span translate>actions.fermer</span>
				</button>
				<button mat-stroked-button color="primary" (click)="entretienContent.deleteEntretien();" *ngIf="entretien.idEntretien && (null | right:'suppression') && (entretien.type == 'COURANT' || !entretien.previsionnel)">
					<span translate>actions.supprimer</span>
				</button>
				<button mat-raised-button color="primary" (click)="entretienContent.saveEntretien();" *ngIf="(null | right:'creation')" [disabled]="entretienForm.invalid || entretien.readOnly">
					<span translate>actions.enregistrer</span>
				</button>
			</div>
		</div>
		<div *ngIf="entretien.idEntretien && !isEntretienLoaded">
			<div class="tile-sub text-center">
				<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
			</div>
		</div>
	</div>
</form>