import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash-es';

import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConnecteurTypeService } from './connecteur-type.service';
import { ConnecteurService } from '../connecteur.service'
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	selector: 'connecteur-type',
	templateUrl: './connecteur-type.component.html'
})
export class ConnecteurTypeComponent implements OnInit {
	/** Elément courant **/
	@Input() connecteurType: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Récupération de la liste des types de connecteur **/
	public listeTypesConnecteur: Array<{ code: string,libelle: string }>;

	/** Récupération de la liste des types d'exécution **/
	public listeTypesExecution: Array<{ code: string,libelle: string }>;

	/** Récupération de la liste des types de visibilité **/
	public listeTypesVisibility: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private connecteurTypeService: ConnecteurTypeService,public rightService: RightService,private connecteurService: ConnecteurService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de connecteur
		this.listeTypesConnecteur = this.connecteurTypeService.getListeTypesConnecteur();

		//Récupération de la liste des types d'exécution
		this.listeTypesExecution = this.connecteurService.getListeTypesExecution();

		//Récupération de la liste des types de Visibilité
		this.listeTypesVisibility = this.connecteurTypeService.getListeTypesVisibility();

		//Copie du type de connecteur
		this.connecteurType = cloneDeep(this.connecteurType);
	}

	/**
	 * Enregistrement du type de connecteur
	 */
	saveConnecteurType() {
		//Enregistrement du type de connecteur
		this.connecteurTypeService.saveConnecteurType(this.connecteurType).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du type de connecteur
					this.onObjectChange.emit(result.data.connecteurType);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}