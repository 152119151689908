import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ServiceListItemComponent } from './service-list-item.component';
import { mapEntites } from './service.entite';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './service-list.component.html'
})
export class ServiceListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ServiceListItemComponent>;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,public connecteurService: ConnecteurService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ServiceListItemComponent>({
			uri: () => `/controller/Analytique/filtreServices`,
			title: this.translateService.instant('service.liste.title'),
			component: ServiceListItemComponent,
			extraBodyClass: 'liste_services_item',
			isTile: true,
			noZoomEffect: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'code',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			getKeyFieldName: () => 'idService',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Création d'un service
				this.layoutService.goToByUrl('/Analytique/loadService/0');
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}