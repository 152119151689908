<form #entretienForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<entretien-content #entretienContent [entretien]="entretien" source="CONDUCTEUR" [nbInterventions]="nbInterventions" (close)="onContentClose($event)" [liste]="liste"></entretien-content>
	<span class="pull-right">
		<button mat-raised-button color="primary" *ngIf="(TypeDroit.ENTRETIEN | right:'creation') && !entretien.readOnly" (click)="entretienContent.saveEntretien();" [disabled]="entretienForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: entretienForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>