<div class="container-fluid">
	<form #serviceForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
								<label translate>service.type.item</label>
								<div>
									<selectpicker name="type" #typeField="ngModel" [(ngModel)]="service.type" required [disabled]="service.idService || isForceParent">
										<mat-option *ngFor="let type of listeTypes" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>service.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="service.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>service.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="service.reference" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group">
								<label translate>service.code</label>
								<div>
									<input type="text" name="code" #code="ngModel" class="form-control" [(ngModel)]="service.code" maxlength="100"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeCalculAvantageNatureField.invalid }" *ngIf="service.type == 'SOCIETE'">
								<label translate>service.typeCalculAvantageNature</label>
								<div>
									<selectpicker name="typeCalculAvantageNature" #typeCalculAvantageNatureField="ngModel" [(ngModel)]="service.typeCalculAvantageNature">
										<mat-option *ngFor="let typeCalculAvantageNature of listeTypesCalculAvantageNature" [value]="typeCalculAvantageNature.code">{{ typeCalculAvantageNature.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>service.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="service.actif"><span translate>service.actif.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }" *ngIf="service.type == 'SOCIETE'">
								<label translate>service.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="service.pays" required [disabled]="service?.extension?.planComptable"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }" *ngIf="service.type == 'SERVICE'">
								<label translate>service.societeParente</label>
								<div>
									<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="service.societe" required [disabled]="service.idService || isForceParent"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': parent.invalid }" *ngIf="service.type == 'SERVICE'">
								<label translate>service.serviceParent</label>
								<div>
									<autocomplete name="parent" type="service" #parent="ngModel" [(ngModel)]="service.parent" [filter]="{ idSociete: service.societe?.idService }" [disabled]="!service.societe || service.idService || isForceParent"></autocomplete>
								</div>
							</div>
						</div>
					</div>
					<div class="p-t-20">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label translate>service.attribut1</label>
									<div>
										<input type="text" name="attribut1" #attribut1="ngModel" class="form-control" [(ngModel)]="service.attribut1" maxlength="300"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label translate>service.attribut2</label>
									<div>
										<input type="text" name="attribut2" #attribut2="ngModel" class="form-control" [(ngModel)]="service.attribut2" maxlength="300"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label translate>service.attribut3</label>
									<div>
										<input type="text" name="attribut3" #attribut3="ngModel" class="form-control" [(ngModel)]="service.attribut3" maxlength="300"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label translate>service.attribut4</label>
									<div>
										<input type="text" name="attribut4" #attribut4="ngModel" class="form-control" [(ngModel)]="service.attribut4" maxlength="300"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>service.responsable</h4>
						</div>
					</div>
					<div class="row p-t-20">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>service.userN1</label>
								<div>
									<autocomplete name="userN1" type="user" #userN1="ngModel" [(ngModel)]="service.userN1"></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>service.userN2</label>
								<div>
									<autocomplete name="userN2" type="user" #userN2="ngModel" [(ngModel)]="service.userN2"></autocomplete>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>service.userN3</label>
								<div>
									<autocomplete name="userN3" type="user" #userN3="ngModel" [(ngModel)]="service.userN3"></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>service.userN4</label>
								<div>
									<autocomplete name="userN4" type="user" #userN4="ngModel" [(ngModel)]="service.userN4"></autocomplete>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteService();" *ngIf="service?.idService && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveService();" *ngIf="null | right:'creation'" [disabled]="serviceForm.invalid">
				<span translate>{{'actions.' + (service?.idService ? 'enregistrer' : 'continuer')}}</span>
			</button>
		</span>
		<button mat-button *ngIf="service?.idService" [formDetectChanges]="{ doAction: close,isDirty: serviceForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>