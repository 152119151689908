import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDrag,CdkDragDrop,moveItemInArray,transferArrayItem } from '@angular/cdk/drag-drop';

import { ImportTemplateService } from './import-template.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeModule } from 'src/app/domain/connecteur/type-module';

@Component({
	selector: 'import-template',
	templateUrl: './import-template.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ImportTemplateComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() importTemplate: any;

	/** Type de module d'import **/
	@Input() typeModule: TypeModule;

	/** Format **/
	@Input() format: any;

	/** Liste des champs disponibles **/
	@Input() listeAvailableFields: Array<any>;

	/** Liste des champs sélectionnables **/
	@Input() listeFields: Array<any> = [];

	/** Liste des formats */
	public listeTypesFormat: Array<{ code: string,libelle: string }>;

	/** Résultat **/
	result: { importTemplate: any };

	/**
	 * Constructeur
	 */
	constructor(private importTemplateService: ImportTemplateService,private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ImportTemplateComponent>,private toastrService: ToastrService,private translateService: TranslateService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
		this.saveImportTemplate = this.saveImportTemplate.bind(this);
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de format
		this.listeTypesFormat = this.importTemplateService.getListeTypesFormat();

		//Parcours des champs disponibles
		this.listeAvailableFields.forEach(field => {
			let fieldType: string;

			//Récupération du type de champ
			fieldType = field.type.split('.').pop(-1);

			//Définition de la description du type de champ
			field.typeDescription = this.translateService.instant('connecteur.import.template.typeField.'+(['String','Date','Double','Integer','Boolean','Long'].includes(fieldType) ? fieldType.toUpperCase() : 'ENUM'));
		});

		//Récupération des champs
		this.listeFields = this.listeAvailableFields.filter(field => {
			let foundField: any;

			//Vérification de la présence du champ
			foundField = this.importTemplate.listeFields.find(f => f.name === field.name);

			//Vérification de la présence du champ
			if (foundField) {
				//Mise à jour des informations
				Object.assign(foundField,field);

				//Suppression de la liste
				return false;
			} else if (field?.required) {
				//Ajout à la liste
				this.importTemplate.listeFields.push(field);

				//Suppression de la liste
				return false;
			} else {
				//Ajout à la liste
				return true;
			}
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement du template d'import
	 */
	saveImportTemplate() {
		//Définition du chemin
		this.importTemplate.typeModule = this.typeModule;
		this.importTemplate.path = this.format.path;
		this.importTemplate.method = this.format.method;

		//Définition de la position
		this.importTemplate.listeFields.forEach((field,index) => {
			//Définition de la position
			field.position = index;
		});

		//Enregistrement de l'objet
		this.importTemplateService.saveImportTemplate(this.importTemplate).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du résultat
					this.result = {
						importTemplate: result.data.importTemplate
					};

					//Fermeture du formulaire
					this.bsModalRef.hide();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Déplacement ou transfert d'un champ
	 */
	onDroppedField(event: CdkDragDrop<number[]>) {
		//Vérification de la source
		if (event.previousContainer === event.container)
			//Déplacement au sein de la liste
			moveItemInArray(event.container.data,event.previousIndex,event.currentIndex);
		else
			//Transfert entre les listes
			transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
	}

	/**
	 * Vérification du droit de transfert pour les champs disponibles
	 */
	onListFieldAvailableEnterPredicate(item: CdkDrag<number>) {
		//Vérification de l'autorisation de transfert
		return !(item.data as any)?.required;
	}

	/**
	 * Vérification du droit de transfert pour les champs utilisés
	 */
	onListFieldUsedEnterPredicate() {
		//Vérification de l'autorisation de transfert
		return true;
	}

	/**
	 * Récupération de la description du champ
	 */
	getFieldDescription(field: any): string {
		let format: string;

		//Vérification du type
		if (field.type === 'java.util.Date')
			//Définition du format
			format = this.translateService.instant('connecteur.import.template.dateFormat');
		else if (field.listeEnumValues?.length)
			//Définition du format
			format = field.listeEnumValues.join(', ');

		//Libellé du champ
		return field.description + ' (' + field.typeDescription + (format?.length ? ' : ' + format : '') + ')';
	}
}