<div class="pull-left lv-avatar">{{ data.type.libelle.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/Connecteur/loadConnecteur',data.idConnecteur]"><span>{{ data.type.libelle + ' (' + data.type.reference + (data.type.typeConnecteur == 'INTERFACE' && data.version ? ' - ' + ('connecteur.version' | translate) + ' ' + data.version : '') + ')' }}</span></a>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data?.type?.fournisseurInterface?.typeFlux"><span translate>fournisseur.formatInterface.typeFlux.item</span>&#160;:&#160;<span [translate]="'fournisseur.formatInterface.typeFlux.' + data?.type?.fournisseurInterface?.typeFlux"></span></li>
		<li><span translate>connecteur.frequence</span>&#160;:&#160;<span *ngIf="data.scheduler" [translate]="'scheduler.frequence.'+data.scheduler.type"></span><span *ngIf="!data.scheduler && data.type.typeConnecteur == 'API'" translate>connecteur.frequenceManuelle</span><span *ngIf="!data.scheduler && data.type.typeConnecteur == 'INTERFACE'" translate>connecteur.frequenceReceptionFichier</span></li>
		<li *ngIf="data.dateLastExecution"><span translate>connecteur.derniereExecution</span>&#160;:&#160;<span>{{ data.dateLastExecution | date:'short' }}</span></li>
		<li><span translate>connecteur.typeExecution.item</span>&#160;:&#160;<span [translate]="'connecteur.typeExecution.' + data.typeExecution"></span></li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif && data?.type?.actif && (!data?.type?.fournisseurInterface || data?.type?.fournisseurInterface?.actif) ? 'oui' : 'non')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && !data.dateLastExecution"><a [routerLink]="[]" (click)="deleteConnecteur();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
			<li *ngIf="data.type.typeConnecteur == 'API'"><a [routerLink]="[]" (click)="login()"><span translate>actions.tester</span></a></li>
			<li *ngIf="data.type.typeConnecteur == 'API'"><a [routerLink]="[]" (click)="execute()"><span translate>actions.executer</span></a></li>
		</ul>
	</div>
</div>