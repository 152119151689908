import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TemplateMailService } from 'src/app/share/components/template-mail/template-mail.service';
import { ReferentielEntiteListItem } from 'src/app/domain/referentiel/referentiel-entite-list-item';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { EntiteService } from 'src/app/share/components/entite/entite.service';

@Component({
	selector: 'template-mail-list-item',
	templateUrl: './template-mail-list-item.component.html'
})
export class TemplateMailListItemComponent extends ReferentielEntiteListItem<any> implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.template.TemplateMail';

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,translateService: TranslateService,toastrService: ToastrService,private historyService: HistoryService,private templateMailService: TemplateMailService,rightService: RightService,referentielService: ReferentielService,private layoutService: LayoutService,public entiteService: EntiteService) {
		//Héritage
		super(referentielService,toastrService,translateService,rightService);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Héritage
		super.ngOnInit();
	}

	/**
	 * Suppression de l'objet
	 */
	deleteTemplateMail() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.templateMailService.deleteTemplateMail(this.liste.selectedSelector,this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Vérification du type de référentiel et de la suppression d'un template importé
				if (this.liste.selectedSelector == TypeReferentiel.LOCAL && this.data.templateMailOrigine)
					//Rafraichissement du compteur des entités standard
					this.refreshReferentiel();

				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Rechargement de la page
				this.layoutService.reloadSameUrl();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.template.TemplateMail',this.data.idTemplateMail);
	}

	/**
	 * Duplication du template de mail
	 */
	duplicateTemplateMail() {
		//Duplication du template de mail
		this.templateMailService.duplicateTemplateMail(this.data).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Redirection vers le template créé
					this.layoutService.goToByState('listeTemplateMails-templateMail',{
						routeParams: {
							typeReferentiel: this.liste.selectedSelector,
							idTemplateMail: result.data.templateMail.idTemplateMail
						},
						withGoBack: true
					});
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}
