import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { filter,first,map } from 'rxjs';
import moment from 'moment';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { mapEntites } from './releve-compteur.entites';
import { ReleveCompteurListItemComponent } from './releve-compteur-list-item.component';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';
import { ReleveCompteurService } from './releve-compteur.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './releve-compteur-list.component.html'
})
export class ReleveCompteurListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ReleveCompteurListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private translateService: TranslateService,private toastrService: ToastrService,private rightService: RightService,public dashboardService: DashboardService,public extractionService: ExtractionService,private releveCompteurService: ReleveCompteurService,public connecteurService: ConnecteurService) {

	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(filter(user => !!user),first()).subscribe({
			next: user => {
				//Mise à jour de l'utilisateur
				this.user = user;

				//Définition de la liste
				this.liste = new ListView<any,ReleveCompteurListItemComponent>({
					uri: `/controller/VehiculeCompteur/filtreCompteurs`,
					title: this.translateService.instant('vehicule.releveCompteur.liste.title'),
					component: ReleveCompteurListItemComponent,
					isTile: true,
					listeFilters: [{
						clef: 'vehicule',
						autocomplete: {
							type: 'vehicule'
						},
						type: TypeFilter.AUTOCOMPLETE,
						isDefault: true
					},{
						clef: '*equipement.reference',
						mapEncryptedFields: {
							'*equipement.reference': '*equipement.referenceChiffree'
						},
						isDefault: true
					},{
						clef: '*fournisseur',
						title: this.translateService.instant('vehicule.releveCompteur.search.fournisseur'),
						autocomplete: {
							type: 'fournisseur'
						},
						type: TypeFilter.AUTOCOMPLETE,
						isDefault: true
					},{
						clef: '*userSource',
						title: this.translateService.instant('vehicule.releveCompteur.search.userSource'),
						autocomplete: {
							type: 'user'
						},
						type: TypeFilter.AUTOCOMPLETE,
						isDefault: true
					},{
						clef: 'dateReleve',
						title: this.translateService.instant('search.date'),
						type: TypeFilter.DATE
					},{
						clef: 'releveCompteur',
						title: this.translateService.instant('vehicule.releveCompteur.search.releveCompteur'),
						type: TypeFilter.DECIMAL
					},{
						clef: 'releveHoraire',
						title: this.translateService.instant('vehicule.releveCompteur.search.releveHoraire'),
						type: TypeFilter.DECIMAL
					},{
						clef: 'extension.userAffecte',
						title: this.translateService.instant('vehicule.releveCompteur.search.userAffecte'),
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'user'
						}
					},{
						clef: 'isValeurReference',
						title: this.translateService.instant('vehicule.releveCompteur.search.valeurReference'),
						type: TypeFilter.BOOLEAN
					},{
						clef: 'isValeurVerrouillee',
						title: this.translateService.instant('vehicule.releveCompteur.search.valeurVerrouillee'),
						type: TypeFilter.BOOLEAN
					}],
					extraOptions: {
						user: this.user
					},
					listeActions: [this.rightService.hasRight(null,'suppression') && {
						icon: 'delete',
						messagingOptions: {
							entryPoint: 'controller/VehiculeCompteur/deleteAllCompteurs'
						},
						onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.deleteAllCompteurs(messagingOptions)
					}].filter(i => !!i),
					noSearch: this.user.type == 'VIRTUAL',
					isLoadingDisabled: this.user.type == 'VIRTUAL',
					defaultOrder: '-dateReleve,vehicule.reference',
					hasMainAction: () => this.user?.type != 'VIRTUAL' && this.rightService.hasRight(null,'creation'),
					doMainAction: (data: any = {}) => {
						//Ajout d'un nouvel élément
						!this.liste.data?.content?.some(i => !i.idCompteur) && this.liste.addItem({
							isDisplayed: true,
							valeurReference: true,
							typeSourceReleve: 'COLLABORATEUR',
							readOnly: false,
							...data
						});
					},
					getKeyFieldName: () => 'idCompteur'
				});

				//Vérification du type d'utilisateur
				if (this.user?.type != 'VIRTUAL') {
					//Vérification de la présence d'un dashboard
					this.dashboardService.hasDashboard().subscribe({
						next: (hasDashboard: boolean) => {
							//Définition de la présence d'un dashboard
							this.hasDashboard = hasDashboard;
						}
					});

					//Vérification de la présence d'imports
					this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
						next: (isImportAvailable: boolean) => {
							//Définition de la présence d'imports
							this.hasImports = isImportAvailable;
						}
					});
				} else if (this.user?.externalAction?.idEntite) {
					//Chargement du dernier compteur du véhicule
					this.releveCompteurService.loadVehiculeAndLastCompteur(this.user.externalAction.idEntite).pipe(first()).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result.codeErreur == TypeCodeErreur.NO_ERROR && result.data?.vehicule) {
								//Définition de la liste
								this.liste.data = {
									content: [Object.assign({
										vehicule: result.data.vehicule,
										valeurReference: false,
										typeSourceReleve: 'COLLABORATEUR',
										dateReleve: moment.utc().startOf('day').toDate(),
										releveCompteur: null,
										lastCompteur: result.data.lastCompteur
									},{
										isDisplayed: true
									})]
								};
							} else if (result.codeErreur == TypeCodeErreur.DROIT) {
								//Affichage du message d'erreur
								this.toastrService.error(this.translateService.instant('actions.acces.accesNonAutorise'));

								//Définition de l'erreur
								this.liste.data = {
									content: [Object.assign({
										errorLibelle: this.translateService.instant('actions.acces.accesNonAutorise')
									},{
										isDisplayed: true
									})]
								};
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Suppressions des compteurs
	 */
	deleteAllCompteurs(messagingOptions: MessagingOptions) {
		//Suppression de la sélection
		this.releveCompteurService.deleteAllForSelection(messagingOptions).subscribe({
			complete: () => this.liste.refresh()
		});
	}
}