import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { TenantOffreEditComponent } from './tenant-offre-edit.component';

@Component({
	templateUrl: './tenant-offre-list-item.component.html'
})
export class TenantOffreListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private pageContentService: PageContentService) {
		//Héritage
		super();
	}

	/**
	 * Affichage de l'offre
	 */
	showOffre(data: any) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TenantOffreEditComponent,{
			data: {
				lien: data
			}
		},'sub').subscribe();
	}
}