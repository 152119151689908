<div class="modal-header">
	<h4 class="modal-title" translate>grilleFluidite.detail.title</h4>
</div>
<div class="modal-body grille-fluidite-detail">
	<div class="alert alert-warning" *ngIf="options.distanceVehicule && distance < options.distanceVehicule || options.dureeContrat && duree < options.dureeContrat">
		<icon name="info"></icon>
		<div>
			<strong translate>grilleFluidite.detail.alerte.depassementDistanceDuree.title</strong>
			<div translate>grilleFluidite.detail.alerte.depassementDistanceDuree.message</div>
		</div>
	</div>
	<div class="alert alert-warning" [ngClass]="detail.level || 'none'" *ngIf="distanceMensuelleToHighlight && !(options.distanceVehicule && distance < options.distanceVehicule || options.dureeContrat && duree < options.dureeContrat)">
		<icon name="info"></icon>
		<div>
			<strong *ngIf="detail.level == 'high'" translate>grilleFluidite.detail.alerte.ecart.high.title</strong>
			<strong *ngIf="detail.level == 'medium' && ecartDistanceToHighlight > 0" translate>grilleFluidite.detail.alerte.ecart.medium.negatif.title</strong>
			<strong *ngIf="detail.level == 'medium' && ecartDistanceToHighlight <= 0" translate>grilleFluidite.detail.alerte.ecart.medium.positif.title</strong>
			<strong *ngIf="detail.level == 'low' && ecartDistanceToHighlight > 0" translate>grilleFluidite.detail.alerte.ecart.low.negatif.title</strong>
			<strong *ngIf="detail.level == 'low' && ecartDistanceToHighlight <= 0" translate>grilleFluidite.detail.alerte.ecart.medium.positif.title</strong>
			<strong *ngIf="!detail.level && ecartDistanceToHighlight > 0" translate>grilleFluidite.detail.alerte.ecart.none.negatif.title</strong>
			<strong *ngIf="!detail.level && ecartDistanceToHighlight <= 0" translate>grilleFluidite.detail.alerte.ecart.none.positif.title</strong>
			<div *ngIf="detail.level == 'high'" translate>grilleFluidite.detail.alerte.ecart.high.message</div>
			<div *ngIf="detail.level == 'medium' && ecartDistanceToHighlight > 0" translate>grilleFluidite.detail.alerte.ecart.medium.negatif.message</div>
			<div *ngIf="detail.level == 'medium' && ecartDistanceToHighlight <= 0" translate>grilleFluidite.detail.alerte.ecart.medium.positif.message</div>
			<div *ngIf="detail.level == 'low' && ecartDistanceToHighlight > 0" translate>grilleFluidite.detail.alerte.ecart.low.negatif.message</div>
			<div *ngIf="detail.level == 'low' && ecartDistanceToHighlight <= 0" translate>grilleFluidite.detail.alerte.ecart.low.positif.message</div>
			<div *ngIf="!detail.level && ecartDistanceToHighlight > 0" translate>grilleFluidite.detail.alerte.ecart.none.negatif.message</div>
			<div *ngIf="!detail.level && ecartDistanceToHighlight <= 0" translate>grilleFluidite.detail.alerte.ecart.none.positif.message</div>
		</div>
	</div>
	<div class="row" *ngIf="isWithVariation">
		<div class="col-xs-12">
			<ul class="pull-right actions m-t-5">
				<li>
					<a [routerLink]="[]" (click)="isDetailDisplayed = !isDetailDisplayed">
						<icon [name]="!isDetailDisplayed ? 'chevron_right' : 'expand_more'"></icon>
					</a>
				</li>
			</ul>
			<h4 class="clickable" (click)="isDetailDisplayed = !isDetailDisplayed" translate>grilleFluidite.detail.generalites</h4>
		</div>
	</div>
	<div [collapse]="!isDetailDisplayed">
		<table class="table">
			<thead *ngIf="options.loiRoulage">
				<tr>
					<td></td>
					<td class="text-right current-loi-roulage" translate>grilleFluidite.detail.actuelle</td>
					<td class="text-right" translate>grilleFluidite.detail.selectionnee</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td style="border-top: none;" translate>grilleFluidite.detail.duree</td>
					<td class="text-right current-loi-roulage" style="border-top: none;" *ngIf="options.loiRoulage">
						<span>{{ options.loiRoulage.duree | number:'.0-0' }}</span>
					</td>
					<td class="text-right" style="border-top: none;">
						<span>{{ detail.duree | number:'.0-0' }}</span>
					</td>
				</tr>
				<tr>
					<td translate>grilleFluidite.detail.distance</td>
					<td class="text-right current-loi-roulage" *ngIf="options.loiRoulage">
						<span>{{ options.loiRoulage.distance | number:'.0-0' }}</span>
					</td>
					<td class="text-right">
						<span>{{ detail.distance | number:'.0-0' }}</span>
					</td>
				</tr>
				<tr>
					<td translate>grilleFluidite.detail.loyer</td>
					<td class="text-right current-loi-roulage" *ngIf="options.loiRoulage">
						<span>{{ options.loiRoulage.loyerMensuel | number:'.2-2' }}</span>
					</td>
					<td class="text-right bold">
						<span>{{ detail.loyer | number:'.2-2' }}</span>
					</td>
				</tr>
				<tr>
					<td translate>grilleFluidite.detail.prixRevientKilometrique</td>
					<td class="text-right current-loi-roulage" *ngIf="options.loiRoulage">
						<span>{{ options.loiRoulage.coutDistance | number:'.3-3' }}</span>
					</td>
					<td class="text-right">
						<span>{{ detail.coutKilometrique | number:'.3-3' }}</span>
					</td>
				</tr>
				<tr>
					<td translate>grilleFluidite.detail.kilometrageMensuel</td>
					<td class="text-right current-loi-roulage" *ngIf="options.loiRoulage">
						<span>{{ (options.loiRoulage.distance / options.loiRoulage.duree) | number:'.0-0' }}</span>
					</td>
					<td class="text-right">
						<span>{{ detail.distanceMensuelle | number:'.0-0' }}</span>
					</td>
				</tr>
				<tr>
					<td translate>grilleFluidite.detail.coutTotal</td>
					<td class="text-right current-loi-roulage" *ngIf="options.loiRoulage">
						<span>{{ options.loiRoulage.loyerTotal | number:'.2-2' }}</span>
					</td>
					<td class="text-right bold">
						<span>{{ detail.coutTotal | number:'.2-2' }}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="isWithVariation">
		<div class="row">
			<div class="col-xs-12">
				<ul class="pull-right actions m-t-5">
					<li>
						<a [routerLink]="[]" (click)="isVariationDisplayed = !isVariationDisplayed">
							<icon [name]="!isVariationDisplayed ? 'chevron_right' : 'expand_more'"></icon>
						</a>
					</li>
				</ul>
				<h4 class="clickable" (click)="isVariationDisplayed = !isVariationDisplayed" translate>grilleFluidite.detail.variation</h4>
			</div>
		</div>
		<div [collapse]="!isVariationDisplayed">
			<table class="table">
				<tbody>
					<tr>
						<td style="border-top: none;" translate>grilleFluidite.detail.nouvelleDureeRestante</td>
						<td class="text-right" style="border-top: none;">
							<span>{{ detail.nombreEcheancesRestantes | number:'.0-0' }}</span>
						</td>
					</tr>
					<tr>
						<td translate>grilleFluidite.detail.montantDejaPaye</td>
						<td class="text-right">
							<span>{{ detail.montantDejaPaye | number:'.2-2' }}</span>
						</td>
					</tr>
					<tr>
						<td translate>grilleFluidite.detail.coutRestant</td>
						<td class="text-right">
							<span>{{ detail.coutRestant | number:'.2-2' }}</span>
						</td>
					</tr>
					<tr>
						<td translate>grilleFluidite.detail.regularisation</td>
						<td class="text-right">
							<span>{{ detail.regularisation | number:'.2-2' }}</span>
						</td>
					</tr>
					<tr>
						<td translate>grilleFluidite.detail.coutTotalEstime</td>
						<td class="text-right">
							<span>{{ detail.coutTotalEstime | number:'.2-2' }}</span>
						</td>
					</tr>
					<tr>
						<td translate>grilleFluidite.detail.ecartMontant</td>
						<td class="text-right">
							<span>{{ detail.ecartMontant | number:'.2-2' }}</span>
						</td>
					</tr>
					<tr>
						<td translate>grilleFluidite.detail.ecart</td>
						<td class="text-right">
							<span>{{ 100 * detail.ecart | number:'.0-0' }}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" (click)="selectDetail()" *ngIf="options.canAddDetail && !(options.distanceVehicule && distance < options.distanceVehicule || options.dureeContrat && duree < options.dureeContrat)">
		<span translate>actions.ajouter</span>
	</button>
</div>