import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ListView } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { SocieteListItemComponent } from './societe-list-item.component';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	selector: 'societe-list',
	templateUrl: './societe-list.component.html'
})
export class SocieteListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,SocieteListItemComponent>;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,public connecteurService: ConnecteurService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,SocieteListItemComponent>({
			uri: `/controller/Societe/filtreSocietes`,
			title: this.translateService.instant('societe.liste.title'),
			component: SocieteListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'siren',
				isDefault: true
			},{
				clef: 'siret',
				isDefault: true
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(s => !s.idSociete) && this.liste.addItem({
					isDisplayed: true
				});
			})
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}