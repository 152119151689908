<form #designationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="lv-actions actions pull-right" *ngIf="!isEdition && (demandeIdentification.statut == 'A_TRANSMETTRE' || demandeIdentification.statut == 'ANOMALIE')">
		<div class="card-header">
			<ul class="actions">
				<li *ngIf="null | right:'creation'">
					<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
					<ul class="dropdown-menu dropdown-menu-right">
						<li><a [routerLink]="[]" (click)="editDesignation()" translate>actions.modifier</a></li>
						<li><a [routerLink]="[]" (click)="deleteDesignation()" *ngIf="demandeIdentification.designation.idDesignation || demandeIdentification.typeDesignation == 'ENVOI_PAPIER'" translate>actions.supprimer</a></li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
	<fieldset [disabled]="!(null | right:'creation') || !isEdition">
		<div class="card" [ngClass]="{ 'full': isEdition }">
			<div class="card-body card-padding">
				<div class="alert alert-danger" *ngIf="demandeIdentification.motifRejet && demandeIdentification.statut =='ANOMALIE' && (demandeIdentification.typeDesignation == 'CONDUCTEUR' || demandeIdentification.typeDesignation == 'SOCIETE') && isEdition">
					<icon name="warning"></icon>
					<div>
						<strong translate>demandeIdentification.alerte.rejet.title</strong>
						<div>{{ demandeIdentification.motifRejet }}</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': designationForm.controls['typeDesignation']?.invalid }">
							<label translate>demandeIdentification.designation.typeDesignation</label>
							<div *ngIf="isEdition">
								<selectpicker name="typeDesignation" #typeDesignation="ngModel" [(ngModel)]="demandeIdentification.typeDesignation" (ngModelChange)="onTypeDesignationChange()" required>
									<mat-option *ngFor="let typeDesignation of listeTypesDesignation" [value]="typeDesignation.code">{{ typeDesignation.libelle }}</mat-option>
								</selectpicker>
							</div>
							<div *ngIf="!isEdition">
								<span [translate]="'demandeIdentification.typeDesignation.'+demandeIdentification.typeDesignation"></span>
							</div>
						</div>
						<div *ngIf="demandeIdentification.typeDesignation == 'CONDUCTEUR'">
							<div class="form-group" [ngClass]="{ 'has-error': designationForm.controls['user']?.invalid }" *ngIf="!demandeIdentification.designation.dateAnonymisation">
								<label translate>demandeIdentification.designation.conducteur</label>
								<div *ngIf="isEdition">
									<div class="input-group">
										<autocomplete name="user" type="conducteur" #user="ngModel" [(ngModel)]="demandeIdentification.designation.user" (onSelect)="onConducteurChange()" required></autocomplete>
										<div class="input-group-addon" *ngIf="demandeIdentification.extension?.vehiculeImmobilise">
											<icon name="warning" class="icon-hc-2x" [tooltip]="'demandeIdentification.designation.tooltip.vehiculeImmobilise' | translate"></icon>
										</div>
										<div class="input-group-addon">
											<icon name="replay_30" class="icon-hc-2x clickable" (click)="showListeAffectations()" [tooltip]="'demandeIdentification.designation.tooltip.historiqueAffectations' | translate"></icon>
										</div>
									</div>
								</div>
								<div *ngIf="!isEdition">
									<span>{{ demandeIdentification.designation.user | display:'user' }}</span>
								</div>
							</div>
							<div class="form-group">
								<label>
									<span translate>demandeIdentification.designation.civilite.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'demandeIdentification.designation.civilite.description' | translate"></icon>
								</label>
								<div class="half">
									<selectpicker name="civilite" #civilite="ngModel" [(ngModel)]="demandeIdentification.designation.civilite">
										<mat-option *ngFor="let civilite of listeTypesCivilite" [value]="civilite"><span [translate]="'user.genre.' + civilite"></span></mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group">
								<label>
									<span translate>demandeIdentification.designation.dateNaissance.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'demandeIdentification.designation.dateNaissance.description' | translate"></icon>
								</label>
								<div class="half">
									<input type="text" class="form-control" name="dateNaissance" #dateNaissance="ngModel" [(ngModel)]="demandeIdentification.designation.dateNaissance" datePicker format="date" maxDate="today"/>
								</div>
							</div>
							<div class="form-group">
								<label>
									<span translate>demandeIdentification.designation.lieuNaissance.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'demandeIdentification.designation.lieuNaissance.description' | translate"></icon>
								</label>
								<div>
									<adresse name="lieuNaissance" #lieuNaissance="ngModel" [(ngModel)]="demandeIdentification.designation.lieuNaissance" [disabled]="!isEdition" searchMode="VILLE"></adresse>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': adresse.invalid }">
								<label translate>demandeIdentification.designation.adresse</label>
								<div>
									<adresse name="adresse" #adresse="ngModel" [(ngModel)]="demandeIdentification.designation.adresse" [disabled]="!isEdition" required></adresse>
								</div>
							</div>
							<div class="form-group">
								<label translate>demandeIdentification.designation.permisConduire</label>
								<div>
									<a [routerLink]="[]" (click)="showPermisConduire()">
										<span *ngIf="demandeIdentification.designation.permisConduire?.reference">{{ demandeIdentification.designation.permisConduire.reference }}</span>
										<span *ngIf="!demandeIdentification.designation.permisConduire?.reference" translate>common.aRenseigner</span>
									</a>
								</div>
							</div>

							<div class="form-group">
								<label>
									<span translate>demandeIdentification.designation.notification.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'demandeIdentification.designation.notification.description' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="notification" [(ngModel)]="demandeIdentification.designation.notification"><span translate>demandeIdentification.designation.notification.message</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" *ngIf="demandeIdentification.designation.notification && demandeIdentification.designation.user">
								<label translate>demandeIdentification.designation.email</label>
								<div>
									<span>{{ demandeIdentification.designation.user.email }}</span>
								</div>
							</div>
							<div class="form-group">
								<label>
									<span translate>demandeIdentification.designation.transmissionMail.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'demandeIdentification.designation.transmissionMail.description' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="transmissionMail" [(ngModel)]="demandeIdentification.designation.transmissionMail"><span translate>demandeIdentification.designation.transmissionMail.message</span></mat-checkbox>
								</div>
							</div>
						</div>
						<div *ngIf="demandeIdentification.typeDesignation == 'VEHICULE_VENDU'">
							<div class="form-group" [ngClass]="{ 'has-error': dateVente.invalid }">
								<label translate>demandeIdentification.designation.dateVente</label>
								<div>
									<input type="text" class="form-control" name="dateVente" #dateVente="ngModel" [(ngModel)]="demandeIdentification.designation.dateVente" datePicker [storeDate]="true" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': nomAcheteur.invalid }">
								<label translate>demandeIdentification.designation.nomAcheteur</label>
								<div>
									<div class="input-group">
										<input type="text" class="form-control" name="nomAcheteur" #nomAcheteur="ngModel" [(ngModel)]="demandeIdentification.designation.nomAcheteur" required maxlength="20" pattern="^(?![<&]).*"/>
										<div class="input-group-addon">
											<icon name="info_outline" [tooltip]="'demandeIdentification.designation.tooltip.interdiction' | translate"></icon>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="demandeIdentification.typeDesignation == 'VEHICULE_VOLE'">
							<div class="form-group" [ngClass]="{ 'has-error': dateVol.invalid }">
								<label translate>demandeIdentification.designation.dateVol</label>
								<div>
									<input type="text" class="form-control" name="dateVol" #dateVol="ngModel" [(ngModel)]="demandeIdentification.designation.dateVol" datePicker [storeDate]="true" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': numeroDeclaration.invalid }">
								<label translate>demandeIdentification.designation.numeroDeclaration</label>
								<div>
									<div class="input-group">
										<input type="text" class="form-control" name="numeroDeclaration" #numeroDeclaration="ngModel" [(ngModel)]="demandeIdentification.designation.numeroDeclaration" required maxlength="20" pattern="^(?![<&]).*"/>
										<div class="input-group-addon">
											<icon name="info_outline" [tooltip]="'demandeIdentification.designation.tooltip.interdiction' | translate"></icon>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="demandeIdentification.typeDesignation == 'VEHICULE_RENDU'">
							<div class="form-group" [ngClass]="{ 'has-error': dateSortie.invalid }">
								<label translate>demandeIdentification.designation.dateSortie</label>
								<div>
									<input type="text" class="form-control" name="dateSortie" #dateSortie="ngModel" [(ngModel)]="demandeIdentification.designation.dateSortie" datePicker [storeDate]="true" required/>
								</div>
							</div>
						</div>
						<div *ngIf="demandeIdentification.typeDesignation == 'SOCIETE'">
							<div class="form-group" [ngClass]="{ 'has-error': designationForm.controls['societe']?.invalid }">
								<label translate>demandeIdentification.designation.societe</label>
								<div *ngIf="isEdition">
									<autocomplete name="societe" type="societeReferentiel" [(ngModel)]="demandeIdentification.designation.societe" required></autocomplete>
								</div>
								<div *ngIf="!isEdition">
									<span>{{ demandeIdentification.designation.societe.libelle }}</span>
								</div>
							</div>
							<ng-container *ngIf="demandeIdentification.designation.societe">
								<div class="form-group">
									<label translate>demandeIdentification.designation.siren</label>
									<div>
										<span>{{ demandeIdentification.designation.societe.siren }}</span>
									</div>
								</div>
								<div class="form-group">
									<label translate>demandeIdentification.designation.siret</label>
									<div>
										<span>{{ demandeIdentification.designation.societe.siret }}</span>
									</div>
								</div>
								<div class="form-group">
									<label translate>demandeIdentification.designation.adresse</label>
									<div>
										<adresse name="adresse" [(ngModel)]="demandeIdentification.designation.societe.adresse" [disabled]="true"></adresse>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
	<ng-container *ngIf="isEdition">
		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveDesignation()" [disabled]="designationForm.invalid" *ngIf="null | right:'creation'">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: designationForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</ng-container>
</form>