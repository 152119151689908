import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'tenant-offre-edit',
	templateUrl: './tenant-offre-edit.component.html'
})
export class TenantOffreEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() lien: any;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();

		//Binding des méthodes
		this.close = this.close?.bind?.(this);
	}
}