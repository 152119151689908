import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ConnecteurService } from './connecteur.service';
import { ImportModule } from './import/import.module';
import { ConnecteurEffects } from './connecteur.effects';

@NgModule({
	imports: [CoreComponentsModule,ImportModule,EffectsModule.forFeature([ConnecteurEffects])],
	exports: [ImportModule],
	providers: [ConnecteurService]
})
export class ConnecteurModule {}