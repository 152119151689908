<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idArticle">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data.libelle + ' (' + data.reference + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idArticle">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.listeRevendeurs?.length"><span translate>articleCRM.revendeur.item</span>&#160;:&#160;<span>{{ getListeRevendeursAsString() }}</span></li>
		<li><span translate>articleCRM.objets</span>&#160;:&#160;<span [translate]="'articleCRM.objet.'+data.typeObjet"></span></li>
		<li *ngIf="data.typeLicence && data.typeLicence != 'NON_DEFINI'"><span translate>licence.typeLicence.item</span>&#160;:&#160;<span [translate]="'licence.typeLicence.'+data.typeLicence"></span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="data?.idArticle">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteArticleCRM();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory('com.notilus.data.crm.ArticleCRM',data?.idArticle)"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<article-crm [articleCRM]="data" (onObjectChange)="onObjectChange($event)"></article-crm>
</div>