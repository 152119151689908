<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
			<icon name="check" *ngIf="data.success !== false && data.apiSession?.statut == 'FINISHED'"></icon>
			<icon name="close" *ngIf="data.success === false || data.apiSession?.statut == 'FAILED'"></icon>
			<icon name="hourglass_bottom" *ngIf="data.success !== false && (!data.apiSession || !data.apiSession?.statut || data.apiSession?.statut == 'PENDING')"></icon>
		</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data?.isDisplayed">
				<ng-container *ngIf="data?.connecteur">
					<ng-container *ngIf="rightService.isRevendeur()">{{ data?.tenant.nom + ' - ' + data?.tenant.code + ' (' + data.connecteur.type.reference + ')' }}</ng-container>
					<ng-container *ngIf="!rightService.isRevendeur()">{{ data.connecteur.type.libelle + ' (' + data.connecteur.type.reference + (data.connecteur.type.typeConnecteur == 'INTERFACE' && data.connecteur.version ? ' - ' + ('connecteur.version' | translate) + ' ' + data.connecteur.version : '') + ')' }}</ng-container>
				</ng-container>
				<ng-container *ngIf="data?.importTemplate">
					<ng-container *ngIf="rightService.isRevendeur()">{{ data?.tenant.nom + ' - ' + data?.tenant.code + ' (' + data.importTemplate.libelle + ')' }}</ng-container>
					<ng-container *ngIf="!rightService.isRevendeur()">{{ data.importTemplate.libelle }}</ng-container>
				</ng-container>
			</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.dateExecution"><span translate>connecteur.import.dateExecution</span>&#160;:&#160;<span>{{ data?.dateExecution | date:'short' }}</span></li>
		<li *ngIf="data.apiSession?.statut"><span translate>connecteur.import.statut.item</span>&#160;:&#160;<span [translate]="'connecteur.import.statut.'+data.apiSession.statut"></span></li>
		<li *ngIf="rightService.isRoot() && data.typeExecution != 'NON_DEFINI'"><span translate>connecteur.typeExecution.item</span>&#160;:&#160;<span [translate]="'connecteur.typeExecution.'+data.typeExecution"></span></li>
		<li *ngIf="data.apiSession?.nbCreations"><span translate>connecteur.import.resultat.nbCreations</span>&#160;:&#160;{{ data.apiSession.nbCreations | number }}</li>
		<li *ngIf="data.apiSession?.nbUpdates"><span translate>connecteur.import.resultat.nbUpdates</span>&#160;:&#160;{{ data.apiSession.nbUpdates | number }}</li>
		<li *ngIf="data.apiSession?.nbIgnores"><span translate>connecteur.import.resultat.nbIgnores</span>&#160;:&#160;{{ data.apiSession.nbIgnores | number }}</li>
		<li *ngIf="data.apiSession?.nbErrors"><span translate>connecteur.import.resultat.errors</span>&#160;:&#160;{{ data.apiSession.nbErrors | number }}</li>
		<li *ngIf="data.apiSession?.nbWarnings"><span translate>connecteur.import.resultat.warnings</span>&#160;:&#160;{{ data.apiSession.nbWarnings | number }}</li>
		<li *ngIf="data.apiSession?.nbInfos"><span translate>connecteur.import.resultat.infos</span>&#160;:&#160;{{ data.apiSession.nbInfos | number }}</li>
		<li *ngIf="data.nbExecutions > 1"><span translate>connecteur.import.nbExecutions</span>&#160;:&#160;{{ data.nbExecutions | number }}</li>
		<ng-template #listeParamsTooltipTemplate>
			<div style="text-align: left;">
				<div *ngFor="let param of data.listeParamsUsed">
					<span>{{ param.connecteurTypeParam.name }}</span>&#160;:&#160;<span>{{ (param.connecteurTypeParam.type == 'date' ? (param.dateValue | date:'short')  : param.connecteurTypeParam.type == 'numeric' ? (param.numericValue | number) : param.connecteurTypeParam.type == 'boolean' ? ('common.'+(param.booleanValue ? 'oui' : 'non') | translate) : param.value) || ('common.nonDefini' | translate) }}</span>
				</div>
			</div>
		</ng-template>
		<li *ngIf="data.listeParamsUsed?.length" [tooltip]="listeParamsTooltipTemplate"><span translate>connecteur.execution.parametresFacultatifs</span>&#160;:&#160;<span>{{ data.listeParamsUsed.length }}</span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'creation') && data?.listeLinks?.length > 0"><a [routerLink]="[]" (click)="retryImport(data);"><span translate>actions.relancer</span></a></li>
			<li *ngIf="rightService.isRoot() && (null | right:'suppression') && data.connecteur?.type?.typeConnecteur == 'INTERFACE'"><a [routerLink]="[]" (click)="deleteImport();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="rightService.isRevendeur() && data.correlationId && data.connecteur?.type?.typeExecution == 'MIDDLEWARE'"><a [routerLink]="[]" (click)="downloadLogs();"><span translate>connecteur.actions.telechargerJournaux</span></a></li>
			<li *ngIf="!rightService.isRevendeur() && (TypeDroit.CONNECTEUR | right:'consultation') && data.connecteur"><a [routerLink]="[]" (click)="goToConnecteur();"><span translate>connecteur.import.actions.accederConnecteur</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory('com.notilus.data.connecteur.ConnecteurExecution',data?.idExecution,data?.tenant?.idTenant)"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data?.isDisplayed">
	<import [connecteurExecution]="data" (onObjectChange)="onObjectChange($event)"></import>
</div>