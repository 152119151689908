<form #connecteurTypeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!connecteurType.isDisplayed">
		<div class="tile-sub">
			<fieldset>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>connecteur.connecteurType.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="connecteurType.libelle" required [disabled]="true"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>connecteur.connecteurType.reference</label>
							<div>
								<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="connecteurType.reference" required [disabled]="true"/>
							</div>
						</div>
						<div class="form-group">
							<label translate>connecteur.connecteurType.fournisseurInterface</label>
							<div>
								<autocomplete name="fournisseurInterface" type="fournisseurInterface" #fournisseurInterface="ngModel" [(ngModel)]="connecteurType.fournisseurInterface" [disabled]="true"></autocomplete>
							</div>
						</div>
						<div class="form-group">
							<label translate>connecteur.connecteurType.fournisseur</label>
							<div>
								<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="connecteurType.fournisseur" [disabled]="true"></autocomplete>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': typeConnecteur.invalid }">
							<label translate>connecteur.connecteurType.typeConnecteur</label>
							<selectpicker name="typeConnecteur" #typeConnecteur="ngModel" [(ngModel)]="connecteurType.typeConnecteur" required [disabled]="true">
								<mat-option *ngFor="let typeConnecteur of listeTypesConnecteur" [value]="typeConnecteur.code">{{ typeConnecteur.libelle }}</mat-option>
							</selectpicker>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeExecution.invalid }">
							<label translate>connecteur.connecteurType.typeExecution</label>
							<selectpicker name="typeExecution" #typeExecution="ngModel" [(ngModel)]="connecteurType.typeExecution" required [disabled]="true">
								<mat-option *ngFor="let typeExecution of listeTypesExecution" [value]="typeExecution.code">{{ typeExecution.libelle }}</mat-option>
							</selectpicker>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeVisibility.invalid }">
							<label translate>connecteur.connecteurType.typeVisibility</label>
							<selectpicker name="typeVisibility" #typeVisibility="ngModel" [(ngModel)]="connecteurType.typeVisibility" required [disabled]="true">
								<mat-option *ngFor="let typeVisibility of listeTypesVisibility" [value]="typeVisibility.code">{{ typeVisibility.libelle }}</mat-option>
							</selectpicker>
						</div>
						<div class="form-group">
							<label translate>connecteur.connecteurType.actif.item</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="connecteurType.actif"><span translate>connecteur.connecteurType.actif.isActif</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: connecteurTypeForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveConnecteurType();" *ngIf="null | right:'creation'" [disabled]="connecteurTypeForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>