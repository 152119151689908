<form #taxeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" [translate]="'facture.taxe.title' | pluralTranslate:listeTaxes?.length"></h4>
	</div>
	<div class="modal-body">
		<div class="alert alert-warning alert-no-shadow" *ngIf="hasError">
			<icon name="info"></icon>
			<div>
				<strong translate>facture.taxe.alerte.warningMontant.title</strong>
				<div translate>facture.taxe.alerte.warningMontant.message</div>
			</div>
		</div>
		<div class="d-flex gap-4">
			<div class="text-right bold flex-1">
				<h4 translate>facture.taxe.taux</h4>
			</div>
			<div class="bold flex-2">
				<h4 translate>facture.taxe.montant</h4>
			</div>
			<div class="flex-2"></div>
		</div>
		<fieldset [disabled]="disabled">
			<div class="d-flex align-items-center gap-4" *ngFor="let taxe of listeTaxes; let index = index">
				<div class="text-right flex-1 m-b-10 m-t-10">
					<span [ngClass]="{ 'bold': taxe.montant }" >{{ (taxe?.taux | number) + ' %' }}</span>
				</div>
				<div class="form-group flex-2 m-b-10 m-t-10">
					<input type="text" data-test-id="taxe" [name]="'montant-'+index" #taxeField="ngModel" class="form-control" [(ngModel)]="taxe.montant" nNumber="3"/>
				</div>
				<div class="flex-2"></div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span [translate]="!taxeForm.dirty ? 'actions.fermer' : 'actions.annuler'"></span>
		</button>
		<button mat-raised-button color="primary" (click)="saveTaxe()" [disabled]="taxeForm.invalid" *ngIf="!disabled">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>