import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnDestroy,OnInit,Output } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'import',
	templateUrl: './import.component.html'
})
export class ImportComponent implements OnInit,OnDestroy,AfterContentChecked {
	/** Elément courant **/
	@Input() connecteurExecution: any;

	/** Gestion d'une modification de l'exécution **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Copie de l'exécution **/
	public savedConnecteurExecution: any;

	/** Progression du traitement **/
	progress: { nbProcessed: number,nbTotal: number } = null;

	/** Connexion à la WebSocket **/
	messaging$: MessagingObservables;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public rightService: RightService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Copie de l'exécution du connecteur
		this.savedConnecteurExecution = cloneDeep(this.connecteurExecution);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Fermeture de la connexion
		this.messaging$?.close();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Fermeture de l'import
	 */
	onContentClose(connecteurExecution: any) {
		//Vérification de la présence de données
		if (connecteurExecution) {
			//Mise à jour de l'objet
			this.connecteurExecution = Object.assign(this.connecteurExecution,connecteurExecution);

			//Mise à jour de la copie
			this.savedConnecteurExecution = cloneDeep(this.connecteurExecution);
		} else {
			//Retour à la page précédente
			this.close();
		}
	}
}