import { NgModule } from '@angular/core';

import { ChatbotComponent } from './chatbot.component';
import { ChatbotService } from './chatbot.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { DisplayMoreModule } from 'src/app/share/components/display-more/display-more.module';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule,DisplayMoreModule],
	declarations: [ChatbotComponent],
	exports: [ChatbotComponent],
	providers: [ChatbotService]
})
export class ChatbotModule { }