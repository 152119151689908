<div *ngIf="article" [ngClass]="{ 'container-fluid': !isModal && article.listeContents?.length > 0,'container': !isModal && article.listeContents?.length == 0 }" class="article">
	<div class="row">
		<div [ngClass]="{ 'col-md-9': !isModal && article.listeContents?.length > 0,'col-md-12': !isModal && article.listeContents?.length == 0 }">
			<div class="card">
				<div class="card-body card-padding">
					<h1 data-test-id="titre">{{ article.titre }}</h1>
					<div class="listview lv-bordered">
						<div class="lv-body">
							<div class="lv-item media article-content" *ngFor="let content of article.listeContents">
								<ng-container *ngIf="!isModal">
									<div class="media-body clickable" (click)="content.isToggled = !content.isToggled;">
										<ul class="pull-right actions m-t-10">
											<li><a [routerLink]="[]"><icon [name]="content.isToggled ? 'chevron_right' : 'expand_more'"></icon></a></li>
										</ul>
										<h3 class="word-break" [id]="'id_titre_'+content.idContent">{{ content.titre }}</h3>
									</div>
									<div [collapse]="content.isToggled" class="p-t-10">
										<p #contentContainer [innerHtml]="content.content"></p>
									</div>
								</ng-container>
								<div *ngIf="isModal">
									<h3 class="word-break">{{ content.titre }}</h3>
									<p #contentContainer [innerHtml]="content.content"></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr *ngIf="!isModal && article.typeArticle == 'STANDARD' && !article.tenant"/>
			<div class="center-block feedback" *ngIf="!isModal && article.typeArticle == 'STANDARD' && !article.tenant">
				<div class="lv-item media">
					<div class="rating bubble bot">
						<div translate>article.feedback.question</div>
						<div class="text-center">
							<span *ngFor="let star of feedback.listeStars; let index = index;">
								<a [routerLink]="[]" (click)="updateRating(index)">
									<icon [name]="star.filled ? 'star' : 'star_outline'"></icon>
								</a>
							</span>
						</div>
					</div>
				</div>
				<div class="lv-item media">
					<div class="comment-container bubble bot" *ngIf="feedback.rated">
						<div>
							<a class="p-l-10 pull-right" *ngIf="feedback.commented" [routerLink]="[]" (click)="feedback.commented = false;"><icon name="edit"></icon></a>
							<span [translate]="'article.feedback.'+(!feedback.commented ? 'laisserCommentaire' : 'remerciement')"></span>
						</div>
						<div *ngIf="!feedback.commented" class="tips" translate>article.feedback.envoyerCommentaire</div>
						<form *ngIf="!feedback.commented" #feedbackForm="ngForm" autocomplete="off">
							<textarea elasticTextarea rows="3" class="form-control comment-textarea" name="commentaire" [(ngModel)]="feedback.commentaire" (keypress)="sendComment($event)"></textarea>
						</form>
						<div *ngIf="feedback.commented">
							<span class="bold p-t-10">{{ feedback.commentaire }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-3 hidden-xs hidden-sm" *ngIf="!isModal && article.listeContents?.length > 0">
			<div class="card fixed-to-top">
				<div class="card-body p-r-20">
					<div class="listview lv-bordered lv-sm m-t-0">
						<div class="actions-container">
							<button mat-icon-button (click)="generateDocument();" [tooltip]="'actions.genererDocument' | translate">
								<mat-icon>picture_as_pdf</mat-icon>
							</button>
							<button mat-icon-button *ngIf="article.listeLinks?.length" class="attachment" (click)="showAttachment();" [attr.data-counter]="article.listeLinks?.length || 0" [tooltip]="'actions.attachment.consulter' | translate">
								<mat-icon>attach_file</mat-icon>
							</button>
						</div>
						<div class="lv-header-alt">
							<h2 class="lvh-label bold" translate>article.voletNavigation</h2>
						</div>
						<div class="lv-body">
							<div class="lv-item media" *ngFor="let content of article.listeContents">
								<div class="media-body">
									<div class="lv-title" [title]="content.titre">
										<a [routerLink]="[]" (click)="content.isToggled = false; scrollTo('id_titre_'+content.idContent);">
											<span *ngIf="content.titre">{{ content.titre }}</span>
											<span *ngIf="!content.titre" translate>article.rubrique.aucunTitre</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>