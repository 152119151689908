import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { FactureService } from '../facture.service';
import { IEntity } from 'src/app/domain/entity/entity';

@Component({
	selector: 'detail',
	templateUrl: './detail.component.html'
})
export class DetailComponent implements OnInit,IEntity {
	/** Ligne de facture courante **/
	public detail: any;

	/**
	 * Constructeur
	 */
	constructor(private factureService: FactureService,private activatedRoute: ActivatedRoute,private layoutService: LayoutService) {
		//Binding
		this.close = this.close.bind(this);
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.facture.Detail';

	/**
	 * Récupération de l'identifiant
	 */
	getIdObject: () => number = () => this.detail?.idDetail || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'identifiant de la ligne de facture à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idDetail);
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idDetail: number) {
		//Chargement de la ligne de facture
		this.factureService.loadDetail(idDetail).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR)
					//Définition du detail
					this.detail = result.data?.detail;
			}
		});
	}

	/**
	 * Fermeture du formulaire
	 */
	close() {
		//Retour en arrière
		this.layoutService.goBack();
	}
}