import { Component,Input } from '@angular/core';

import { TypeModule } from 'src/app/domain/connecteur/type-module';

@Component({
	templateUrl: './import-resume.component.html'
})
export class ImportResumeComponent {
	/** Fournisseur sélectionné **/
	@Input() selectedFournisseur: any;

	/** Connecteur sélectionné **/
	@Input() selectedConnecteur: any;

	/** Type de module d'import sélectionné **/
	@Input() typeModule?: TypeModule;

	/** Module d'import sélectionné **/
	@Input() selectedModule?: any;

	/** Format sélectionné **/
	@Input() selectedFormat?: any;

	/** Template d'import sélectionné **/
	@Input() selectedTemplate?: any;

	/** Vérification de la disponibilité du connecteur sélectionné **/
	@Input() selectedConnecteurAvailability: { isAvailable: boolean };
}