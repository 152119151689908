import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { AttachmentEntity,LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { TemplateMailService } from 'src/app/share/components/template-mail/template-mail.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TemplateMailDestinataireEditComponent } from './template-mail-destinataire-edit.component';
import { TemplateMailDestinataireListComponent } from './template-mail-destinataire-list.component';
import { TemplateMailEditComponent } from './template-mail-edit.component';
import { TemplateMailPreviewSelectionComponent } from './template-mail-preview-selection.component';
import { TemplateMailRubriqueListEditComponent } from './template-mail-rubrique-list-edit.component';
import { TemplateMailRubriqueListComponent } from './template-mail-rubrique-list.component';

@Component({
	selector: 'template-mail',
	templateUrl: './template-mail.component.html'
})
export class TemplateMailComponent implements OnInit,IEntity,AttachmentEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Template courant **/
	public templateMail: any;

	/** Catalogue des données métier **/
	public listeBusinessData: Array<any> = null;

	/** Type de référentiel **/
	public typeReferentiel: TypeReferentiel;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.TEMPLATE_MAIL;

	/** Type de pièce jointe de l'entité **/
	readonly typeLink: string = 'MAIL_ATTACHMENT';

	/** Classe du type de pièce jointe pour l'entité **/
	readonly typeLinkClass: string = 'com.notilus.data.attachment.link.LinkTemplateMail$TypeLinkTemplateMail';

	/** Résumé **/
	resume: { nbRubriques: number,nbDestinataires: number } = {
		nbRubriques: 0,
		nbDestinataires: 0
	};

	/** Indicateur de personnalisation du template de mail **/
	isCustom: boolean;

	/** Utilisateur connecté **/
	user: User;

	/** Utilisateur d'origine **/
	userOrigine: User;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private templateMailService: TemplateMailService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute,private rightService: RightService
			,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,private attachmentService: AttachmentService,private layoutService: LayoutService,private store: Store<AppState>,private entiteService: EntiteService
			,private bsModalService: BsModalService,private autocompleteService: AutocompleteService,private referentielService: ReferentielService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.template.TemplateMail';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.templateMail?.idTemplateMail || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.templateMail;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<{ user: User,userOrigine: User }>(state => ({
			user: state.session?.user,
			userOrigine: state.session?.userOrigine
		})).pipe(first()).subscribe(({ user,userOrigine }) => {
			//Définition des utilisateurs
			this.user = user;
			this.userOrigine = userOrigine;

			//Définition du type de référentiel
			this.typeReferentiel = this.activatedRoute.snapshot.params.typeReferentiel;

			//Chargement des données
			this.loadData(this.activatedRoute.snapshot.params.idTemplateMail).subscribe({
				complete: () => {
					//Initialisation de la page complexe
					this.options = {
						listeFields: [!!this.templateMail?.templateMailOrigine && {
							libelle: 'templateMail.custom.item',
							key: 'custom',
							type: 'BOOLEAN'
						},{
							libelle: 'templateMail.reference',
							key: 'reference'
						},{
							libelle: 'templateMail.libelle',
							key: 'libelle'
						},{
							libelle: 'templateMail.entite',
							key: (data) => this.entiteService.translateEntityCode(data.entite?.split('.').pop())
						},{
							libelle: 'templateMail.objet',
							key: 'objet'
						},{
							libelle: 'templateMail.actif.item',
							key: 'actif',
							type: 'BOOLEAN'
						}].filter(f => !!f),
						listeActions: [{
							libelle: 'actions.previsualiser',
							doAction: () => this.previewTemplateMail(),
							isVisible: () => !!this.templateMail.entite,
						},{
							libelle: 'actions.modifier',
							doAction: () => this.editTemplateMail(),
							isVisible: () => this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot()),
							type: TypeAction.EDITION
						},{
							libelle: 'actions.consulter',
							doAction: () => this.editTemplateMail(),
							isVisible: () => !(this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot())),
							type: TypeAction.CONSULTATION
						},{
							libelle: 'actions.dupliquer',
							doAction: () => this.duplicateTemplateMail(),
							isVisible: () => this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot())
						},{
							libelle: 'actions.supprimer',
							doAction: () => this.deleteTemplateMail(),
							isVisible: () => this.rightService.hasRight(null,'suppression') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot()),
							type: TypeAction.SUPPRESSION
						},{
							libelle: 'actions.importer',
							doAction: () => this.importTemplateMail(),
							isVisible: () => this.rightService.hasRight(null,'creation') && this.typeReferentiel == TypeReferentiel.STANDARD && !this.rightService.isRoot(),
						}],
						listeAlertes: [{
							icon: 'view_column',
							title: this.translateService.instant('templateMail.alerte.aucuneRubrique.title'),
							message: this.translateService.instant('templateMail.alerte.aucuneRubrique.message'),
							isVisible: () => this.resume.nbRubriques == 0 && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot()) && !this.pageContentService.isOpened(),
							isActionVisible: () => this.rightService.hasRight(null,'creation'),
							doAction: () => this.editListeRubriques(true)
						}],
						listeElements: [{
							type: 'RUBRIQUE',
							libelle: 'templateMail.elements.rubrique',
							component: TemplateMailRubriqueListComponent,
							retrieveComponentData: () => ({
								templateMail: this.templateMail,
								resume: this.resume,
								typeReferentiel: this.typeReferentiel,
								editListeRubriques: this.editListeRubriques.bind(this)
							}),
							doAction: () => this.editListeRubriques(true),
							libelleAction: this.translateService.instant('templateMail.actions.ajouterRubrique'),
							isActionVisible: () => this.rightService.hasRight(null,'creation') && (!this.templateMail.templateMailOrigine || this.templateMail.custom) && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot()),
							count: () => this.resume?.nbRubriques || 0
						},{
							type: 'DESTINATAIRE',
							libelle: 'templateMail.elements.destinataire',
							component: TemplateMailDestinataireListComponent,
							retrieveComponentData: () => ({
								templateMail: this.templateMail,
								resume: this.resume,
								addDestinataire: this.addDestinataire.bind(this)
							}),
							doAction: () => this.addDestinataire(),
							libelleAction: this.translateService.instant('templateMail.actions.ajouterDestinataire'),
							isVisible: () => this.templateMail?.notificationManuelle,
							isActionVisible: () => this.rightService.hasRight(null,'creation') && (!this.templateMail.templateMailOrigine || this.templateMail.custom) && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot()) && this.templateMail.notificationManuelle,
							count: () => this.resume?.nbDestinataires || 0
						}]
					};
				}
			});
		});
	}

	/**
	 * Edition du template de mail
	 */
	editTemplateMail() {
		//Ouverture du composant d'édition
		this.pageContentService.open(TemplateMailEditComponent,{
			data: {
				templateMail: this.templateMail.idTemplateMail ? cloneDeep(this.templateMail) : this.templateMail,
				isCustom: this.isCustom,
				typeReferentiel: this.typeReferentiel,
				deleteTemplateMail: this.deleteTemplateMail.bind(this),
				resume: this.resume,
				user: this.user,
				listeBusinessData: this.listeBusinessData
			}
		}).subscribe({
			next: (data: { templateMail: any,nbDestinataires: number }) => {
				//Vérification des données
				if (data) {
					//Mise à jour du template
					this.templateMail = data.templateMail;

					//Récupération de l'indicateur de personnalisation
					this.isCustom = this.templateMail.custom;

					//Mise à jour du nombre de rubriques
					this.resume.nbRubriques = this.templateMail.listeTemplateStructure?.length || 0;

					//Mise à jour du nombre de destinataires
					this.resume.nbDestinataires = data.nbDestinataires;

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.templateMail);
				}
			}
		});
	}

	/**
	 * Suppression du template
	 */
	private deleteTemplateMail() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.templateMailService.deleteTemplateMail(this.typeReferentiel,this.templateMail))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.templateMail;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Prévisualisation
	 */
	private previewTemplateMail() {
		let bsModalRef: BsModalRef<TemplateMailPreviewSelectionComponent>;
		let autocompleteOptions: AutocompleteOptions;

		//Récupération de l'autocomplete associé à l'entité
		autocompleteOptions = this.autocompleteService.getOptionsForEntite(this.templateMail.entite);

		//Vérification de la présence d'un autocomplete pour l'entité
		if (autocompleteOptions) {
			//Affichage de la pop-up de choix de la valeur de référence
			bsModalRef = this.bsModalService.show(TemplateMailPreviewSelectionComponent,{
				initialState: {
					autocompleteOptions
				},
				class: 'modal-md'
			});

			//Récupération du résultat
			bsModalRef.onHidden.pipe(
				first(),
				map(() => bsModalRef.content?.reference),
				filter(reference => !!reference)
			).subscribe(entite => {
				//Lancement de la prévisualisation sur la valeur de référence
				this.templateMailService.previewTemplateMail(this.templateMail,entite[autocompleteOptions.getKeyFieldName()]);
			});
		} else
			//Lancement de la prévisualisation sans valeur de référence
			this.templateMailService.previewTemplateMail(this.templateMail,null);
	}

	/**
	 * Import du template de mail
	 */
	private importTemplateMail() {
		//Copie de l'entité en local
		this.referentielService.copyEntiteLocal(this.templateMail.idTemplateMail,this.getClassName()).subscribe(result => {
			let listeDoublons: Array<string>;

			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.import.success'));

				//Fermeture de la page
				this.layoutService.goBack();
			} else if (result?.codeErreur === TypeCodeErreur.DOUBLON) {
				//Création de la liste
				listeDoublons = [];

				//Vérification de la référence
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

				//Vérification du libellé
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.import.error'));
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idTemplateMail: number): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement du template de mail
		this.templateMailService.loadTemplateMail(idTemplateMail || 0).subscribe({
			next: result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Définition du template
					this.templateMail = result.data?.templateMail;

					//Récupération du catalogue
					this.listeBusinessData = result.data?.catalogue;

					//Parcours des entités proposées par le catalogue
					this.listeBusinessData.forEach(entity => {
						//Ajout du libellé de l'entité
						entity.libelle = this.entiteService.translateEntityCode(entity.code);
					});

					//Vérification du mode
					if (!this.templateMail) {
						//Création du template vierge
						this.templateMail = {
							tenant: this.user.tenant,
							typeDiffusion: this.rightService.isRoot() ? 'TOUS' : this.user.tenant.type == 'REVENDEUR' ? 'REVENDEUR' : 'CLIENT',
							type: 'AUTRE',
							actif: false,
							notificationManuelle: !this.rightService.isRoot(),
							langue: this.user.langue,
							custom: false
						};
					}

					//Récupération de l'indicateur de personnalisation
					this.isCustom = this.templateMail.custom;

					//Initialisation des compteurs
					this.resume = {
						nbRubriques: this.templateMail.listeTemplateStructure?.length || 0,
						nbDestinataires: result.data?.nbDestinataires || 0
					};

					//Vérification de l'identifiant
					if (!this.templateMail.idTemplateMail)
						//Edition
						this.editTemplateMail();

					//Initialisation des pièces jointes
					this.attachmentService.initAttachments(this);
				}

				//Finalisation du chargement des données
				subject.complete();
			},
			complete: () => subject.complete()
		});

		return subject;
	}

	/**
	 * Modification des rubriques
	 */
	editListeRubriques(isAddNewRubrique: boolean) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TemplateMailRubriqueListEditComponent,{
			data: {
				templateMail: this.templateMail,
				typeReferentiel: this.typeReferentiel,
				isAddNewRubrique,
				isCustom: this.isCustom,
				user: this.user,
				userOrigine: this.userOrigine
			}
		},'sub').subscribe((data: { templateMail: any }) => {
			//Vérification de la présence du template
			if (data?.templateMail) {
				//Mise à jour du template
				this.templateMail = data.templateMail;

				//Récupération de l'indicateur de personnalisation
				this.isCustom = this.templateMail.custom;

				//Rafraichissement de l'onglet
				this.complexPage.setSelectedElementByType('RUBRIQUE',true);
			}

			//Mise à jour du compteur
			this.resume.nbRubriques = this.templateMail.listeTemplateStructure?.length || 0;
		});
	}

	/**
	 * Création/Modification d'un destinataire
	 */
	addDestinataire(destinataire: any = { templateMail: Object.assign({},this.templateMail) }) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TemplateMailDestinataireEditComponent,{
			data: {
				destinataire: cloneDeep(destinataire),
				isReadOnly: !this.rightService.isRoot() && this.typeReferentiel == TypeReferentiel.STANDARD || this.templateMail.templateMailOrigine && !this.templateMail.custom
			}
		},'sub').subscribe((data: { destinataire: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Affichage de l'onglet (si nécessaire)
				this.complexPage.setSelectedElementByType('DESTINATAIRE',destinataire.idDestinataire != data.destinataire?.idDestinataire);

				//Vérification de la présence d'une création ou d'une suppression
				if (!destinataire.idDestinataire && data.destinataire?.idDestinataire)
					//Incrémentation du compteur
					this.resume.nbDestinataires++;
				else if (destinataire.idDestinataire && !data.destinataire?.idDestinataire)
					//Décrémentation du compteur
					this.resume.nbDestinataires--;

				//Mise à jour du destinataire
				destinataire = Object.assign(destinataire,data.destinataire);
			}
		});
	}

	/**
	 * Duplication du template de mail
	 */
	duplicateTemplateMail() {
		//Duplication du template de mail
		this.templateMailService.duplicateTemplateMail(this.templateMail).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Redirection vers le template créé
					this.layoutService.replaceUrlWith({
						typeReferentiel: this.typeReferentiel,
						idTemplateMail: result.data.templateMail.idTemplateMail
					},true);
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}