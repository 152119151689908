import { Component,Input } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	selector: 'import-specification-connecteur-list-item',
	templateUrl: './import-specification-connecteur-list-item.component.html'
})
export class ImportSpecificationConnecteurListItemComponent extends ListViewItem<any> {
	/** Indicateur de suppression possible **/
	@Input() canRemove: boolean;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}