<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.reference.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/TemplateMail',liste.selectedSelector,data.idTemplateMail]">{{ data.libelle + ' (' + data.reference + ')' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li><span>{{ entiteService.translateEntityCode(data.entite.split('.').pop(-1)) }}</span></li>
		<li *ngIf="rightService.isRoot() || extraOptions.user.tenant?.type == 'REVENDEUR'"><span translate>templateMail.typeDiffusion.item</span>&#160;:&#160;<span [translate]="'diffusion.'+data.typeDiffusion"></span></li>
		<li *ngIf="data.templateMailOrigine"><span translate>templateMail.custom.item</span>&#160;:&#160;<span [translate]="'common.'+(data?.custom ? 'oui' : 'non')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'creation') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="duplicateTemplateMail();"><span translate>actions.dupliquer</span></a></li>
			<li *ngIf="(null | right:'suppression') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="deleteTemplateMail();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(null | right:'creation') && liste.selectedSelector == 'STANDARD' && !rightService.isRoot()"><a [routerLink]="[]" (click)="copyEntiteLocal(data.idTemplateMail)"><span translate>actions.importer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>