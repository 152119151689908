import { NgModule } from '@angular/core';

import { TaxeModule } from './taxe/taxe.module';
import { RegleTaxeModule } from './regle-taxe/regle-taxe.module';
import { AvantageNatureAbattementModule } from './avantage-nature/avantage-nature-abattement.module';

@NgModule({
	imports: [TaxeModule,RegleTaxeModule,AvantageNatureAbattementModule]
})
export class FiscaliteModule { }
