<div class="pull-left lv-avatar">{{ data.libelle.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="!canRemove && extraOptions.onItemSelected({ template: data })">{{ data.libelle }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>connecteur.import.template.champs</span>&#160;:&#160;<span>{{ getListeFields() }}</span></li>
	</ul>

	<div class="lv-actions actions" *ngIf="canRemove">
		<a [routerLink]="[]" (click)="extraOptions.onItemSelected(null)"><icon name="close" class="icon-hc-1x"></icon></a>
	</div>
	<div class="lv-actions actions" *ngIf="!canRemove">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="showImportTemplate()"><span translate>actions.modifier</span></a></li>
			<li><a [routerLink]="[]" (click)="deleteImportTemplate();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>