<div class="container">
	<div class="card">
		<list-view [liste]="liste" *ngIf="liste"></list-view>
	</div>
</div>
<floating-button>
	<floating-button-item *ngIf="hasDashboard" icon="edit" [label]="'dashboard.actions.accederDashboard' | translate" (click)="dashboardService.showDashboard()"></floating-button-item>
	<floating-button-item *ngIf="hasImports" icon="upload" [label]="'actions.importer' | translate" (click)="connecteurService.showImport()"></floating-button-item>
	<floating-button-item *ngIf="TypeDroit.FACTURE_LAD | right:'creation'" icon="insert_drive_file" [label]="'actions.scanner' | translate" (click)="factureService.showScanFacture()"></floating-button-item>
	<floating-button-item icon="download" [label]="'actions.extraire' | translate" (click)="extractionService.selectExtraction(getMapEntites())"></floating-button-item>
</floating-button>