import { ChangeDetectorRef,Component,Input,OnInit,ViewChild } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map } from 'rxjs';
import { cloneDeep } from 'lodash-es';

import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { DashboardChartComponent } from 'src/app/components/dashboard/dashboard-chart.component';
import { DashboardChartCustomizerComponent } from 'src/app/components/dashboard/dashboard-chart-customizer.component';
import { SubTypeChart } from 'src/app/domain/chart/chart';

@Component({
	templateUrl: './chart-preview.component.html',
	styles: ['.modal-header .badge { position: absolute; top: 0; right: 0; }']
})
export class ChartPreviewComponent implements OnInit {
	/** Indicateur d'ajout de graphique **/
	@Input() canAddChart: boolean = false;

	/** Source **/
	@Input() source: 'GRAPHIQUE' | 'CHATBOT';

	/** Graphique **/
	dashboardChart: { chart: any,readOnly?: boolean,rule?: any,entityName?: string };

	/** Liste des filtres **/
	listeFilters: Array<any> = [];

	/** Dernier graphique (via le drill down) **/
	lastDefinition: any;

	/** Résultat **/
	result: { isChartAdded: boolean };

	/** Graphique **/
	@ViewChild('dashboardChartRef') dashboardChartRef: DashboardChartComponent;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ChartPreviewComponent>,private extractionService: ExtractionService,private dashboardService: DashboardService,private bsModalService: BsModalService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération du dernier graphique pour le drill down
		this.lastDefinition = this.dashboardChart.chart.listeDefinitions[this.dashboardChart.chart.listeDefinitions.length - 1];

		//Vérification de la source
		if (this.source == 'CHATBOT')
			//Ajout de l'option de lecture seule au graphique
			this.dashboardChart.readOnly = true;
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Ajout du graphique
	 */
	addChart() {
		//Définition du résultat
		this.result = {
			isChartAdded: true
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Extraction des données
	 */
	executeExtraction() {
		//Déclechement de l'extraction
		this.extractionService.executeExtraction(this.dashboardChart.chart.extraction,{ dashboardChart: this.dashboardChart });
	}

	/**
	 * Accès aux données
	 */
	accessData() {
		let selectedValuesForLastDefinition: Array<any>;

		//Récupération de la valeur sélectionnée pour le dernier graphique
		selectedValuesForLastDefinition = this.listeFilters?.filter(f => f.idDefinition == this.lastDefinition.idDefinition)?.[0]?.selectedValueRaw;

		//Accès aux données
		this.dashboardService.redirectToList(this.dashboardChart,this.dashboardChartRef.currentDefinition,this.dashboardChartRef.chartOptions,selectedValuesForLastDefinition);
	}

	/**
	 * Affichage de la pop-up de personnalisation des filtres
	 */
	customize() {
		let bsChartCustomizerModalRef: BsModalRef<DashboardChartCustomizerComponent>;

		//Initialisation des conditions personnalisées
		this.dashboardChart.rule = this.dashboardChart.rule || {};

		//Définition de l'entité
		this.dashboardChart.entityName = this.dashboardChart.chart.extraction.entity;

		//Ouverture de la modal (personnalisation des conditions uniquement)
		bsChartCustomizerModalRef = this.bsModalService.show(DashboardChartCustomizerComponent,{
			initialState: {
				dashboardChart: cloneDeep(this.dashboardChart),
				typeAffichage: 'CONDITIONS_ONLY'
			},
			class: 'modal-max'
		});

		//Retour du résultat
		bsChartCustomizerModalRef.onHidden.pipe(
			first(),
			map(() => bsChartCustomizerModalRef.content?.result?.dashboardChart),
			filter(dashboardChart => !!dashboardChart)
		).subscribe({
			next: dashboardChart => {
				//Mise à jour des conditions personnalisées
				this.dashboardChart.rule = dashboardChart.rule;

				//Mise à jour de l'index
				this.dashboardChartRef.currentDefinitionIndex = 0;

				//Rechargement des données
				this.dashboardChartRef.loadGraph(0);

				//Remise à zéro des filtres
				this.listeFilters = [];
			}
		});
	}

	/**
	 * Interception de la sélection d'une valeur
	 */
	onSelect(d: any) {
		let listeKeys: any;
		let filters: Array<any>;

		//Retrait du filtre déjà sélectionné pour le graphique courant
		this.listeFilters = this.listeFilters.filter(f => f.idDefinition != this.dashboardChartRef.currentDefinition.idDefinition);

		//Définition de la liste de clés
		listeKeys = this.dashboardChartRef.currentDefinition.subType != SubTypeChart.NON_DEFINI ? d : d[0];

		//Création des filtres associés à la valeur sélectionnée
		filters = this.dashboardService.createListeRuleDetails(this.dashboardChart,this.dashboardChartRef.currentDefinition,Array.isArray(listeKeys) ? listeKeys : [listeKeys]).filter(f => f.isSelected);

		//Mémorisation de la valeur brute sélectionnée
		filters.forEach(f => f.selectedValueRaw = d);

		//Ajout des filtres à la liste
		this.listeFilters.push(...filters);
	}

	/**
	 * Interception du retour en arrière
	 */
	onGoBack(newIndex: number) {
		let listeIdDefinitions: Array<number>;

		//Récupération des identifiants des graphiques dont on va retirer les filtres associés
		listeIdDefinitions = this.dashboardChart.chart.listeDefinitions.slice(newIndex).map(d => d.idDefinition);

		//Conservation des filtres des graphiques parents uniquement
		this.listeFilters = this.listeFilters.filter(f => !listeIdDefinitions.includes(f.idDefinition));
	}

	/**
	 * Retrait d'un filtre
	 */
	removeFilter(filter: any) {
		//Retrait du filtre
		this.listeFilters = this.listeFilters.filter(f => f != filter);
	}

	/**
 	 * Fermeture de la modal
 	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}