/**
 * Modèle représéntant un message échangé entre le bot et l'utilisateur
 */
export interface Exchange {
	/** Type de message **/
	type: 'BOT' | 'USER';

	/** Message (texte) **/
	text?: string;

	/** Message (Markdown) **/
	markdown?: string;

	/** Nombre d'objets à afficher par page **/
	limitLinks?: number;

	/** Identifiant de suivi de la conversation en cours **/
	correlationId?: string;

	/** Identifiant de partie **/
	partId?: string;

	/** Note attribuée à la conversation en cours **/
	score?: number;

	/** Commentaire associé à l'évaluation **/
	comment?: string;

	/** Indicateur de fin de la conversation **/
	finished?: boolean;

	/** Indicateur de présence d'une note **/
	rated?: boolean;

	/** Indicateur de présence d'un commentaire **/
	commented?: boolean;

	/** Liste d'objets à afficher **/
	listeLinks?: Array<any>;

	/** Liste des actions proposées à l'utilisateur **/
	listeActions?: Array<any>;

	/** Liste des étoiles pour l'attribution d'une note à la conversation **/
	listeStars?: Array<any>;

	/** Identifiant de l'échange **/
	idChatbotExchange?: string;

	/** Graphique à exécuter **/
	chart?: any;

	/** Type de scénario **/
	typeScenario?: TypeScenario;

	/** Indicateur de fallback **/
	fallback?: boolean;
}

/**
 * Enumération des types de scénario
 */
export enum TypeScenario {
	/** Enumération **/
	NON_DEFINI = 'NON_DEFINI',
	SEARCH_FAQ = 'SEARCH_FAQ',
	CREATE_VEHICULE = 'CREATE_VEHICULE',
	CREATE_SINISTRE = 'CREATE_SINISTRE',
	EXECUTE_CHART = 'EXECUTE_CHART',
	EXECUTE_EXTRACTION = 'EXECUTE_EXTRACTION',
	IMPORT_DATA = 'IMPORT_DATA'
}