import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first,Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConnecteurTypeService {
	/** Liste des types de connecteur **/
	private readonly listeTypesConnecteur: Array<string> = ['API','INTERFACE'];

	/** Liste des types de visibilité **/
	private readonly listeTypesVisibility: Array<string> = ['CLIENT','ROOT','BOTH'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Récupération de la liste des types de connecteur
	 */
	public getListeTypesConnecteur(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de connecteur
		return this.listeTypesConnecteur.map(code => ({
			code,
			libelle: this.translateService.instant(`connecteur.type.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de visibilité
	 */
	public getListeTypesVisibility(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de visibilités
		return this.listeTypesVisibility.map(code => ({
			code,
			libelle: this.translateService.instant(`connecteur.typeVisibility.${code}`)
		}));
	}

	/**
	 * Enregistrement du type de connecteur
	 */
	public saveConnecteurType(connecteurType: any): Observable<Result> {
		//Enregistrement du type de connecteur
		return this.http.put<Result>(`${environment.baseUrl}/controller/Connecteur/saveConnecteurType`,connecteurType).pipe(first());
	}
}