import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class AvantageNatureAbattementService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Suppression de l'abattement
	 */
	public deleteAvantageNatureAbattement(abattement: any): Observable<Result> {
		//Suppression de l'abattement
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fiscalite/deleteAvantageNatureAbattement/${abattement.idAbattement}`);
	}

	/**
	 * Enregistrement de l'abattement
	 */
	public saveAvantageNatureAbattement(abattement: any): Observable<Result> {
		//Enregistrement de l'abattement
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fiscalite/saveAvantageNatureAbattement`,abattement);
	}
}