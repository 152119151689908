import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter,first,map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { ConnecteurDestinatairesComponent } from './connecteur-destinataires.component';
import { ConnecteurExecutionResultComponent } from './connecteur-execution-result.component';
import { ConnecteurParamsComponent } from './connecteur-params.component';
import { ConnecteurPasswordsComponent } from './connecteur-passwords.component';
import { ConnecteurExecutionHistoryComponent } from './connecteur-execution-history.component';


@Injectable()
export class ConnecteurService {
	/** Liste des types d'environnement **/
	private readonly listeTypesEnvironnement: Array<string> = ['DEFAULT','BETA'];

	/** Liste des types d'exécution **/
	private readonly listeTypesExecution: Array<string> = ['NON_DEFINI','INTEGRATION','MIDDLEWARE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private translateService: TranslateService) {}

	/**
	 * Chargement d'un connecteur
	 */
	public loadConnecteur(idConnecteur: number): Observable<Result> {
		//Chargement du connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/loadConnecteur/${idConnecteur}`,null);
	}

	/**
	 * Suppression d'un connecteur
	 */
	public deleteConnecteur(idConnecteur: number): Observable<Result> {
		//Suppression d'un connecteur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Connecteur/deleteConnecteur/${idConnecteur}`);
	}

	/**
	 * Enregistrement d'un connecteur
	 */
	public saveConnecteur(connecteur: any): Observable<Result> {
		//Enregistrement d'un connecteur
		return this.http.put<Result>(`${environment.baseUrl}/controller/Connecteur/saveConnecteur`,connecteur);
	}

	/**
	 * Récupération de la liste des types d'environnement
	 */
	public getListeTypesEnvironnement(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'environnement
		return this.listeTypesEnvironnement.map(code => ({
			code,
			libelle: this.translateService.instant(`connecteur.environnement.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types d'exécution
	 */
	public getListeTypesExecution(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'environnement
		return this.listeTypesExecution.map(code => ({
			code,
			libelle: this.translateService.instant(`connecteur.typeExecution.${code}`)
		}));
	}

	/**
	 * Affichage des paramètres du connecteur
	 */
	public showParams(connecteur: { idConnecteur: number,listeParams: Array<any>,type: { listeParams: Array<any> } },options?: { isOnlyOptionals?: boolean,buttonLabel?: string }): Observable<Array<any>> {
		let bsModalRef: BsModalRef<ConnecteurParamsComponent>;
		let listeParams: Array<any>;

		//Copie des paramètres actuels
		listeParams = connecteur.listeParams && cloneDeep(connecteur.listeParams) || [];

		//Tri des paramètres par caractère optionnel
		listeParams.sort((a,b) => a.param.optional && !b.param.optional ? 1 : -1);

		//Parcours des paramètres attendus du connecteur
		connecteur.type.listeParams.forEach(param => {
			//Vérification de la présence du paramètre sur le connecteur
			if (!listeParams.some(p => p.param.idConnecteurTypeParam == param.idConnecteurTypeParam))
				//Ajout du paramètre
				listeParams.push({ param,value : null });
		});

		//Vérification de l'affichage des paramètres optionnels
		if (options?.isOnlyOptionals)
			//Filtrage des paramètres optionnels
			listeParams = listeParams.filter(p => p.param.optional);

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ConnecteurParamsComponent,{
			initialState: {
				idConnecteur: connecteur.idConnecteur,
				listeParams,
				options
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content?.result?.listeParams),
			filter(listeParams => !!listeParams)
		);
	}

	/**
	 * Affichage de la liste des destinataires du connecteur
	 */
	public showDestinataires(connecteur: { listeEmails: Array<string>,type: { listeEmails: Array<string> } }): Observable<Array<string>> {
		let bsModalRef: BsModalRef<ConnecteurDestinatairesComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ConnecteurDestinatairesComponent,{
			initialState: {
				listeEmails: (connecteur.listeEmails || []).map(e => ({ value: e }))
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content?.result?.listeEmails),
			filter(listeEmails => !!listeEmails)
		);
	}

	/**
	 * Affichage de la liste des mots de passe du connecteur
	 */
	public showPasswords(connecteur: { listePasswords: Array<{ value: string,idConnecteurPassword?: number }> }): Observable<Array<any>> {
		let bsModalRef: BsModalRef<ConnecteurPasswordsComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ConnecteurPasswordsComponent,{
			initialState: {
				listePasswords: cloneDeep(connecteur.listePasswords) || []
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content?.result?.listePasswords),
			filter(listePasswords => !!listePasswords)
		);
	}

	/**
	 * Affichage du résultat de l'exécution
	 */
	public showExecutionResult(execution: any) {
		//Chargement du résultat d'API
		execution.apiSession?.idApiDataResult && this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/consultApiDataResult/${execution.apiSession.idApiDataResult}`,null).pipe(first()).subscribe({
			next: result => {
				//Affichage du résultat de l'exécution
				this.bsModalService.show(ConnecteurExecutionResultComponent,{
					initialState: {
						execution,
						dataResult: result?.data?.apiDataResult?.dataResult
					},
					class: 'modal-lg'
				});
			}
		});
	}

	/**
	 * Affichage de l'historique de l'exécution
	 */
	public showExecutionHistory(execution: any) {
		//Affichage de l'historique de l'exécution
		this.bsModalService.show(ConnecteurExecutionHistoryComponent,{
			initialState: {
				execution
			},
			class: 'modal-lg'
		});
	}
}