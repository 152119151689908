import { Component,Input,OnInit } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { AuthentificationConfigurationService } from './authentification-configuration.service';
import { AppState } from 'src/app/domain/appstate';

@Component({
	selector: 'authentification-configuration-content',
	templateUrl: './authentification-configuration-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class AuthentificationConfigurationContentComponent implements OnInit {
	/** Element courant **/
	@Input() configuration: any;

	/** Identifiant du tenant **/
	@Input() idTenant: number;

	/** Etat du composant **/
	@Input() disabled?: boolean;

	/** URL de l'application **/
	appliUrl: string;

	/** Liste des types d'authentifications **/
	public listeTypesAuthentification: Array<{ code: string,libelle: string }>;

	/** Liste des types d'authentifications SAML2 **/
	public listeSaml2Types: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private authentificationConfigurationService: AuthentificationConfigurationService,private store: Store<AppState>) {

	}
	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types d'authentification
		this.listeTypesAuthentification = this.authentificationConfigurationService.getListeTypesAuthentification();

		//Récupération de la liste des modes d'authentification SAML2
		this.listeSaml2Types = this.authentificationConfigurationService.getListeSaml2Types();

		//Récupération de l'URL de l'application
		this.store.select<string>(state => state.session.appliUrl).pipe(first()).subscribe({
			next: appliUrl => {
				//Mémorisation de l'URL de l'application
				this.appliUrl = appliUrl;
			}
		});
	}

	/**
	 * Changement de type
	 */
	onTypeChange() {
		//Vérification de la configuration
		if (this.configuration.type == 'SAML2') {
			//Définition du mode d'authentification SAML2 par défaut
			this.configuration.saml2Type = 'SP_INITIATED';
		} else {
			//Retrait du mode d'authentification SAML2
			this.configuration.saml2Type = 'NON_DEFINI';
		}

		//Retrait du chiffrement du NameId
		this.configuration.saml2EncryptedNameId = false;
	}

	/**
	 * Récupération de l'URL des méta-données
	 */
	getMetadataUrl(): string {
		//Retour de l'URL
		return `${this.appliUrl}controller/_saml2Metadata/${this.idTenant}/${this.configuration.idConfig}`;
	}

	/**
	 * Ouverture des méta-données
	 */
	openMetadataUrl() {
		//Ouverture des méta-données dans une nouvelle fenêtre
		window.open(this.getMetadataUrl(),'_new');
	}

	/**
	 * Récupération du Relay State fixe
	 */
	getRelayStateFixe(): string {
		//Retour du Relay State fixe
		return `${this.appliUrl}controller/Login/${this.configuration.tenant.code}`;
	}
}