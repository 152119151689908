import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { v4 } from 'uuid';

import { BusinessDataService } from 'src/app/share/components/entite/business-data/business-data.service';
import { RuleService } from './rule.service';

@Component({
	selector: 'rule-item',
	templateUrl: './rule-item.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class RuleItemComponent implements OnInit {
	/** Mode 'Light' **/
	@Input() isModeLight?: boolean;

	/** Mode 'Edition' **/
	@Input() isEdition?: boolean;

	/** Entité **/
	@Input() entite: string;

	/** Détail courant **/
	@Input() detail: any;

	/** Interception de la suppression **/
	@Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

	/** Liste des jours de la semaine **/
	listeWeekDays: Array<{ code: string,libelle: string }>;

	/** Clé **/
	key: string;

	/**
	 * Constructeur
	 */
	constructor(private businessDataService: BusinessDataService,private ruleService: RuleService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation de la liste des opérateurs
		this.initListeOperateurs();

		//Récupération des jours de la semaine
		this.listeWeekDays = this.ruleService.getListeWeekDays();

		//Génération de la clé
		this.key = v4().toString();
	}

	/**
	 * Sélection d'une donnée métier
	 */
	selectEntity() {
		//Création du filtre si nécessaire
		this.detail.filter = this.detail.filter || {};

		this.businessDataService.showBusinessData(this.entite,{
			typeContext: 'RULE',
			isSelectEntity: true
		}).pipe(filter(item => item?.entity)).subscribe({
			next: ({ entity }) => {
				//Définition du filtre
				this.detail.filter.filter = entity.path;
				this.detail.filter.type = entity.type;
				this.detail.filter.operateur = 'EQUAL';
				this.detail.filter.object = null;
				this.detail.filter.listeValues = [];
				this.detail.filter.typeAgregation = 'NON_DEFINI';
				this.detail.filter.autocompleteType = entity.autocompleteType;
				this.detail.filter.autocompleteFilter = entity.autocompleteFilter;
				this.detail.filter.libelle = entity.pathLibelle ? entity.pathLibelle : entity.libelle;

				//Détermination du type de champs de saisie
				this.detail.filter.fieldType = this.ruleService.getFieldType(this.detail.filter.type,entity);

				//Vérification de la présence d'une énumération
				this.detail.filter.enum = this.detail.filter.fieldType?.type == 'ENUM';

				//Initialisation de la liste des opérateurs
				this.initListeOperateurs();
			}
		});
	}

	/**
	 * Suppression de l'élément
	 */
	removeItem() {
		//Notification de la suppression
		this.onDelete.emit();
	}

	/**
	 * Changement de type d'agrégation
	 */
	onTypeAgregationChange() {
		//Mise à jour de la liste des opérateurs
		this.initListeOperateurs();
	}

	/**
	 * Changement d'opérateur
	 */
	onOperateurChange() {
		//Vérification de la valeur
		if (this.detail.filter?.listeValues?.[0])
			//Suppression de la fonction
			this.detail.filter.listeValues[0].fonction = null;
	}

	/**
	 * Vérification de la présence d'un filtre avec agrégation
	 */
	isTypeAgregation(): boolean {
		//Vérification du type d'agrégation
		return this.detail.filter?.typeAgregation && this.detail.filter.typeAgregation != 'NON_DEFINI';
	}

	/**
	 * Interception du changement de temporal
	 */
	onTemporalChange({ temporal,date }: { temporal: string,date: any }) {
		//Vérification de la fonction
		if (this.detail.filter.listeValues[0].fonction != temporal)
			//Ré-initialisation de la valeur
			this.detail.filter.listeValues[0].value = null;

		//Mise à jour des données
		this.detail.filter.listeValues[0].fonction = temporal;
		this.detail.filter.listeValues[0].dateValue = date;
	}

	/**
	 * Interception du changement de période glissante
	 */
	onRollingPeriodChange({ temporal,value }: { temporal: string,value: number }) {
		let newValue: { fonction: string,numberValue: number };

		//Mise à jour des données
		this.detail.filter.listeValues[0].fonction = 'DATE_TODAY';
		this.detail.filter.listeValues[0].value = null;

		//Initialisation de la nouvelle valeur
		newValue = {
			fonction: temporal,
			numberValue: value
		};

		//Vérification de la présence d'une seconde valeur
		if (this.detail.filter?.listeValues[1])
			//Définition de la valeur
			this.detail.filter.listeValues[1] = newValue;
		else
			//Ajout de la valeur
			this.detail.filter.listeValues.push(newValue);
	}

	/**
	 * Initialisation de la liste des opérateurs
	 */
	private initListeOperateurs() {
		//Vérification de la présence d'un filtre
		if (this.detail.filter) {
			//Définition de la liste des opérateurs
			this.detail.filter.listeOperateurs = this.getListeOperateurs();

			//Définition de la liste des valeurs
			this.detail.filter.listeValues = this.detail.filter.listeValues?.length ? this.detail.filter.listeValues : [{}];
		}
	}

	/**
	 * Récupération de la liste des opérateurs
	 */
	private getListeOperateurs(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des opérateurs
		return !this.isTypeAgregation() ? this.detail.filter.fieldType?.listeOperators : this.ruleService.getListeOperatorsAgregations();
	}
}