import { NgModule } from '@angular/core';
import { CommonModule,CurrencyPipe,DatePipe,DecimalPipe,TitleCasePipe } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS,MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_CHECKBOX_DEFAULT_OPTIONS,MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_RADIO_DEFAULT_OPTIONS,MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipConfig,TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule,BsDropdownConfig } from 'ngx-bootstrap/dropdown'
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ColorPickerModule } from 'ngx-color-picker';
import { GridsterModule } from 'angular-gridster2';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MarkdownModule } from 'ngx-markdown';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
	imports: [CommonModule,FormsModule,ReactiveFormsModule,RouterModule,TranslateModule,CollapseModule,ModalModule.forChild(),TooltipModule,CodemirrorModule,BsDropdownModule.forRoot(),PopoverModule.forRoot(),TypeaheadModule.forRoot(),NgxSummernoteModule,ColorPickerModule,SortableModule,GridsterModule,NgApexchartsModule,FullCalendarModule
		,MatFormFieldModule,MatInputModule,MatToolbarModule,MatIconModule,MatButtonModule,MatMenuModule,MatButtonToggleModule,MatCheckboxModule,MatRippleModule,MatChipsModule,MatSlideToggleModule,MatRadioModule,MatProgressSpinnerModule,MatSliderModule,MatSelectModule,MarkdownModule.forRoot(),DragDropModule
	],
	exports: [CommonModule,FormsModule,ReactiveFormsModule,RouterModule,TranslateModule,CollapseModule,ModalModule,TooltipModule,CodemirrorModule,BsDropdownModule,PopoverModule,TypeaheadModule,NgxSummernoteModule,ColorPickerModule,SortableModule,GridsterModule,NgApexchartsModule,FullCalendarModule
		,MatFormFieldModule,MatInputModule,MatToolbarModule,MatIconModule,MatButtonModule,MatMenuModule,MatButtonToggleModule,MatCheckboxModule,MatRippleModule,MatChipsModule,MatSlideToggleModule,MatRadioModule,MatProgressSpinnerModule,MatSliderModule,MatSelectModule,MarkdownModule,DragDropModule
	],
	providers: [CurrencyPipe,DatePipe,DecimalPipe,TitleCasePipe,{
		provide: TooltipConfig,
		useValue: {
			placement: 'bottom',
			container: 'body'
		}
	},{
		provide: BsDropdownConfig,
		useValue: {
			isAnimated: true,
			autoClose: true
		}
	},{
		provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
		useValue: {
			hideSingleSelectionIndicator: true,
			hideMultipleSelectionIndicator: true
		}
	},{
		provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
		useValue: {
			appearance: 'outline'
		}
	},{
		provide: MAT_RADIO_DEFAULT_OPTIONS,
		useValue: {
			color: 'primary'
		}
	},{
		provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
		useValue: {
			color: 'primary'
		}
	}]
})
export class CoreComponentsModule {}