import { Component,EventEmitter,Input,Output } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	selector: 'import-format-fournisseur-list-item',
	templateUrl: './import-format-fournisseur-list-item.component.html'
})
export class ImportFormatFournisseurListItemComponent extends ListViewItem<any> {
	/** Fournisseur sélectionné **/
	@Input() selectedFournisseur: any;

	/** Sélection d'un élément **/
	@Output() onItemSelected: EventEmitter<any> = new EventEmitter();

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}