import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { first,map } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

import { SubTypeChart,TypeChart } from 'src/app/domain/chart/chart';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeComparaison } from 'src/app/domain/common/list-view';
import { RuleService } from 'src/app/share/components/rule/rule.service';
import { DashboardService } from './dashboard.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';

@Component({
	selector: 'dashboard-chart-customizer',
	templateUrl: './dashboard-chart-customizer.component.html'
})
export class DashboardChartCustomizerComponent implements OnInit,AfterContentChecked {
	/** Graphique **/
	@Input() dashboardChart: any;

	/** Dashboard courant **/
	@Input() dashboard: any;

	/** Indicateur d'affichage des conditions seulement  **/
	@Input() typeAffichage: 'CONDITIONS_ONLY' = null;

	/** Etat d'ouverture */
	public mapToggles: { title: boolean,color: boolean,seuil: boolean,conditionRoot: boolean,condition: boolean,options: boolean,nbValues: boolean } = {
		title: true,
		color: true,
		seuil: true,
		conditionRoot: false,
		condition: true,
		options: true,
		nbValues: true
	}

	/** Indicateur de chargement **/
	isLoaded: boolean = false;

	/** Profil **/
	profil: any;

	/** Liste des types de comparaisons */
	listeTypeComparaisons: Array<TypeComparaison> = [TypeComparaison.GREATER,TypeComparaison.GREATER_EQUAL,TypeComparaison.EQUAL,TypeComparaison.BETWEEN,TypeComparaison.LESS,TypeComparaison.LESS_EQUAL];

	/** Types de comparaison **/
	public TypeComparaison: typeof TypeComparaison = TypeComparaison;

	/** Liste des couleurs personnalisées **/
	listeCustomColors: Array<any>;

	/** Couleur par défaut **/
	defaultColor: any;

	/** Résultat **/
	result: { dashboardChart: any };

	/**
	 * Constructeur
	 */
	constructor(private dashboardService: DashboardService,private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<DashboardChartCustomizerComponent>,private toastrService: ToastrService,private translateService: TranslateService,private ruleService: RuleService,private entiteService: EntiteService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement de la personnalisation du graphique
		(this.dashboardChart?.idLien ? this.dashboardService.loadDashboardChart(this.dashboardChart.idLien) : of(null)).pipe(
			first(),
			map(result => result?.data?.dashboardChart)
		).subscribe({
			next: dashboardChart => {
				//Mise à jour de la personnalisation
				Object.assign(this.dashboardChart,dashboardChart);

				//Vérification de la règle
				this.dashboardChart.rule = this.dashboardChart.rule || {};

				//Vérification du type de chart
				if (this.isColorCustomizable()) {
					//Vérification de la présence de couleurs personnalisées
					if (this.dashboardChart.listeCustomColors?.length) {
						//Initialisation de la couleur par défaut
						this.defaultColor = this.dashboardChart.listeCustomColors.find(c => !c.typeComparaison);

						//Initialisation de la liste des couleurs personnalisées
						this.listeCustomColors = this.dashboardChart.listeCustomColors.filter(c => c.typeComparaison);
					}

					//Vérification de la couleur par défaut
					if (!this.defaultColor)
						//Initialisation de la couleur par défaut
						this.defaultColor = {};
				}

				//Chargement effectué
				this.isLoaded = true;

				//Vérification nombre de valeurs à afficher
				if (this.isNbValuesCustomizable() && !this.dashboardChart.nbValues)
					//Définition du nombre de valeurs par défaut
					this.dashboardChart.nbValues = 12;
			}
		});

		//Initialisation de l'état d'ouverture de la section des conditions standards
		this.mapToggles.conditionRoot = this.typeAffichage == 'CONDITIONS_ONLY';
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Récupération de la traduction d'une entité
	 */
	translateEntityCode(entite: string) {
		//Traduction de l'entité
		return this.entiteService.translateEntityCode(entite);
	}

	/**
	 * Enregistrement de la personnalisation
	 */
	public saveDashboardChartCustomization() {
		let doSaveDashboardChart: () => void;

		//Vérification de la règle
		if (this.dashboardChart.rule?.listeDetails?.length)
			//Traitement des règles avant enregistrement
			this.dashboardChart.rule = this.ruleService.updateRuleForSave(this.dashboardChart.rule);

		//Vérification du contexte d'affichage de la modal
		if (this.typeAffichage != 'CONDITIONS_ONLY') {
			//Définition de la liste des couleurs personnalisées
			this.dashboardChart.listeCustomColors = (this.listeCustomColors || []).filter(c => !!c.color);

			//Vérification de la couleur par défaut
			if (this.defaultColor?.color) {
				//Vérification de la présence de couleurs conditionnelles
				if (this.dashboardChart.listeCustomColors.length)
					//Ajout de la couleur par défaut
					this.dashboardChart.listeCustomColors.push(this.defaultColor);
				else
					//Définition de la liste des couleurs
					this.dashboardChart.listeCustomColors = [this.defaultColor];
			}

			//Définition de la fonction d'enregistrement du graphique
			doSaveDashboardChart = () => this.dashboardService.saveDashboardChart(this.dashboard.idDashboard,{
					...this.dashboardChart,
					dashboard: cloneDeep(this.dashboard),
					rule: this.dashboardChart.rule?.listeDetails?.length && this.dashboardChart.rule || null
				}).subscribe({
					next: (result: Result) => {
						//Vérification du code d'erreur
						if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Message d'erreur
							this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

							//Définition du dashboard chart
							Object.assign(this.dashboardChart,result.data.dashboardChart);

							//Définition du résultat
							this.result = {
								dashboardChart: this.dashboardChart
							};

							//Fermeture de la modal
							this.bsModalRef.hide();
						} else
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				});

			//Vérification de la présence d'un dashboard personnalisé
			if (!this.dashboard?.idDashboard) {
				//Enregistrement du dashboard
				this.dashboardService.saveDashboard(this.profil ? 'PROFIL' : 'USER',this.dashboard).pipe(first()).subscribe({
					next: result => {
						let savedDashboardChart: any;

						//Vérification du code d'erreur
						if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

							//Mise à jour du dashboard
							Object.assign(this.dashboard,result.data?.dashboard);

							//Récupération du graphique du dashboard
							savedDashboardChart = result.data?.dashboard.listeDashboardCharts.find(dashboardChart => dashboardChart.chart?.idChart == this.dashboardChart?.chart?.idChart);

							//Récupération de l'identifiant du lien
							this.dashboardChart.idLien = savedDashboardChart.idLien;

							//Enregistrement du graphique
							doSaveDashboardChart();
						} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
								field: this.translateService.instant('actions.doublon.entite')
							}));
						} else
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				});
			} else
				//Enregistrement du graphique
				doSaveDashboardChart();
		} else {
			//Définition du résultat (sans enregistrement)
			this.result = {
				dashboardChart: this.dashboardChart
			};

			//Fermeture de la modal
			this.bsModalRef.hide();
		}
	}

	/**
	 * Vérification du type de chart
	 */
	isColorCustomizable(): boolean {
		let chart: any;

		//Récupération du graphique
		chart = this.dashboardChart.chart;

		//Vérification du type de chart
		return chart?.firstType == TypeChart.KPI
			|| chart?.firstType == TypeChart.LINE
			|| chart?.firstType == TypeChart.BAR && chart?.listeDefinitions?.some(d => d.subType == SubTypeChart.GROUPE || d.subType == SubTypeChart.EMPILE);
	}

	/**
	 * Vérification du type de graphique pour la saisie de nombre de valeurs à afficher
	 */
	isNbValuesCustomizable(): boolean {
		let chart: any;

		//Récupération du graphique
		chart = this.dashboardChart.chart;

		//Vérification du type de graphique
		return [TypeChart.AREA,TypeChart.LINE,TypeChart.BAR].includes(chart?.firstType);
	}

	/**
	 * Initialisation de la liste des couleurs personnalisées
	 */
	initListeCustomColors() {
		//Initialisation de la liste des couleurs personnalisées
		this.listeCustomColors = [{}];
	}

	/**
	 * Ajout d'une couleur personnalisée
	 */
	addCustomColor() {
		let item: any;

		//Vérification de la liste des couleurs personnalisées
		if (this.listeCustomColors.length)
			//Récupératoin du dernier élément
			item = this.listeCustomColors[this.listeCustomColors.length - 1];

		//Ajout d'une couleur personnalisée
		this.listeCustomColors.push({ typeComparaison: item?.typeComparaison });
	}

	/**
	 * Suppression d'une couleur personnalisée
	 */
	removeCustomColor(index: number) {
		//Suppression d'une couleur personnalisée
		this.listeCustomColors.splice(index,1);
	}
}
