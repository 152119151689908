import { NgModule } from '@angular/core';

import { CoreComponentsModule } from './core-components.module';
import { ActionToastModule } from './action-toast/action-toast.module';
import { ListViewModule } from './list-view/list-view.module';
import { ConfirmModule } from './confirmation/confirm.module';
import { RightModule } from 'src/app/share/pipe/right/right.module';
import { FormDetectChangesModule } from 'src/app/share/directive/form-detect-changes/form-detect-changes.module';
import { FileUploaderModule } from 'src/app/share/directive/file-uploader/file-uploader.module';
import { AttachmentModule } from './attachment/attachment.module';
import { ThumbModule } from './thumb/thumb.module';
import { HistoryModule } from './history/history.module';
import { ImageEditorModule } from './image-editor/image-editor.module';
import { BytesModule } from 'src/app/share/pipe/bytes/bytes.module';
import { FloatingButtonModule } from './floating-button/floating-button.module';
import { PleaseWaitModule } from './please-wait/please-wait.module';
import { AutocompleteModule } from './autocomplete/autocomplete.module';
import { AutocompleteSearchModule } from './autocomplete/search/autocomplete-search.module';
import { MessagingModule } from './messaging/messaging.module';
import { PluralTranslateModule } from 'src/app/share/pipe/plural-translate/plural-translate.module';
import { FieldKeyValueModule } from 'src/app/share/pipe/field-key-value/field-key-value.module';
import { DatePickerModule } from 'src/app/share/directive/date-picker/date-picker.module';
import { PageContentModule } from './page-content/page-content.module';
import { ComplexPageModule } from './complex-page/complex-page.module';
import { MailHistoryModule } from './mail-history/mail-history.module';
import { NotificationModule } from './notification/notification.module';
import { StepperModule } from './stepper/stepper.module';
import { ExtractionModule } from './extraction/extraction.module';
import { EntiteModule } from './entite/entite.module';
import { TemplateMailModule } from './template-mail/template-mail.module';
import { SummernoteModule } from './summernote/summernote.module';
import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';
import { PersonalDataModule } from 'src/app/share/directive/personal-data/personal-data.module';
import { ReferentielModule } from './referentiel/referentiel.module';
import { ColorPickerModule } from './color-picker/color-picker.module';
import { NumberModule } from 'src/app/share/directive/number/number.module';
import { AdresseModule } from './adresse/adresse.module';
import { PatternModule } from 'src/app/share/directive/pattern/pattern.module';
import { SchedulerModule } from './scheduler/scheduler.module';
import { CapitalizeFirstModule } from 'src/app/share/pipe/capitalize-first/capitalize-first.module';
import { BasketModule } from './basket/basket.module';
import { AutoFocusModule } from 'src/app/share/directive/autofocus/autofocus.module';
import { DocumentModule } from './document/document.module';
import { ConnecteurModule } from './connecteur/connecteur.module';
import { DisplayMoreModule } from './display-more/display-more.module';
import { RuleModule } from './rule/rule.module';
import { DisplayModule } from '../pipe/display/display.module';
import { AdvancedSearchModule } from './advanced-search/advanced-search.module';
import { SuccessiveSearchModule } from './successive-search/successive-search.module';
import { DroitModule } from './droit/droit.module';
import { TextareaModule } from '../directive/textarea/textarea.module';
import { AmountModule } from './amount/amount.module';
import { PhoneNumberModule } from './phone-number/phone-number.module';
import { ChartModule } from './chart/chart.module';
import { CalendarModule } from './calendar/calendar.module';
import { ValidatorModule } from '../directive/validator/validator.module';
import { ComparatorModule } from './comparaison/comparator.module';
import { WorkflowModule } from './workflow/workflow.module';
import { IconModule } from './icon/icon.module';
import { ArticleModule } from './article/article.module';
import { ScrollFixedModule } from '../directive/scroll-fixed/scroll-fixed.module';
import { FilterModule } from '../pipe/filter/filter.module';
import { SecurityModule } from './security/security.module';
import { UserSchedulerModule } from './user-scheduler/user-scheduler.module';
import { ImportTemplateModule } from './import-template/import-template.module';

@NgModule({
	imports: [CoreComponentsModule,ListViewModule,ActionToastModule,ConfirmModule,RightModule,FormDetectChangesModule,FileUploaderModule,AttachmentModule,ThumbModule,HistoryModule,ImageEditorModule,BytesModule,SelectPickerModule
		,FloatingButtonModule,PleaseWaitModule,AutocompleteModule,AutocompleteSearchModule,MessagingModule,PluralTranslateModule,CapitalizeFirstModule,FieldKeyValueModule,DatePickerModule,PageContentModule,ComplexPageModule,MailHistoryModule,NotificationModule,StepperModule
		,ExtractionModule,EntiteModule,PersonalDataModule,TemplateMailModule,SummernoteModule,ReferentielModule,ColorPickerModule,NumberModule,AdresseModule,PatternModule,SchedulerModule,BasketModule,AutoFocusModule,DocumentModule,ConnecteurModule,DisplayMoreModule,RuleModule
		,DisplayModule,AdvancedSearchModule,SuccessiveSearchModule,DroitModule,TextareaModule,AmountModule,PhoneNumberModule,ChartModule,CalendarModule,ValidatorModule,ComparatorModule,WorkflowModule,IconModule,ArticleModule,ScrollFixedModule,FilterModule,SecurityModule,UserSchedulerModule,ImportTemplateModule
	],
	exports: [CoreComponentsModule,ListViewModule,ActionToastModule,ConfirmModule,RightModule,FormDetectChangesModule,FileUploaderModule,AttachmentModule,ThumbModule,HistoryModule,ImageEditorModule,BytesModule,SelectPickerModule
		,FloatingButtonModule,PleaseWaitModule,AutocompleteModule,MessagingModule,PluralTranslateModule,CapitalizeFirstModule,FieldKeyValueModule,DatePickerModule,PageContentModule,ComplexPageModule,MailHistoryModule,NotificationModule,StepperModule
		,ExtractionModule,EntiteModule,PersonalDataModule,TemplateMailModule,SummernoteModule,ReferentielModule,ColorPickerModule,NumberModule,AdresseModule,PatternModule,SchedulerModule,BasketModule,AutoFocusModule,DocumentModule,ConnecteurModule,DisplayMoreModule,RuleModule
		,DisplayModule,AdvancedSearchModule,SuccessiveSearchModule,DroitModule,TextareaModule,AmountModule,PhoneNumberModule,ChartModule,CalendarModule,ValidatorModule,ComparatorModule,WorkflowModule,IconModule,ArticleModule,ScrollFixedModule,FilterModule,SecurityModule,UserSchedulerModule,ImportTemplateModule
	]
})
export class ShareComponentModule { }