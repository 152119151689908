import { Pipe,PipeTransform } from '@angular/core';

@Pipe({
	name: 'pluralTranslate'
})
export class PluralTranslatePipe implements PipeTransform {
	/**
	 * Transformation
	 */
	transform(key: string,number: number): string {
		//Retour de la clé suffixée par le singulier ou le pluriel
		return `${key}.${!number ? 'none' : number == 1 ? 'singular' : 'plural'}`;
	}
}
