import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { cloneDeep } from 'lodash-es';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeGroupe } from 'src/app/domain/security/right';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DroitService } from 'src/app/share/components/droit/droit.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { OffreDroitComponent } from './offre-droit.component';
import { OffreEditComponent } from './offre-edit.component';
import { OffreService } from './offre.service';

@Component({
	selector: 'offre',
	templateUrl: './offre.component.html'
})
export class OffreComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Offre courante **/
	public offre: any;

	/** Résumé */
	private resume: { nbDroitsAdmin: number,nbDroitsYourway: number,nbDroitsComptabilite: number } = {
		nbDroitsAdmin: 0,
		nbDroitsYourway: 0,
		nbDroitsComptabilite: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private offreService: OffreService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private droitService: DroitService) { }

	/** Récupération de la classe **/
	getClassName: () => string = () => 'com.notilus.data.commerce.Offre';

	/** Récupération de l'identifiant de l'offre à charger **/
	getIdObject: () => number = () => this.offre?.idOffre || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'offre.libelle',
				key: 'libelle'
			},{
				libelle: 'offre.reference',
				key: 'reference'
			},{
				libelle: 'offre.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			customStyle: {
				elements: {
					header: 'card profil m-t-10',
					body: ' ',
					row: ' '
				}
			},
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editOffre(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editOffre(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteOffre(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			isTabActionsHidden: () => true,
			listeElements: [{
				type: 'DROIT',
				libelle: `offre.elements.droit.${TypeGroupe.ADMIN}`,
				component: OffreDroitComponent,
				retrieveComponentData: () => ({
					resume: this.resume,
					offre: this.offre,
					saveOffre: this.saveOffre.bind(this)
				}),
				doAction: () => this.droitService.onEditGroupe.emit(TypeGroupe.ADMIN),
				count: () => this.resume.nbDroitsAdmin,
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isTabHidden: true
			},{
				type: 'DROIT',
				libelle: `offre.elements.droit.${TypeGroupe.YOURWAY}`,
				component: OffreDroitComponent,
				retrieveComponentData: () => ({
					resume: this.resume,
					offre: this.offre,
					saveOffre: this.saveOffre.bind(this)
				}),
				doAction: () => this.droitService.onEditGroupe.emit(TypeGroupe.YOURWAY),
				count: () => this.resume.nbDroitsYourway,
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isTabHidden: true
			},{
				type: 'DROIT',
				libelle: `offre.elements.droit.${TypeGroupe.COMPTABILITE}`,
				component: OffreDroitComponent,
				retrieveComponentData: () => ({
					resume: this.resume,
					offre: this.offre,
					saveOffre: this.saveOffre.bind(this)
				}),
				doAction: () => this.droitService.onEditGroupe.emit(TypeGroupe.COMPTABILITE),
				count: () => this.resume.nbDroitsComptabilite,
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isTabHidden: true
			}]
		};

		//Récupération de l'identifiant de l'offre à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idOffre);
			}
		});
	}

	/**
	 * Edition de l'offre
	 */
	private editOffre() {
		//Ouverture du composant d'édition
		this.pageContentService.open(OffreEditComponent,{
			data: {
				offre: cloneDeep(this.offre),
				deleteOffre: this.deleteOffre.bind(this),
				saveOffre: this.saveOffre.bind(this)
			}
		}).subscribe({
			next: (offre: any) => {
				//Vérification des données
				if (offre) {
					//Mise à jour de l'offre
					this.offre = Object.assign(this.offre,offre);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.offre);
				}
			}
		});
	}

	/**
	 * Suppression de l'offre
	 */
	private deleteOffre() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.offreService.deleteOffre(this.offre))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	private saveOffre(offre: any,close: Function) {
		//Enregistrement de l'objet
		this.offreService.saveOffre(offre).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					offre = Object.assign(offre,result.data?.offre);

					//Redéfinition de l'offre
					this.offre = Object.assign(this.offre,offre);

					//Fermeture du formulaire
					close?.(offre);

					//Définition du résumé
					this.resume = {
						nbDroitsAdmin: this.offre?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.ADMIN)?.nbDroits || 0,
						nbDroitsYourway: this.offre?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.YOURWAY)?.nbDroits || 0,
						nbDroitsComptabilite: this.offre?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.COMPTABILITE)?.nbDroits || 0
					}
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,0))
							//Ajout de la référence
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Vérification du type de licence
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.typeLicence'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idOffre: number) {
		//Enregistrement de l'offre
		this.offreService.loadOffre(idOffre).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'offre
					this.offre = result.data?.offre;

					//Définition du résumé
					this.resume = {
						nbDroitsAdmin: this.offre?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.ADMIN)?.nbDroits || 0,
						nbDroitsYourway: this.offre?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.YOURWAY)?.nbDroits || 0,
						nbDroitsComptabilite: this.offre?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.COMPTABILITE)?.nbDroits || 0
					}

					//Vérification de l'identifiant de l'offre
					if (!this.offre?.idOffre) {
						//Création d'un objet vierge
						this.offre = {
							actif: false,
							typeLicence: 'NON_DEFINI'
						};

						//Edition de l'offre
						this.editOffre();
					}
				}
			}
		});
	}
}