import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { TypeDroit } from 'src/app/domain/security/right';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { VehiculeListItemComponent } from './vehicule-list-item.component';
import { mapEntites } from './vehicule.entites';
import { VehiculeService } from './vehicule.service';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './vehicule-list.component.html'
})
export class VehiculeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,VehiculeListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public dashboardService: DashboardService,private vehiculeService: VehiculeService
		,public extractionService: ExtractionService,private rightService: RightService,private layoutService: LayoutService,public connecteurService: ConnecteurService) {}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement des configurations des véhicules
		this.vehiculeService.getListeConfigurations().subscribe({
			complete: () => {
				//Définition de la liste
				this.liste = new ListView<any,VehiculeListItemComponent>({
					uri: `/controller/Vehicule/filtreVehicules`,
					title: this.translateService.instant('vehicule.liste.title'),
					component: VehiculeListItemComponent,
					listeFilters: [{
						clef: 'reference',
						title: this.translateService.instant('vehicule.immatriculation.item'),
						isDefault: true
					},{
						clef: 'listeOldReferences',
						title: this.translateService.instant('vehicule.search.listeAnciennesImmatriculations'),
						isDefault: true
					},{
						clef: 'reference',
						title: this.translateService.instant('vehicule.search.numero'),
						isDefault: true
					},{
						clef: 'numeroInterne',
						title: this.translateService.instant('vehicule.search.numeroInterne'),
						isDefault: true
					},{
						clef: '*modele.libelle',
						isDefault: true
					},{
						clef: '*marque.libelle',
						isDefault: true
					},{
						clef: 'vin',
						title: this.translateService.instant('vehicule.search.vin'),
						isDefault: true
					},{
						clef: 'societe',
						title: this.translateService.instant('vehicule.societeImputation'),
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'societe'
						},
						isDefault: true
					},{
						clef: 'service',
						title: this.translateService.instant('vehicule.serviceImputation'),
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'service'
						},
						isDefault: true
					},{
						clef: 'actif',
						type: TypeFilter.BOOLEAN
					},{
						clef: 'genre',
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'genre'
						}
					},{
						clef: 'dateEntree',
						type: TypeFilter.DATE
					},{
						clef: 'dateSortie',
						type: TypeFilter.DATE
					},{
						clef: 'typeVehicule',
						type: TypeFilter.LONG,
						title: this.translateService.instant('vehicule.typeVehicule.item'),
						listeValues: this.vehiculeService.getListeTypesVehicule().filter(t => t.code != 'VEHICULE_PERSO')
					},{
						clef: 'pays',
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'pays'
						}
					}],
					defaultOrder: 'reference,*modele.libelle,*modele.marque.libelle',
					getKeyFieldName: () => 'idVehicule',
					hasMainAction: () => this.rightService.hasRight(null,'creation'),
					doMainAction: (creationData?: any) => {
						//Affichage de la sélection d'une immatriculation
						this.vehiculeService.showImmatriculationSelection((data: { immatriculation: any,params: any }) => {
							//Vérification de la présence de données
							if (data) {
								//Redirection vers le véhicule
								this.layoutService.goToByState('listeVehicules-loadVehicule',{
									routeParams: {
										idVehicule: 0
									},
									routeData: {
										immatriculation: data.immatriculation,
										modele: data.params.modele
									}
								});
							}
						},{
							isDateEntreeVisible: false,
							isWithEngin: true,
							reference: creationData?.immatriculation || null
						}).subscribe();
					},
					listeActions: [(this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') || this.rightService.hasRight(TypeDroit.VEHICULE_CONDUCTEUR,'creation')) && {
						icon: 'event',
						onPress: (actionMasse: ActionMasse) => this.vehiculeService.goToPlanning(null,{ listeSelectedFilters: actionMasse.searchSpec.listeFilter },actionMasse.listeIdObjects)
					}].filter(i => !!i)
				});
			}
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().pipe(map(result => result?.isAvailable)).subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}