import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { shareReplay } from 'rxjs/operators';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { AuthentificationConfigurationService } from '../authentification/configuration/authentification-configuration.service';
import { LocaleService } from 'src/app/components/locale/locale.service';
import { RevendeurService } from './revendeur.service';
import { SelectPickerService } from 'src/app/share/components/select-picker/select-picker.service';

@Component({
	selector: 'revendeur',
	templateUrl: './revendeur.component.html'
})
export class RevendeurComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() revendeur: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Types d'attachment **/
	public TypeAttachment = TypeAttachment;

	/** Liste des modes d'authentification **/
	public listeModesAuthentification: Array<{ code: string,libelle: string }>;

	/** Liste des locales **/
	public listeLocales: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(public revendeurService: RevendeurService,private toastrService: ToastrService,private translateService: TranslateService,private localeService: LocaleService
			,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef,private authentificationConfigurationService: AuthentificationConfigurationService,public selectPickerService: SelectPickerService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du revendeur
		this.revendeur = Object.assign({},this.revendeur);

		//Initialisation du statut d'activation du compte administrateur
		this.revendeur.isAdminAccountEnabled = this.revendeur.idRevendeur ? null : false;

		//Définition de la liste des locales
		this.localeService.getListeLocalesForTenant().subscribe(listeLocales => this.listeLocales = listeLocales);

		//Définition de la liste des modes d'authentification
		this.listeModesAuthentification = this.authentificationConfigurationService.getListeModesAuthentification();

		//Chargement du détail du revendeur
		this.revendeurService.loadTenantForRevendeur(this.revendeur.idRevendeur).subscribe({
			next: (result: Result) => {
				//Vérification du résultat
				if (result?.data?.tenant) {
					//Récupération de la liste des configurations d'authentification
					this.revendeur.listeAuthConfigs = result.data.listeAuthConfigs;

					//Définition du thème
					this.revendeur.theme = result.data.tenant.tenantDescription.theme;

					//Vérification de la présence d'un thème
					if (this.revendeur.theme) {
						//Définition du tenant description
						this.revendeur.theme.tenantDescription = result.data.tenant.tenantDescription;

						//Suppression du thème du tenant description (Récursion)
						delete this.revendeur.theme.tenantDescription.theme;
					} else {
						//Définition du thème par défaut
						this.revendeur.theme = {
							actif: false
						}
					}
				} else {
					//Définition du thème par défaut
					this.revendeur.theme = {
						actif: false
					}
				}
			}
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement du revendeur
	 */
	saveRevendeur() {
		//Enregistrement du revendeur
		this.revendeurService.saveRevendeur(this.revendeur).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du revendeur (Conservation de la liste des configurations)
					this.onObjectChange.emit(Object.assign(this.revendeur,result.data.revendeur));
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du code
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du code
						listeDoublons.push(this.translateService.instant('actions.doublon.code'));

					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression du revendeur
	 */
	deleteRevendeur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du revendeur
					this.revendeurService.deleteRevendeur(this.revendeur).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du revendeur
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Configuration des modes d'authentifications
	 */
	showListeConfigurations() {
		//Affichage de la liste des configurations
		this.authentificationConfigurationService.showListeConfigurations({
			listeConfigurations: this.revendeur.listeAuthConfigs,
			idTenant: this.revendeur.listeAuthConfigs?.[0]?.tenant?.idTenant
		}).subscribe({
			next: (listeConfigurations: Array<any>) => {
				//Mise à jour de la liste des configurations
				this.revendeur.listeAuthConfigs = listeConfigurations;
			}
		});
	}

	/**
	 * Vérification du statut d'activation du compte administrateur du revendeur
	 */
	isAdminAccountEnabled() {
		//Vérification du statut d'activation du compte administrateur du revendeur
		this.revendeurService.isAdminAccountEnabled(this.revendeur).pipe(shareReplay(1)).subscribe({
			next: result => {
				//Alimentation de l'indicateur d'activation du compte administrateur
				this.revendeur.isAdminAccountEnabled = result.data ? result.data.isAdminAccountEnabled : true;

				//Vérification de l'indicateur
				if (this.revendeur.isAdminAccountEnabled)
					//Affichage d'un message d'avertissement
					this.toastrService.warning(this.translateService.instant('revendeur.modificationCodeImpossible'));
			}
		});
	}
}