import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ImportTemplateService } from './import-template.service';
import { ImportTemplateComponent } from './import-template.component';
import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';

@NgModule({
	imports: [CoreComponentsModule,SelectPickerModule],
	declarations: [ImportTemplateComponent],
	providers: [ImportTemplateService]
})
export class ImportTemplateModule {}